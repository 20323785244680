enum LocaleKey {
  ACCESS_DENIED = 'access.denied',
  ACTIONS = 'actions',
  ACTIVE = 'active',
  ACTIVE_AGENTS = 'active.agents',
  ACTIVE_CHATS = 'active.chats',
  ACTIVE_CHATS_KPI = 'active.chats.kpi',
  ACTIVE_CHATS_HEADER = 'active.chats.header',
  ADD = 'add',
  ADD_CONDITION = 'add.condition',
  ADD_NEW_ATTRIBUTE_COLUMN = 'add.new.attribute.column',
  ADD_NEW_COLUMNS = 'add.new.columns',
  ADD_NEW_ROW = 'add.new.row',
  ADD_NEW_FILTER = 'add.new.filter',
  ADD_NEW_VIEW = 'add.new.view',
  ADD_VIEW = 'add.view',
  ADD_ALERT_ROW = 'add.alert.row',
  ADD_FILTER_ROW = 'add.filter.row',
  ADD_VIEW_BUTTON_TOOLTIP = 'add.view.button.tooltip',
  AGENT = 'agent',
  AGENT_ATTRIBUTE = 'attribute.type.agent',
  AGENTNAME_TITLE = 'agentname.title',
  AGENTS = 'agents',
  AGENTS_HEADER = 'agents.header',
  AGENTS_LOGGED_IN = 'agents.logged.in',
  AGENTS_ON_BUSY = 'agents.on.busy',
  AGENT_FULL_NAME_HEADER = 'agent.full.name.header',
  AGENT_FULL_NAMES_HEADER = 'agent.full.names.header',
  AGENT_GROUP_HEADER = 'agent.group.header',
  AGENT_GROUPS_HEADER = 'agent.groups.header',
  AGENT_GROUP_NAME_HEADER = 'agent.group.name.header',
  AGENT_GROUPS_ARE_NOT_ENABLED = 'agent.groups.are.not.enabled',
  AGENT_HAS_CHATS_FORCED_LOGOUT_ERROR = 'agent.has.chats.forced.logout.error',
  AGENT_ID_HEADER = 'agent.id.header',
  AGENT_LOGOUT_DISABLED = 'agent.logout.disabled',
  AGENT_LOGOUT_SUCCESSFUL = 'agent.logout.successful',
  AGENT_LOGOUT_UNAVAILABLE = 'agent.logout.unavailable',
  AGENT_NOTES = 'agent.notes',
  AGENT_SERVICEABILITY = 'agent.serviceability',
  AGENT_STATUS = 'agent.status',
  AGENT_UTILIZATION = 'agent.utilization',
  AGENT_UTILIZATION_HELP = 'agent.utilization.help',
  AGENT_GROUPS_MORE_INFO = 'agent.groups.more.info',
  ALERTS = 'alerts',
  ALERTING_COLUMN = 'alerting.column',
  ALERT_COLOR_VALIDATION = 'alert.color.validation',
  ALERT_COLUMN_VALIDATION = 'alert.column.validation',
  ALERT_VALUE_VALIDATION = 'alert.value.validation',
  ALERT_TOGGLE_SWITCH = 'alert.toggle.switch.a11y',
  ALT_FILTER = 'alt.filter',
  API_CONNECTION_PROBLEM = 'api.connection.problem',
  APPLICATION_ERROR_OCCURED = 'application.error.occured',
  APPLY = 'apply',
  APPLY_AND_SAVE = 'apply.and.save',
  ASCENDING = 'ascending',
  ASYNCHRONOUS_HEADER = 'asynchronous.header',
  ATTRIBUTES_HEADER = 'attributes.header',
  ATTRIBUTE_ALREADY_EXIST = 'attribute.already.exist',
  ATTRIBUTE_NAME = 'attribute.name',
  ATTRIBUTE_TYPE = 'attribute.type',
  AVAILABLE = 'available',
  AVAILABLE_CHAT_SLOTS = 'available.chat.slots',
  AVAILABLE_COLUMNS = 'available.columns',
  AVAILABLE_FOR_HEADER = 'available.for.header',
  AVAILABLE_SLOTS = 'available.slots',
  AVAILABLE_TIME_HEADER = 'available.time.header',
  AVAIL_AGENTS = 'avail.agents',
  AVERAGE_CHAT_DURATION = 'average.chat.duration',
  AVERAGE_INITIAL_RESPONSE = 'average.initial.response',
  AVERAGE_RESPONSE_TIME = 'average.response.time',
  AVERAGE_TIME_IN_QUEUE = 'average.time.in.queue',
  AVERAGE_WAIT = 'average.wait',
  AVG_CHAT_DURATION = 'avg.chat.duration',
  AVG_DURATION_HEADER = 'avg.duration.header',
  AVG_INITIAL_RESPONSE = 'avg.initial.response',
  AVG_INITIAL_RESPONSE_HEADER = 'avg.initial.response.header',
  AVG_RESPONSE = 'avg.response',
  AVG_RESPONSE_TIME = 'avg.response.time',
  AVG_RESPONSE_TIME_HEADER = 'avg.response.time.header',
  AVG_TIME_IN_QUEUE_HEADER = 'avg.time.in.queue.header',
  AVG_WAIT_HEADER = 'avg.wait.header',
  BACK = 'back',
  BACK_TO_LIST = 'back.to.list',
  BOOKMARKS = 'bookmarks',
  BOOKMARK_CHAT = 'bookmark.chat',
  BOOKMARK_ADDED = 'bookmark.added.a11y',
  BOOKMARK_REMOVED = 'bookmark.removed.a11y',
  BOOKMARKED_CHATS = 'bookmarked.chats',
  BUNIT_AND_GROUP_NAME = 'bunit.and.group.name',
  BUSINESS_RULES = 'business.rules',
  BUSINESS_RULE_ATTRIBUTE = 'attribute.type.business.rule',
  BUSINESS_RULE_HEADER = 'business.rule.header',
  BUSINESS_UNIT_HEADER = 'business.unit.header',
  BUSY = 'busy',
  BUSY_AGENTS = 'busy.agents',
  BUSY_AGENTS_CHATTING_KPI = 'busy.agents.chatting.kpi',
  BUSY_AGENTS_NOT_CHATTING_KPI = 'busy.agents.not.chatting.kpi',
  BUSY_AGENTS_CHATTING = 'busy.agents.chatting',
  BUSY_AGENTS_NOT_CHATTING = 'busy.agents.not.chatting',
  BUSY_CLICKS_COUNT_HEADER = 'busy.clicks.count.header',
  CANCEL = 'cancel',
  CAN_SERVICE_COUNT_HEADER = 'can.service.count.header',
  CELL_COPY_MESSAGE = 'cell.copy.message',
  CHANGE_PASSWORD = 'change.password',
  CHAT = 'chat',
  CHAT_SLOTS_UTILIZATION = 'chat.slots.utilization',
  CHATS = 'chats',
  CHATS_BETWEEN_SLA = 'chats.between.sla',
  CHATS_EXCEEDING_GOAL = 'chats.exceeding.goal',
  CHATS_EXCEEDING_HANDLE_TIME_GOAL = 'chats.exceeding.handle.time.goal',
  CHATS_EXCEEDING_SLA = 'chats.exceeding.sla',
  CHATS_IN_LAST_HOUR = 'chats.in.last.hour',
  CHATS_IN_QUEUE = 'chats.in.queue',
  CHATS_IN_QUEUE_KPI = 'chats.in.queue.kpi',
  CHATS_LESS_SLA = 'chats.less.sla',
  CHATS_MORE_SLA = 'chats.more.sla',
  CHATS_WITHIN_GOAL = 'chats.within.goal',
  CHATS_WITHIN_HANDLE_TIME_GOAL = 'chats.within.handle.time.goal',
  CHATS_WITHIN_SLA = 'chats.within.sla',
  CHAT_COMPLETED = 'chat.completed',
  CHAT_ID = 'chat.id',
  CHAT_ID_HEADER = 'chat.id.header',
  CHAT_IS_DISABLED = 'chat.is.disabled',
  CHAT_NAME_HEADER = 'chat.name.header',
  CHAT_REQUESTS = 'chat.requests',
  CHAT_SCORES_HELP = 'chat.scores.help',
  CHAT_STATUS = 'chat.status',
  CHAT_UTILIZATION = 'chat.utilization',
  CHAT_UTILIZATION_HELP = 'chat.utilization.help',
  CHAT_WITH_AGENT = 'chat.with.agent',
  CHAT_WITH_AGENT_UNAVAILABLE = 'chat.with.agent.unavailable',
  CLEAR_SEARCH = 'clear.search',
  CLIENT = 'client',
  CLOSE = 'close',
  CLOSED = 'closed',
  CLOSED_CHATS_COUNT_HEADER = 'closed.chats.count.header',
  CLOSE_CHAT = 'close.chat',
  CLOSE_TRANSCRIPT = 'close.transcript',
  COLLAPSE = 'collapse',
  COLLAPSE_FULL_MESSAGE = 'collapse.full.message.a11y',
  COLOR = 'color',
  COLUMN = 'column',
  COLUMN_ATTRIBUTE_NAME_VALIDATION = 'column.attribute.name.validation',
  COLUMN_ATTRIBUTE_TYPE_VALIDATION = 'column.attribute.type.validation',
  COLUMN_REORDER_MESSAGE = 'column.reorder.message.a11y',
  COLUMN_REORDER_SUCCESSFUL = 'column.reorder.successful.a11y',
  COLUMN_SORT_SUCCESSFUL = 'column.sort.successful.a11y',
  COLUMN_SORT_REMOVED = 'column.sort.removed.a11y',
  COLUMNS = 'columns',
  COMMIT = 'commit',
  COMMIT_VIEW_CHANGES = 'commit.view.changes',
  COMMIT_VIEWS_ERROR = 'commit.views.error',
  COMMIT_VIEWS_ERROR_MESSAGE = 'commit.views.error.message',
  COMMIT_VIEWS_INPROCESS = 'commit.views.inprocess',
  COMMIT_VIEWS_INPROCESS_MESSAGE = 'commit.views.inprocess.message',
  COMMIT_VIEWS_SUCCESS = 'commit.views.success',
  COMMIT_VIEWS_SUCCESS_MESSAGE = 'commit.views.success.message',
  COMMIT_VIEW_TITLE = 'commit.view.title',
  COMMIT_VIEW_MESSAGE = 'commit.view.message',
  CONFERENCE_COUNT_HEADER = 'conference.count.header',
  CONFIGURE = 'configure',
  CONFIGURE_MODE = 'configure.mode',
  CONFIRM_PASSWORD = 'confirm.password',
  CONNECTING = 'connecting',
  CONTINUE_SESSION = 'continue.session',
  CONVERSATION = 'conversation',
  CONVERSATIONS = 'conversations',
  CONVERSATION_ID_HEADER = 'conversation.id.header',
  COPY = 'copy',
  CHANGED = 'changed',
  CURRENT = 'current',
  CURRENT_ATTRIBUTE_NAME = 'current.attribute.name',
  CURRENT_ATTRIBUTE_TYPE = 'current.attribute.type',
  CURRENT_CHAT = 'current.chat',
  CURRENT_COLUMNS = 'current.columns',
  CURRENT_PAGE_HEADER = 'current.page.header',
  CURRENT_QUEUES_WITHIN_SLA = 'current.queues.within.sla',
  CURRENT_STATE_HEADER = 'current.state.header',
  CURRENT_PASSWORD = 'current.password',
  CUSTOMER = 'customer',
  CUSTOMER_ALL = 'customer.all',
  DATE_FULL_FORMAT = 'date.full.format',
  DATE_TIME = 'date.time',
  DEFINE_ATTRIBUTE_VIEW = 'define.attribute.view',
  DEGRADED_MODE_FALSE = 'degraded.mode.false',
  DEGRADED_MODE_TRUE = 'degraded.mode.true',
  DELETE = 'delete',
  DELETE_FILTER = 'delete.filter',
  DELETE_FILTER_INFO = 'delete.filter.info',
  DELETE_FILTER_TITLE = 'delete.filter.title',
  DELETE_VIEW = 'delete.view',
  DELETE_VIEW_INFO = 'delete.view.info',
  DELETE_VIEW_TITLE = 'delete.view.title',
  DESCENDING = 'descending',
  DELETE_ITEM = 'delete.item',
  DISCARD = 'discard',
  DISCARD_AND_LOGOUT = 'discard.and.logout',
  DISMISS = 'dismiss',
  DONT_SAVE = 'dont.save',
  DOCKED_CHAT_PANEL = 'docked.chat.panel.a11y',
  EXPORT = 'export',
  EXPORTING = 'exporting',
  DURATION = 'duration',
  DURATION_HEADER = 'duration.header',
  EDIT = 'edit',
  EDIT_CURRENT_VIEW = 'edit.current.view',
  EDIT_FILTER = 'edit.filter',
  EFFICIENCY = 'efficiency',
  EMPTY = 'empty',
  END_INTERNAL_CHAT = 'end.internal.chat',
  END_CHAT_TOOLTIP = 'end.chat.tooltip',
  END_CHAT_CONFIRM_TITLE = 'end.chat.confirm.title',
  END_CHAT_CONFIRM_CONTENT = 'end.chat.confirm.content',
  ENTER_VALUE = 'enter.value',
  ENTER_FILTER_NAME = 'enter.filter.name',
  ENGAGEMENT_ENDED_MESSAGE = 'engagement.ended.message',
  ENGAGEMENT_INACTIVE = 'engagement.inactive',
  ENGAGEMENT_ID = 'engagement.id',
  ENGAGEMENT_METRICS = 'engagement.metrics',
  ERROR = 'error',
  ERROR_OCCURED_TEXT = 'error.occured.text',
  ESCALATE = 'escalate',
  ESCALATED_CHATS = 'escalated.chats',
  ESCALATED_CHATS_KPI = 'escalated.chats.kpi',
  ESCALATED_COMMENT = 'escalated.comment',
  ESCALATE_CHAT = 'escalate.chat',
  EVERY = 'every',
  EXPAND = 'expand',
  EXPAND_CHAT_TOOLTIP = 'expand.chat.tooltip',
  EXPIRED = 'expired',
  FALSE = 'false',
  FIELD_REQUIRED = 'field.required',
  FILTER = 'filter',
  FILTERING_COLUMN = 'filtering.column',
  FILTERS = 'filters',
  FILTER_NAME = 'filter.name',
  FILTER_NAME_VALIDATION = 'filter.name.validation',
  FILTER_COLUMN_VALIDATION = 'filter.column.validation',
  FILTER_OPERATOR_VALIDATION = 'filter.operator.validation',
  FILTER_VALUE_VALIDATION = 'filter.value.validation',
  FIRST_NAME = 'first.name',
  FOLLOW_UP = 'follow.up',
  FOLLOW_UP_NOTES = 'follow.up.notes',
  HEADER = 'header',
  HIDDEN_COLUMNS = 'hidden.columns',
  HIDE = 'hide',
  HIDE_CHAT = 'hide.chat',
  HIDE_COLUMN_MESSAGE = 'hide.column.message',
  HIDE_COLUMN_TITLE = 'hide.column.title',
  HIGH = 'high',
  HOUR_ABBR = 'hour.abbr',
  ID = 'id',
  ILLEGAL_MSG = 'illegal.msg',
  IMPROPER_ACCOUNT_CONFIG = 'improper_account_config',
  INTERNAL = 'internal',
  INTERNAL_CHAT = 'internal.chat',
  INTERNAL_CHAT_LIST_ITEM = 'internal.chat.list.item.a11y',
  INTERNAL_CHATS = 'internal.chats',
  INTERNAL_CHATS_DISABLE = 'internal.chats.disable',
  INTERNAL_CHAT_HAS_ENDED = 'internal.chat.has.ended',
  INTERNAL_TRANSCRIPT_MENU = 'internal.transcript.menu',
  INVALID = 'invalid',
  IS_WAITING_HEADER = 'is.waiting.header',
  LABEL_ALL = 'label.all',
  LABEL_CLEAR = 'label.clear',
  LABEL_INTERNAL = 'label.internal',
  LABEL_NAME = 'label.name',
  LANGUAGE = 'language',
  LAST_NAME = 'last.name',
  LAUNCH_PAGE_HEADER = 'launch.page.header',
  LIMIT_INFO = 'limit.info',
  LIMIT_REACHED_INFO = 'limit.reached.info',
  LIVE_KPIS = 'live.kpis',
  LOADING = 'loading',
  LOADING_PLEASE_WAIT = 'loading.please.wait',
  LOADING_ALL_AGENT_GROUPS = 'loading.all.agent.groups',
  LOAD_PAST_ENGAGEMENTS = 'load.past.engagements',
  LOGGED_IN_HEADER = 'logged.in.header',
  LOGGED_IN_AT = 'logged.in.at',
  LOGGED_IN_AS = 'logged.in.as',
  LOGGED_OUT = 'logged.out',
  LOGIN = 'login',
  LOGIN_USING_SELECTION = 'login.using.selection',
  LOGIN_USING_SETTINGS = 'login.using.settings',
  LOGIN_DURATION_HEADER = 'login.duration.header',
  LOGIN_FAILURE = 'invalid',
  LOGOUT = 'logout',
  LOGOUT_DIALOG_TITLE = 'logout.dialog.title',
  LOGOUT_DIALOG_MESSAGE = 'logout.dialog.message',
  LOGOUT_AGENT_NAME = 'logout.agent.name',
  LOGOUT_FORCED_CONFIRMATION = 'logout.forced.confirmation',
  LOG_AGENT_OUT = 'log.agent.out',
  LONGEST_ACTIVE_CHAT = 'longest.active.chat',
  LONGEST_CHAT = 'longest.chat',
  LONGEST_WAIT_TIME = 'longest.wait.time',
  MAKE_SELECTIONS = 'make.selections',
  MANAGE_ALERTS = 'manage.alerts',
  MANAGE_COLUMNS_WINDOW = 'manage.columns.window',
  MANAGE_VIEWS = 'manage.views',
  MAX_CHATS_HEADER = 'max.chats.header',
  MAX_RESPONSE_HEADER = 'max.response.header',
  MAX_TIME_IN_QUEUE = 'max.time.in.queue',
  MAX_TIME_IN_QUEUE_HEADER = 'max.time.in.queue.header',
  MENU_CONTAINS = 'menu.contains',
  MENU_DOES_NOT_CONTAIN = 'menu.does.not.contain',
  MENU_DOES_NOT_END_WITH = 'menu.does.not.end.with',
  MENU_DOES_NOT_START_WITH = 'menu.does.not.start.with',
  MENU_ENDS_WITH = 'menu.ends.with',
  MENU_EQUALS = 'menu.equals',
  MENU_GREATER_THAN = 'menu.greater.than',
  MENU_GREATER_THAN_OR_EQUAL_TO = 'menu.greater.than.or.equal.to',
  MENU_IS_NOT_NULL = 'menu.is.not.null',
  MENU_IS_NULL = 'menu.is.null',
  MENU_LESS_THAN = 'menu.less.than',
  MENU_LESS_THAN_OR_EQUAL_TO = 'menu.less.than.or.equal.to',
  MENU_NOT_EQUAL = 'menu.not.equal',
  MENU_STARTS_WITH = 'menu.starts.with',
  MINIMIZE_CHAT = 'minimize.chat',
  MINIMIZED_CHAT_PANEL = 'minimized.chat.panel.a11y',
  MINIMIZED_INTERNAL_CHAT_LIST = 'minimized.internal.chat.list.a11y',
  MINIMIZE_CHAT_TOOLTIP = 'minimize.chat.tooltip',
  MINUTE_ABBR = 'minute.abbr',
  MINUTE_SHORT = 'minute.short',
  MODERATE = 'moderate',
  MORE_CHATS = 'more.chats.a11y',
  NAVIGATION_DRAWER = 'navigation.drawer.a11y',
  NEW_MESSAGES = 'new.messages',
  NEW_PASSWORD = 'new.password',
  NEXT = 'next',
  NO = 'no',
  NONE = 'none',
  NO_RECORDS_FOUND = 'no.records.found',
  NO_RECORDS_FOUND_BOOKMARK = 'no.records.found.bookmark',
  NO_ROTATION = 'no.rotation',
  NO_OPTIONS = 'no.options',
  NORMAL = 'normal',
  NUMBER_OF_TRANSFERS = 'number.of.transfers',
  OFFLINE_STATE_TITLE = 'offline.state.title',
  OFFLINE_STATE_MESSAGE = 'offline.state.message',
  OK = 'ok',
  ONLINE_STATE_TITLE = 'online.state.title',
  ONLINE_STATE_MESSAGE = 'online.state.message',
  OPERATOR = 'operator',
  OWNER_ATTRIBUTE = 'attribute.type.owner',
  PAGES = 'pages',
  PAGE_HEADER = 'page.header',
  PASSWORD = 'password',
  PASSWORD_CHANGED = 'password.changed',
  PASSWORD_EXPIRED = 'expired',
  PAUSE = 'pause',
  PERCENT_OF_CHATS_QUEUED_HEADER = 'percent.of.chats.queued.header',
  PERCENT_OF_QUEUES_WITHIN_SLA = 'percent.of.queues.within.sla',
  PERCENT_SALE_QUALIFIED = 'percent.sale.qualified',
  PERCENT_WITHIN_SLA_HEADER = 'percent.within.sla.header',
  PERSISTENT_COUNT_HEADER = 'persistent.count.header',
  PERSISTENT_HEADER = 'persistent.header',
  PINNED_CHATS = 'pinned.chats',
  POSITION_HEADER = 'position.header',
  POST_CHAT_SURVEY = 'post.chat.survey',
  PRIORITY_HEADER = 'priority.header',
  PRESS_SPACEBAR_TO_SORT = 'press.spacebar.to.sort',
  PREVIOUS = 'previous',
  RED = 'red',
  QUEUE = 'queue',
  QUEUED_CHATS_EXCEEDING = 'queued.chats.exceeding',
  QUEUED_CHATS_WITHIN = 'queued.chats.within',
  QUEUE_STATUS = 'queue.status',
  RANKING_HEADER = 'ranking.header',
  READ_FULL_MESSAGE = 'read.full.message.a11y',
  REFRESH = 'refresh',
  RED_ALERT_VALUE = 'red.alert.value',
  REMOVE = 'remove',
  REMOVE_ROW = 'remove.row',
  REMOVE_ALL = 'remove.all',
  REMOVE_CHAT_TOOLTIP = 'remove.chat.tooltip',
  REMOVE_FROM_LIST = 'remove.from.list',
  RELOGIN = 'relogin',
  PRESS_SPACEBAR_TO_REMOVE_SORT = 'press.spacebar.to.remove.sort',
  REPEAT_NEW_PASSWORD = 'repeat.new.password',
  REQUESTED_ATTRIBUTE = 'attribute.type.requested',
  REQUIRES_ATTENTION_COUNT_HEADER = 'requires.attention.count.header',
  RESET_COLUMN = 'reset.column',
  RESET_TABLE = 'reset.table',
  RESET_VIEW = 'reset.view',
  RESUME = 'resume',
  RETRIEVED_SHORTLY = 'retrieved.shortly',
  ROLLING_QUEUES_ABANDONED = 'rolling.queues.abandoned',
  ROLLING_QUEUES_WITHIN_SLA = 'rolling.queues.within.sla',
  ROLLING_TIME_IN_QUEUE = 'rolling.time.in.queue',
  ROTATE = 'rotate',
  ROTATE_INTERVAL = 'rotate.interval',
  SALE_QUALIFIED_CHATS = 'sale.qualified.chats',
  SAVE = 'save',
  SAVE_AS_NEW = 'save.as.new',
  SAVE_CHANGES = 'save.changes',
  SAVE_FILTER = 'save.filter',
  SAVING_NEW_FILTER = 'saving.new.filter',
  SECOND_ABBR = 'second.abbr',
  SECOND_SHORT = 'second.short',
  SCORE_HEADER = 'score.header',
  SEARCH = 'search',
  SEARCH_FROM_COLUMN = 'search.from.column',
  SEARCH_FROM_THE_TABLE = 'search.from.the.table',
  SECONDS = 'seconds',
  SCROLL_TO_BOTTOM = 'scroll.to.bottom',
  SEE_NEW_MESSAGES = 'see.new.messages',
  SELECTED = 'selected',
  SELECT_ATTR_NAME = 'select.attr.name',
  SELECT_ATTR_TYPE = 'select.attr.type',
  SELECT_COLOR = 'select.color',
  SELECT_COLUMN = 'select.column',
  SELECT_FILTER = 'select.filter',
  SELECT_OPERATOR = 'select.operator',
  SELECT_OPTION = 'select.option',
  SELECT_OPTION_LIST = 'select.option.list',
  SELECT_TAB_VIEW = 'select.tab.view',
  SELECT_HOURS = 'select.hours',
  SELECT_MINUTES = 'select.minutes',
  SELECT_SECONDS = 'select.seconds',
  SELECT_DATE_TIME = 'select.date.time',
  SEND = 'send',
  SENDING = 'sending',
  SETTINGS = 'settings',
  SEND_A_MESSAGE = 'send.a.message',
  SEND_CHAT_TOOLTIP = 'send.chat.tooltip',
  SESSION_AUTO_EXPIRED = 'session.auto.expired',
  SESSION_TIMEOUT = 'session.timeout',
  SESSION_TIMEOUT_MESSAGE = 'session.timeout.message',
  SHOW_DETAILS = 'show.details',
  SHOW_COLUMN_SEARCH = 'show.column.search',
  SHOW_ONLY = 'show.only',
  SINGLE_UNIT_SELECTED = 'single.unit.selected',
  SITE = 'site',
  SKIP_TO_CONTENT = 'skip.to.content',
  SKIP_TO_NAVIGATION = 'skip.to.navigation',
  SLA = 'sla',
  SSO_LOGIN_INFO = 'sso.login.info',
  STATUS_AGENT_WAIT = 'status.agent.wait',
  STATUS_AGENT_WAIT_CONF = 'status.agent.wait.conf',
  STATUS_CUST_WAIT = 'status.cust.wait',
  STATUS_CUST_WAIT_CONF = 'status.cust.wait.conf',
  STATUS_DISPOSITION = 'status.disposition',
  STATUS_ESCALATED_CHATS = 'status.escalated.chats',
  STATUS_HEADER = 'status.header',
  STATUS_TIME_HEADER = 'status.time.header',
  STATUS_WAIT_TRANSFER = 'status.wait.transfer',
  STATUSES = 'statuses',
  SUBMIT = 'submit',
  SUMMARY = 'summary',
  SUMMARY_HYPHEN = 'summary.hyphen',
  SUB_TAB = 'sub.tab',
  TAB_STATUSES = 'tab.statuses',
  THE_ENGAGEMENT_HAS_ENDED = 'the.engagement.has.ended',
  TIME_IN_QUEUE_HEADER = 'time.in.queue.header',
  TIME_IN_QUEUE_LONGEST = 'time.in.queue.longest',
  TIME_IN_STATE_HEADER = 'time.in.state.header',
  TIME_SINCE_CUSTOMER_MESSAGE_HEADER = 'time.since.custmsg.header',
  TIME_SINCE_LAST_ENGAGEMENT = 'time.since.last.engagement',
  TIME_TO_EXPIRE = 'time.to.expire',
  TIMES_ON_BUSY_HEADER = 'times.on.busy.header',
  TITLE = 'title',
  TOTAL_BUSY_TIME_HEADER = 'total.busy.time.header',
  TOTAL_CHATS_HEADER = 'total.chats.header',
  TOTAL_IN_QUEUE_HEADER = 'total.in.queue.header',
  TOTAL_RESULTS_FOUND = 'total.results.found',
  TRANSCRIPT = 'transcript',
  TRANSFERRED_COUNT_HEADER = 'transferred.count.header',
  TRANSCRIPT_SR_ONLY = 'transcript.internal.chat.header.a11y',
  TRENDING_AVG = 'trending.avg',
  TRENDING_PERCENT = 'trending.percent',
  TRENDING_PERCENT_OF_ABANDONS = 'trending.percent.of.abandons',
  UNDELETABLE_COLUMNS = 'undeletable.columns',
  UNDELIVERED = 'undelivered',
  UNRESOLVED_CHATS = 'unresolved.chats',
  UNSAVED = 'unsaved',
  UNSAVED_FILTER_INFO = 'unsaved.filter.info',
  UNSAVED_FILTER_TITLE = 'unsaved.filter.title',
  UPDATE_FILTER = 'update.filter',
  UPDATING_FILTER = 'updating.filter',
  USER = 'user',
  USERNAME = 'username',
  VALUE = 'value',
  VALUES_FOR = 'values.for',
  VIEW_LESS = 'view.less',
  VIEW_MORE = 'view.more',
  VIEW_NAME = 'view.name',
  VIEW_NAME_VALIDATION = 'view.name.validation',
  VISITOR_ATTRIBUTE = 'attribute.type.visitor',
  WARNING = 'warning',
  YELLOW = 'yellow',
  YELLOW_ALERT_VALUE = 'yellow.alert.value',
  YES = 'yes',
  YOU = 'you',
  ZERO_SECONDS = 'zero.seconds.a11y',
}

export default LocaleKey;
