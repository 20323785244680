import { createAction } from 'redux-actions';

export enum Type {
  OPEN_NAVIGATION_DRAWER = 'OPEN_NAVIGATION_DRAWER',
  CLOSE_NAVIGATION_DRAWER = 'CLOSE_NAVIGATION_DRAWER',
}

const openNavigationDrawer = createAction(Type.OPEN_NAVIGATION_DRAWER);
const closeNavigationDrawer = createAction(Type.CLOSE_NAVIGATION_DRAWER);

export const NavigationActions = {
  openNavigationDrawer,
  closeNavigationDrawer,
};

export type NavigationActionType = typeof NavigationActions;
