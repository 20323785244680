import { Action, handleActions } from 'redux-actions';
import SubTabIndex from '@/enums/SubTabIndex';
import { CurrentView } from '@/interfaces/currentView';
import CurrentViewActionsType from '@/actions/sync/currentViewActions';
import Category from '@/enums/Category';
import Dimension from '@/enums/Dimension';
import { ViewActionType } from '@/actions/sync/supervisorViewActions';
import { CustomColumn } from '@/components/organisms/CustomTable/customTable.types';
import PageType from '@/enums/PageType';

export const DEFAULT_CURRENT_VIEW: CurrentView = {
  index: SubTabIndex.ZERO,
  category: Category.SUMMARY,
  dimension: Dimension.SUMMARY,
  name: '',
  tableId: null,
  columns: [],
  isCustom: false,
  returnFields: [],
  pageType: PageType.SUMMARY,
};

export type CurrentViewPaylod = CurrentView & CustomColumn[];

const processUpdateCurrentViewAction = (
  state: CurrentView,
  action: Action<CurrentView>,
): CurrentView => (
  {
    ...action.payload,
  }
);

const processUpdateViewColumnsAction = (
  state: CurrentView,
  action: Action<CustomColumn[]>,
): CurrentView => (
  {
    ...state,
    columns: action.payload,
  }

);

const currentViewReducer = handleActions<CurrentView, CurrentViewPaylod>(
  {
    [CurrentViewActionsType.UPDATE_CURRENT_VIEW]: processUpdateCurrentViewAction,
    [ViewActionType.UPDATE_VIEW_COLUMNS_REORDERED]: processUpdateViewColumnsAction,
  },
  DEFAULT_CURRENT_VIEW,
);

export default currentViewReducer;
