import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { authorizedGet, authorizedPost, getUserName } from '@/api/oAuth2ClientAPI';
import URL from '@/api/url';
import selectRegisteredId from '@/selectors/registeredIdSelector';
import { store } from '@/stores/store';
import { ExitChatResponse, InternalMessagesResponse, JoinChatResponse } from '@/interfaces/Chat';
import contentType from '@/constants/contentType';
import { ChangePasswordPayload } from '@/interfaces/User';
import { post } from './httpClientAPI';
import { RegisteredIdActions } from '@/actions/sync/userActions';

function responseHandler<T>(errorResponse: AxiosResponse): Promise<T | void> | undefined {
  if (errorResponse && errorResponse.status === 401) {
    store.dispatch(RegisteredIdActions.requestUpdateRegisteredId());
    return undefined;
  }
  return Promise.reject(errorResponse);
}

function authorizedAeapiGet<T>(
  url: string,
  data: string | null,
  headers?: AxiosRequestHeaders,
  timeout?: number,
): Promise<T | void> {
  return authorizedGet<T | void>(url, data, headers, timeout)
    .catch<T | void>(responseHandler);
}

export function authorizedAeapiPost<T>(
  url: string,
  data: string | null,
  headers?: AxiosRequestHeaders,
): Promise<T | void> {
  return authorizedPost<T | void>(url, data, headers)
    .catch<T | void>(responseHandler);
}

export function verifySession(): Promise<void> {
  const state = store.getState();
  const agentId = getUserName();
  const registeredId = selectRegisteredId(state);
  const url: string = `${URL.API.ENGAGEMENT_API_V2_AGENT.VERIFY_SESSION}?`
    + `agentId=${agentId}`
    + `&registeredId=${registeredId}`;
  const data = { agentId };
  return authorizedAeapiPost(url, JSON.stringify(data));
}

export function loadMessages(): Promise<InternalMessagesResponse | void> {
  const registeredId = selectRegisteredId(store.getState());
  const params = new URLSearchParams({
    registeredId,
    agentId: getUserName() || '',
  }).toString();
  const url: string = `${URL.API.ENGAGEMENT_API_V2_AGENT.MESSAGES}?${params}`;
  return authorizedAeapiGet(url, null, undefined, 40000);
}

export function joinChat(engagementId): Promise<JoinChatResponse | void> {
  const registeredId = selectRegisteredId(store.getState());
  const data = {
    engagementId,
    agentId: getUserName(),
  };
  const url: string = `${URL.API.ENGAGEMENT_API_V2_AGENT.JOIN_ENGAGEMENT}?registeredId=${registeredId}`;
  return authorizedPost(url, JSON.stringify(data));
}

export function postMessage(engagementId, message): Promise<InternalMessagesResponse | void> {
  const registeredId = selectRegisteredId(store.getState());
  const data = {
    agentId: getUserName(),
    engagementId,
    messageType: 'chatLine',
    messageText: message.content,
  };
  const url: string = `${URL.API.ENGAGEMENT_API_V2_AGENT.MESSAGES}?registeredId=${registeredId}`;
  return authorizedAeapiPost(url, JSON.stringify(data));
}

export function exitChat(engagementId): Promise<ExitChatResponse | void> {
  const registeredId = selectRegisteredId(store.getState());
  const data = {
    engagementId,
    agentId: getUserName(),
  };
  const url: string = `${URL.API.ENGAGEMENT_API_V2_AGENT.SUPERVISOR.EXIT_ENGAGEMENT}?registeredId=${registeredId}`;
  return authorizedAeapiPost(url, JSON.stringify(data));
}

export function changePassword({
  username: agentId,
  oldPassword,
  newPassword,
  confirmNewPassword,
}: ChangePasswordPayload) {
  const data = {
    agentId,
    oldPassword,
    newPassword,
    confirmNewPassword,
  };

  const headers: AxiosRequestHeaders = {
    'Content-Type': contentType.JSON,
  };

  return post(URL.API.ENGAGEMENT_API_V2_AGENT.CHANGE_PASSWORD, JSON.stringify(data), headers);
}

export function logoutAeapi(): Promise<void> {
  const state = store.getState();
  const agentId = getUserName();
  const registeredId = selectRegisteredId(state);
  const url: string = `${URL.API.ENGAGEMENT_API_V2_AGENT.LOGOUT}?`
    + `registeredId=${registeredId}`
    + `&agentId=${agentId}`;

  return authorizedAeapiPost(url, JSON.stringify({ agentId }));
}
