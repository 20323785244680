enum Dimension {
  SUMMARY = 'summary',
  AGENTS = 'agents',
  CONVERSATIONS = 'conversations',
  BUSINESS_RULES = 'businessRules',
  ENGAGEMENTS = 'engagements',
  PAGES = 'pages',
  CUSTOM_BUSINESS_RULE_ATTRIBUTE_DIVISION = 'businessRuleAttribute.Division',
  CUSTOM_VISITOR_ATTRIBUTE_CUSTOMER_VALUE = 'visitorAttribute.Customer%20Value',
}

export default Dimension;
