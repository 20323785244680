import {
  put,
  takeEvery,
  select,
  call,
  delay,
} from 'redux-saga/effects';
import {
  selectRotationPauseState,
  selectRotationSettingInfo,
  selectRotationSettings,
  selectManualRotationPauseState,
} from '@/selectors/rotationSelector';
import openActionItem from '@/selectors/openActionItemSelector';
import SelectedUnit from '@/enums/SelectedUnit';
import { ApplicationSettingsActions } from '@/actions/sync/applicationSettingsActions';
import Type, { RotationActions } from '@/actions/sync/rotationActions';
import {
  selectUnit,
  getCurrentSetting,
} from '@/selectors/applicationSettingsSelector';
import { BusinessUnit } from '@/interfaces/BusinessUnit';
import { AgentGroup } from '@/interfaces/AgentGroup';

function* updateSettings(isPrevious) {
  const currentSettings = yield select(getCurrentSetting);
  const rotationSettings = yield select(selectRotationSettings);
  const rotationSettingsInfo: (BusinessUnit | AgentGroup)[] = (
    yield select(selectRotationSettingInfo));
  const selectedUnit = yield select(selectUnit);
  let currentIndex = isPrevious ? 0 : -1;

  if (currentSettings.length === 1) {
    rotationSettingsInfo.forEach((setting, index) => {
      if (setting.settingId === currentSettings[0].settingId) {
        currentIndex = index;
      }
    });
  }
  const nextIndex = (currentIndex + 1) % rotationSettings.length;
  const prevIndex = ((currentIndex + rotationSettings.length) - 1) % rotationSettings.length;
  const indexPosition = isPrevious ? prevIndex : nextIndex;
  if (selectedUnit === SelectedUnit.BU) {
    yield put(ApplicationSettingsActions.selectedBusinessUnitsChanged(
      [rotationSettings[indexPosition]] as string[],
    ));
  } else {
    yield put(ApplicationSettingsActions.selectedAgentGroupsChanged(
      [rotationSettings[indexPosition]] as string[],
    ));
  }
}

function* executeUpdateSettings(isPrevious) {
  const isRotationPaused = yield select(selectRotationPauseState);
  if (isRotationPaused) {
    yield call(updateSettings, isPrevious);
  } else {
    yield put(RotationActions.setRotationPause(true));
    yield call(updateSettings, isPrevious);
    // delay to prevent batching of dispatched action
    yield delay(0);
    yield put(RotationActions.setRotationPause(false));
  }
}

export function* nextRotationSettings() {
  yield call(executeUpdateSettings, false);
}

export function* autoRotationSettings() {
  yield call(updateSettings, false);
}

export function* previousRotationSettings() {
  yield call(executeUpdateSettings, true);
}

export function* pauseRotation() {
  const isRotationPausedManually = yield select(selectManualRotationPauseState);
  if (!isRotationPausedManually) {
    const isMenuItemOpened = yield select(openActionItem);
    yield put(RotationActions.setRotationPause(!!isMenuItemOpened));
  }
}

export default function* rotationSaga() {
  yield takeEvery(Type.NEXT_ROTATION_SETTINGS, nextRotationSettings);
  yield takeEvery(Type.AUTO_ROTATE_SETTINGS, autoRotationSettings);
  yield takeEvery(Type.PREVIOUS_ROTATION_SETTINGS, previousRotationSettings);
  yield takeEvery(Type.REQUEST_ROTATION_PAUSE, pauseRotation);
}
