import {
  AttributeNameDropdownData,
  AttributePairDropdownData,
  AttributesData,
  AttributeTypeDropdownData,
  ViewAttributesData,
} from '@/interfaces/AttributesData';
import Category from '@/enums/Category';
import { AttributeKeys, AttributeTypes, AttributeLabels } from '@/enums/Attributes';
import { CurrentView } from '@/interfaces/currentView';
import { Attributes, AttributeType } from '@/constants/attributes';

const buildQueueViewAttributesData = (
  attributesData: AttributesData,
): ViewAttributesData[] => ([
  {
    key: AttributeKeys.RULE,
    type: AttributeTypes.RULE,
    label: AttributeLabels.RULE,
    shortLabel: AttributeLabels.RULE_SHORT,
    attributes: attributesData.br_custom,
  },
  {
    key: AttributeKeys.VISITOR,
    type: AttributeTypes.VISITOR,
    label: AttributeLabels.VISITOR,
    shortLabel: AttributeLabels.VISITOR_SHORT,
    attributes: attributesData.visitor,
  },
]);

const buildEngagementViewAttributesData = (
  attributesData: AttributesData,
): ViewAttributesData[] => ([
  {
    key: AttributeKeys.RULE,
    type: AttributeTypes.RULE,
    label: AttributeLabels.RULE,
    shortLabel: AttributeLabels.RULE_SHORT,
    attributes: attributesData.br_custom,
  },
  {
    key: AttributeKeys.AGENT,
    type: AttributeTypes.AGENT,
    label: AttributeLabels.AGENT,
    shortLabel: AttributeLabels.AGENT_SHORT,
    attributes: attributesData.agent,
  },
  {
    key: AttributeKeys.VISITOR,
    type: AttributeTypes.VISITOR,
    label: AttributeLabels.VISITOR,
    shortLabel: AttributeLabels.VISITOR_SHORT,
    attributes: attributesData.visitor,
  },
]);

const buildViewAttributesData = (
  category: Category,
  attributesData: AttributesData,
): ViewAttributesData[] => (category === Category.QUEUE
  ? buildQueueViewAttributesData(attributesData)
  : buildEngagementViewAttributesData(attributesData)
);

export const buildViewTypeData = (
  attributePair: AttributePairDropdownData,
): AttributeTypeDropdownData => ({
  id: attributePair.typeId,
  label: attributePair.typeLabel,
} as AttributeTypeDropdownData);

export const buildViewNameData = (
  attributePair: AttributePairDropdownData,
): AttributeNameDropdownData => ({
  id: attributePair.nameId,
  label: attributePair.nameLabel,
});

export const buildAttributesForCurrentView = (
  attributesData: AttributesData,
  { name }: CurrentView,
): ViewAttributesData[] => {
  const allAttributes = Object.entries(Attributes).map(
    ([key, attributes]): ViewAttributesData[] => (
      attributes.filter((attribute: AttributeType): boolean => (
        (
          attribute.hideOn && !attribute.hideOn.includes(name)
        ) || attribute.showOn.includes(name)
      )).map((attribute: AttributeType): ViewAttributesData => ({
        key: attribute.data,
        type: attribute.type,
        label: attribute.label,
        shortLabel: attribute.shortName,
        attributes: attributesData[key],
      }))
    ),
  ).reduce((
    previous: ViewAttributesData[],
    current: ViewAttributesData[],
  ): ViewAttributesData[] => previous.concat(current));

  return allAttributes;
};

export const getAttributeKey = (type: string): AttributeKeys => {
  let key: AttributeKeys = AttributeKeys.AGENT;
  if (type === AttributeTypes.AGENT) {
    key = AttributeKeys.AGENT;
  } else if (type === AttributeTypes.REQUESTED) {
    key = AttributeKeys.REQUESTED;
  } else if (type === AttributeTypes.RULE) {
    key = AttributeKeys.RULE;
  } else if (type === AttributeTypes.VISITOR) {
    key = AttributeKeys.VISITOR;
  } else {
    throw new Error(`Unexpected attribute type found: ${type}`);
  }
  return key;
};

export const getAttributeShortLabel = (type: string): AttributeLabels => {
  let key: AttributeLabels = AttributeLabels.AGENT_SHORT;
  if (type === AttributeTypes.AGENT) {
    key = AttributeLabels.AGENT_SHORT;
  } else if (type === AttributeTypes.REQUESTED) {
    key = AttributeLabels.REQUESTED_SHORT;
  } else if (type === AttributeTypes.RULE) {
    key = AttributeLabels.RULE_SHORT;
  } else if (type === AttributeTypes.VISITOR) {
    key = AttributeLabels.VISITOR_SHORT;
  } else {
    throw new Error(`Unexpected attribute type found: ${type}`);
  }
  return key;
};

export default buildViewAttributesData;
