import {
  applyMiddleware, createStore, Store,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { CustomScript } from '@/interfaces/CustomScript';
import rootReducer from '@/reducers/rootReducer';
import { User } from '@/interfaces/User';
import { ApplicationSettings } from '@/interfaces/ApplicationSettings';
import { Oauth2State } from '@/interfaces/Oauth2State';
import { CustomViewsData } from '@/interfaces/SupervisorView';
import rootSaga from '@/sagas/rootSaga';
import { SnackBarMessage } from '@/interfaces/SnackbarMessage';
import { Bookmarked } from '@/interfaces/Bookmarked';
import Rotation from '@/interfaces/Rotation';
import { TableConfig } from '@/interfaces/TableConfig';
import { Summary } from '@/interfaces/Summary';
import { ActiveFilter } from '@/interfaces/activeFilter';
import { CurrentView } from '@/interfaces/currentView';
import { ChatManager } from '@/interfaces/Chat';
import { ConversationManager } from '@/interfaces/Conversation';
import { TableData } from '@/interfaces/TableData';
import { NavigationConfig } from '@/interfaces/NavigationConfig';
import history from '@/services/history';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

/**
 * Interface state which is managed by Redux. State can describe state of application including,
 * but not limited: Sites, BUs, AGs, etc.
 */
export interface StoreState {
  activeFilter: ActiveFilter;
  applicationSettings: ApplicationSettings;
  bookmarked: Bookmarked;
  chatManager: ChatManager;
  conversationManager: ConversationManager;
  currentView: CurrentView;
  customScript: CustomScript;
  oauth2: Oauth2State;
  summary: Summary;
  supervisorViews: CustomViewsData;
  tableConfig: TableConfig;
  rotation: Rotation;
  user: User;
  messageInfo: SnackBarMessage,
  registeredId: string,
  tableData: TableData,
  navigation: NavigationConfig,
  onlineStatus: boolean,
  openActionItem: string,
}

const queryParams = new URLSearchParams(history.location.search);
let devTools = applyMiddleware(...middlewares);

if (process.env.NODE_ENV === 'development' || queryParams.get('enableDebug') === 'true') {
  devTools = composeWithDevTools(devTools);
}

export const store: Store<StoreState> = createStore(
  rootReducer,
  undefined,
  devTools,
);
sagaMiddleware.run(rootSaga);
