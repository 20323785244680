import { Action, handleActions } from 'redux-actions';
import { DimensionMetrics } from '@/interfaces/DimensionMetrics';
import { RealtimeMetricResponse } from '@/interfaces/responses/RealtimeMetricsResponse';
import { TableData, TableDataPayload } from '@/interfaces/TableData';
import { Type } from '@/actions/sync/tableDataActions';

export const initialState: TableData = {
  fetchedData: {} as RealtimeMetricResponse<DimensionMetrics>,
  isLoading: true,
  shouldStopRequests: false,
};

const processUpdateDataAction = (
  state: TableData,
  action: Action<RealtimeMetricResponse<DimensionMetrics>>,
): TableData => ({
  ...state,
  fetchedData: action.payload,
});

const processSetLoading = (
  state: TableData,
  action: Action<boolean>,
): TableData => ({
  ...state,
  isLoading: action.payload,
});

const processShouldStopRequest = (
  state: TableData,
  action: Action<boolean>,
): TableData => ({
  ...state,
  shouldStopRequests: action.payload,
});

const tableDataReducer = handleActions<TableData, TableDataPayload>(
  {
    [Type.UPDATE_FETCHED_DATA]: processUpdateDataAction,
    [Type.SET_LOADING]: processSetLoading,
    [Type.SHOULD_STOP_REQUESTS]: processShouldStopRequest,
  },
  initialState,
);

export default tableDataReducer;
