import { handleActions, Action, ActionMeta } from 'redux-actions';
import { ApplicationSettings } from '@/interfaces/ApplicationSettings';
import SelectedUnit from '@/enums/SelectedUnit';
import { Site } from '@/interfaces/Site';
import Type, { ApplicationSettingsPayloads } from '@/actions/sync/applicationSettingsActions';

export const applicationSettingsInitialState: ApplicationSettings = {
  selectedUnit: SelectedUnit.BU,
  selectedSite: {} as Site,
  selectedBusinessUnitIds: {},
  selectedAgentGroupIds: {},
  showTranscriptDetails: true,
  isColumnSearchVisible: false,
  subTabIndex: 0,
  portalSettingsChanged: false,
  searchValue: '',
  degradedServiceMode: {
    isBannerOpen: false,
    isOn: false,
  },
  isLowResolution: false,
  shouldApplyLastSettings: true,
};

const processUpdateApplicationSettingsAction = (
  state: ApplicationSettings,
  action: Action<ApplicationSettings>,
): ApplicationSettings => {
  const { isLowResolution, ...payload } = action.payload;
  return {
    ...state,
    ...payload,
  };
};

const processAction = (fieldName: string) => ((state, action) => ({
  ...state,
  [fieldName]: action.payload,
}));

const processPortalSettingsChangedAction = (
  state: ApplicationSettings,
  action: Action<boolean>,
): ApplicationSettings => processAction('portalSettingsChanged')(state, action);

const setDegradedModeBannerOpened = (
  state: ApplicationSettings,
  action: Action<boolean>,
): ApplicationSettings => ({
  ...state,
  degradedServiceMode: {
    ...state.degradedServiceMode,
    isBannerOpen: action.payload,
  },
});

const setDegradedMode = (
  state: ApplicationSettings,
  action: Action<boolean>,
): ApplicationSettings => ({
  ...state,
  degradedServiceMode: {
    ...state.degradedServiceMode,
    isOn: action.payload,
  },
});

const processUpdateBuAgIds = (fieldName: string) => (
  state: ApplicationSettings,
  {
    payload,
    meta: siteId,
  }: ActionMeta<string[], number>,
): ApplicationSettings => ({
  ...state,
  [fieldName]: {
    ...state[fieldName],
    [siteId || state.selectedSite.id]: payload,
  },
});

export const applicationSettingsReducer = handleActions<
ApplicationSettings, ApplicationSettingsPayloads>(
  {
    [Type.UPDATE_APPLICATION_SETTINGS]: processUpdateApplicationSettingsAction,
    [Type.SELECTED_SITE_CHANGED]: processAction('selectedSite'),
    [Type.SELECTED_UNIT_CHANGED]: processAction('selectedUnit'),
    // @ts-ignore
    [Type.SELECTED_AGENT_GROUPS_CHANGED]: processUpdateBuAgIds('selectedAgentGroupIds'),
    // @ts-ignore
    [Type.SELECTED_BUSINESS_UNITS_CHANGED]: processUpdateBuAgIds('selectedBusinessUnitIds'),
    [Type.SHOW_TRANSCRIPT_DETAILS]: processAction('showTranscriptDetails'),
    [Type.SHOW_COLUMN_SEARCH]: processAction('isColumnSearchVisible'),
    [Type.SET_SUB_TAB_INDEX]: processAction('subTabIndex'),
    [Type.PORTAL_SETTINGS_CHANGED]: processPortalSettingsChangedAction,
    [Type.UPDATE_SEARCH_VALUE]: processAction('searchValue'),
    [Type.SET_DEGRADED_BANNER_OPENED]: setDegradedModeBannerOpened,
    [Type.SET_DEGRADED_BANNER_STATE]: setDegradedMode,
    [Type.UPDATE_LOW_RESOLUTION]: processAction('isLowResolution'),
    [Type.SET_LAST_SETTING]: processAction('shouldApplyLastSettings'),
  },
  applicationSettingsInitialState,
);
