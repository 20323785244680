import { createIntlCache, createIntl } from 'react-intl';
import { getUiLocale } from '@/selectors/customScriptSelector';
import { store } from '@/stores/store';
import formatStringAsJson from '@/utils/formatStringAsJson';
import { MESSAGES } from '@/constants/WebStorageKey';
import { getFromWebStorage } from '@/services/webStorageService';
import messages_en from '@/locales/english.json';

export const handleReactIntlError = (err) => {
  if (err.code !== 'MISSING_TRANSLATION') {
    // eslint-disable-next-line no-console
    console.error(err);
  } else {
    const { id } = err.descriptor;
    // eslint-disable-next-line no-console
    console.warn(`TRANSLATION MISSING for ${id}`);
  }
};

export const getLocalKey = (label): string => (
  `${label.toUpperCase().split(' ').join('_')}_ATTRIBUTE`
);

/**
 * @deprecated Use useIntl() hook instead to handle locale changes properly
 */
function getStringByLocaleKey(localeKey: string): string {
  const cache = createIntlCache();
  const uiLocale = getUiLocale(store.getState());
  const localeMessages = (formatStringAsJson(
    getFromWebStorage(MESSAGES),
  ) || messages_en) as object;
  const intl = createIntl({
    locale: uiLocale,
    messages: { ...localeMessages },
    onError: handleReactIntlError,
  }, cache);
  return intl.formatMessage({ id: localeKey });
}

export default getStringByLocaleKey;
