import { createAction } from 'redux-actions';

export enum Type {
  UPDATE_ONLINE_STATUS = 'UPDATE_ONLINE_STATUS',
}

const updateOnlineStatus = createAction<boolean>(Type.UPDATE_ONLINE_STATUS);

export const OnlineStatusActions = {
  updateOnlineStatus,
};

export type OnlineStatusActionsType = typeof OnlineStatusActions;
