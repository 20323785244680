import { createAction } from 'redux-actions';
import { ViewNameType } from '@/interfaces/SupervisorView';

export enum Type {
  SET_SORT_OPTIONS = 'SET_SORT_OPTIONS',
  UPDATE_SORT_OPTIONS = 'UPDATE_SORT_OPTIONS',
  REMOVE_SORT_OPTIONS = 'REMOVE_SORT_OPTIONS',
  RESET_SORT_OPTIONS = 'RESET_SORT_OPTIONS',
}
const setSortOptions = createAction<SortOptionsPayload>(Type.SET_SORT_OPTIONS);
const updateSortOptions = createAction<SortOptionsPayload>(Type.UPDATE_SORT_OPTIONS);
const removeSortOptions = createAction<SortOptionsPayload>(Type.REMOVE_SORT_OPTIONS);
const resetSortOptions = createAction<ResetSortOptionsPayload>(Type.RESET_SORT_OPTIONS);

export const TableActions = {
  setSortOptions,
  updateSortOptions,
  removeSortOptions,
  resetSortOptions,
};

export type SortOptionsPayload = {
  dataKey: string;
  settingId: string;
  viewName: ViewNameType;
};

export type ResetSortOptionsPayload = {
  settingId: string;
  viewName: ViewNameType;
};

export type TableConfigPayloads = SortOptionsPayload & ResetSortOptionsPayload;
