/**
 * Enum of error types.
 * @static
 * @readonly
 */
enum ERROR {
  ACCESS_DENIED = 'access_denied',
  ACCOUNT_EXPIRED = 'account_expired',
  AGENT_HAS_CHATS_FORCED_LOGOUT_ERROR = 'agent.has.chats.forced.logout.error',
  INVALID_TOKEN = 'invalid_token',
  INVALID_GRANT = 'invalid_grant',
  NETWORK_ERROR = 'Network Error',
  UNAUTHORIZED = 'Unauthorized',
}

export default ERROR;
