import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { DimensionMetrics } from '@/interfaces/DimensionMetrics';
import { ApplicationSettingsActions } from '@/actions/sync/applicationSettingsActions';

import { RealtimeMetricResponse } from '@/interfaces/responses/RealtimeMetricsResponse';
import { getChatsIdRequestString } from '@/selectors/chatManagerSelector';
import { store } from '@/stores/store';
import { authorizedFullResponseSend } from '@/api/oAuth2ClientAPI';
import URL from '@/api/url';
import Category from '@/enums/Category';
import Dimension from '@/enums/Dimension';
import { SummaryResponse } from '@/interfaces/Summary';
import {
  getCurrentSiteId,
  buildBuAndAgParams,
} from '@/selectors/applicationSettingsSelector';
import { TranscriptResponse, ChatDataResponse } from '@/interfaces/Chat';
import { ConversationResponse, ConversationStartDateResponse } from '@/interfaces/Conversation';
import RequestMethod from '@/enums/RequestMethod';

function errorHandler<T>(errorResponse: AxiosResponse): Promise<T | void> | undefined {
  if (errorResponse && errorResponse.status === 403) {
    store.dispatch(ApplicationSettingsActions.portalSettingsChanged(true));
    return undefined;
  }
  return Promise.reject(errorResponse);
}

export function authorizedRTMGet<T>(
  url: string,
  data: string | null,
  checkDegradedMode: boolean = true,
  headers?: AxiosRequestHeaders,
): Promise<T | void> {
  return authorizedFullResponseSend<T>({
    method: RequestMethod.GET,
    url,
    data,
    headers,
  }).then((response) => {
    if (response && response.headers && checkDegradedMode) {
      store.dispatch(ApplicationSettingsActions.updateRTMDegradedState(
        response.headers['degraded-mode'] === 'true',
      ));
    }
    return response;
  }).then((response) => response && response.data);
}

export function loadTableData(url): Promise<RealtimeMetricResponse<DimensionMetrics> | void> {
  return authorizedRTMGet<RealtimeMetricResponse<DimensionMetrics>>(url, null, true, {
    'X-Requested-With': 'XMLHttpRequest',
  })
    .catch<RealtimeMetricResponse<DimensionMetrics> | void>(errorHandler);
}

function loadSummary(
  categoryName: Category,
): Promise<SummaryResponse | void> {
  const state = store.getState();
  const url: string = `${URL.API.V3.METRIC_REALTIME}?`
    + 'returnFields=ALL'
    + `&site=${getCurrentSiteId(state)
    }${buildBuAndAgParams(state)
    }&category=${categoryName
    }&dimension=${Dimension.SUMMARY}`;

  return authorizedRTMGet<SummaryResponse>(url, null)
    .catch<SummaryResponse | void>(errorHandler);
}

export function loadAgentSummary(): Promise<SummaryResponse | void> {
  return loadSummary(Category.AGENT);
}

export function loadEngagementSummary(): Promise<SummaryResponse | void> {
  return loadSummary(Category.ENGAGEMENT);
}

export function loadQueueSummary(): Promise<SummaryResponse | void> {
  return loadSummary(Category.QUEUE);
}

export function loadChatsTranscript(): Promise<TranscriptResponse | void> {
  const state = store.getState();
  const returnFields = [
    'engagementID',
    'transcript',
  ].join(',');
  const url: string = `${URL.API.V3.TRANSCRIPT}?`
    + `returnFields=${returnFields
    }&site=${getCurrentSiteId(state)
    }${buildBuAndAgParams(state)
    }&filter=${getChatsIdRequestString(state)}`;
  return authorizedRTMGet(url, null, false);
}

export function loadConversation(
  engagementID: string,
  conversationID: string,
): Promise<ConversationResponse | void> {
  const state = store.getState();
  const filter = `(engagementID!=${engagementID}  AND (conversationID=${conversationID}))`;
  const returnFields = [
    'engagementID',
    'transcript',
    'startDate',
    'includeActive=1',
  ].join(',');
  const url = `${URL.API.V3.CONVERSATION_TRANSCRIPT}?site=${getCurrentSiteId(state)}`
    + `&filter=${filter}`
    + `&returnFields=${returnFields}`;
  return authorizedRTMGet<ConversationResponse>(url, null, false)
    .catch<ConversationResponse | void>(errorHandler);
}

export function loadChatsData(): Promise<ChatDataResponse | void> {
  const state = store.getState();
  const returnFields = [
    'engagementID',
    'finalOwningAgentID',
    'engagementDuration',
    'engagementAvgAgentResponseTime',
    'businessRuleName',
    'finalOwningAgentFullName',
    'engagementActive',
    'conversationID',
  ].join(',');
  const url: string = `${URL.API.V3.METRIC_REALTIME}?`
    + `returnFields=${returnFields
    }&site=${getCurrentSiteId(state)
    }${buildBuAndAgParams(state)
    }&category=${Category.ENGAGEMENT
    }&dimension=${Dimension.ENGAGEMENTS
    }&filter=${getChatsIdRequestString(state)
    } AND queued=false`;
  return authorizedRTMGet<ChatDataResponse>(url, null)
    .catch<ChatDataResponse | void>(errorHandler);
}

export function loadConversationStartDate(
  conversationID: string,
): Promise<ConversationStartDateResponse> {
  const state = store.getState();
  const filter = `conversationID=${conversationID}`;
  const returnFields = [
    'conversationStartDate',
  ].join(',');
  const url: string = `${URL.API.V3.METRIC_REALTIME}?`
    + `returnFields=${returnFields
    }&site=${getCurrentSiteId(state)
    }${buildBuAndAgParams(state)
    }&category=${Category.CONVERSATION
    }&dimension=${Dimension.CONVERSATIONS
    }&filter=${filter}`;
  return authorizedRTMGet(url, null) as Promise<ConversationStartDateResponse>;
}

export function loadFollowUpConversation(
  startDate: string,
  conversationID: string,
): Promise<ConversationResponse> {
  const state = store.getState();
  const filter = `conversationID=${conversationID}&conversationStartDate=${startDate}`;
  const url = `${URL.API.V3.CONVERSATION_TRANSCRIPT}?site=${getCurrentSiteId(state)}`
    + `&filter=${filter}`
    + '&output=json';
  return authorizedRTMGet(url, null, false) as Promise<ConversationResponse>;
}

export function loadAgentGroups() {
  const returnFields = [
    'agentID',
    'agentGroups',
    'totalNumberOfAGs',
  ].join(',');
  const state = store.getState();
  const url: string = `${`${URL.API.V3.METRIC_REALTIME}?`
    + `returnFields=${returnFields}`
    + `&site=${getCurrentSiteId(state)}`
    + `${buildBuAndAgParams(state)}`
    + `&category=${Category.AGENT}`
    + `&dimension=${Dimension.AGENTS}`
    + '&output=json'
  } `;
  return loadTableData(url);
}

export function loadAllAgentGroups(agentID) {
  const returnFields = [
    'agentGroups',
  ].join(',');
  const state = store.getState();
  const url: string = `${`${URL.API.V3.METRIC_REALTIME}?`
    + `returnFields=${returnFields}`
    + `&site=${getCurrentSiteId(state)}`
    + `${buildBuAndAgParams(state)}`
    + `&category=${Category.AGENT}`
    + `&dimension=${Dimension.AGENTS}`
    + '&output=json'
    + `&agentID=${agentID}`
    + '&allAgentGroups=true'
  } `;
  return loadTableData(url);
}
