import { TokenPayload, TokenPayloadResponse } from '@/interfaces/TokenPayload';

export default (
  tokenResponse: TokenPayloadResponse,
): TokenPayload => ({
  accessToken: tokenResponse.access_token,
  tokenType: tokenResponse.token_type,
  refreshToken: tokenResponse.refresh_token,
  expiresIn: tokenResponse.expires_in,
  scope: tokenResponse.scope,
  sites: tokenResponse.sites,
  jti: tokenResponse.jti,
  tokenUpdateTime: Date.now(),
});
