import { UiLocale, ServerLocale } from '@/interfaces/Language';

export function toUiLocale(serverLocale): UiLocale {
  const keys = Object.keys(UiLocale);
  let uiLocale;
  keys.forEach((key) => {
    if (ServerLocale[key] === serverLocale) {
      uiLocale = UiLocale[key];
    }
  });
  return uiLocale || UiLocale.US;
}

export function toServerLocale(uiLocale): ServerLocale {
  const keys = Object.keys(ServerLocale);
  let serverLocale;
  keys.forEach((key) => {
    if (UiLocale[key] === uiLocale) {
      serverLocale = ServerLocale[key];
    }
  });
  return serverLocale || ServerLocale.US;
}
