export const ELEMENT_ID = {
  ACTIONS: {
    DELETE_FILTER: 'delete-filter',
    LOG_AGENT_OUT: 'log-agent-out',
    MENU: 'menu',
    MENU_CONTENT: 'menu-content',
    SAVE_FILTER: 'save-filter',
    UPDATE_FILTER: 'update-filter',
  },
  ALERT_EDITOR: {
    CONTAINER: {
      NUMBER: 'alert-editor-number',
      TIME: 'alert-editor-time',
    },
    RED_VALUE: 'alert-editor-red-value',
    RED_VALUE_HOUR: 'alert-editor-red-value-hour',
    RED_VALUE_MIN: 'alert-editor-red-value-min',
    RED_VALUE_SEC: 'alert-editor-red-value-sec',
    YELLOW_VALUE: 'alert-editor-yellow-value',
    YELLOW_VALUE_HOUR: 'alert-editor-yellow-value-hour',
    YELLOW_VALUE_MIN: 'alert-editor-yellow-value-min',
    YELLOW_VALUE_SEC: 'alert-editor-yellow-value-sec',
  },
  BUTTON: {
    LOGIN: 'button-login',
    SAVE: 'button-save',
    SAVE_VIEW: 'button-save-view',
    SAVE_EDITED_VIEW: 'button-save-edited-view',
    UPDATE: 'button-update',
    SEND_MESSAGE_ENGAGEMENT: 'engagement-button',
    SEND_MESSAGE_AGENT: 'agent-button',
  },
  CATEGORY: {
    CONTAINER: 'tabs',
    ID: {
      SUMMARY: 'summary',
      QUEUE: 'queue',
      ENGAGEMENT: 'engagement',
      AGENT: 'agent',
      CONVERSATION: 'conversation',
    },
  },
  CHART_TITLE: 'chart-title',
  QUEUE_CHART: 'queue-chart',
  CONVERSATION_TRANSCRIPT: 'conversation-transcript',
  DELETE_FILTER_INFO: 'delete-filter-info',
  DELETE_VIEW_INFO: 'delete-view-info',
  PRESENTER_INFO: 'presenter-data-info',
  DIALOG: {
    ACCESS_DENIED: 'access-denied-dialog',
    ADD_VIEWS: 'add-views-dialog',
    ALERT_EDITOR: 'alert-editor-dialog',
    COLUMN_EXISTS_WARNING: 'column-exists-warning',
    CONNECTION: 'connection-dialog',
    DELETE_FILTER: 'delete-filter-dialog',
    DELETE_VIEW: 'delete-view-dialog',
    EDIT_VIEWS: 'edit-views-dialog',
    LOGOUT_AGENT_CONFIRMATION: 'logout-agent-confirmation',
    LOGOUT_AGENT_WARNING: 'logout-agent-warning',
    MANAGE_COLUMNS: 'manage-columns-dialog',
    MODIFY_FILTER: 'modify-filter-dialog',
    PRESENTER: 'presenter-data-dialog',
    REMOVE_COLUMN: 'remove-column-dialog',
    SAVE_FILTER: 'save-filter-dialog',
    UNSAVED_FILTER_INFO: 'unsaved-filter-info',
    UPDATE_FILTER: 'update-filter-dialog',
  },
  CUSTOM_FILTER: 'custom-filter',
  FILTER_NAME: 'filter-name',
  FILTER_PANEL: 'filter-panel',
  ICON: {
    MANAGE_COLUMNS: 'manage-columns-icon',
  },
  MANAGE_COLUMNS: {
    ADD_ATTRIBUTE_COLUMN_BUTTON: 'add-attribute-column-button',
    AVAILABLE_COLUMNS: 'available-columns',
    COLUMN_EXISTS_MESSAGE: 'column-exists-message',
    COLUMNS_CONTAINER: 'manage-columns-container',
    CURRENT_COLUMNS: 'current-columns',
    SELECT_ATTR_TYPE: 'manage-columns-select-attr-type',
    SELECT_ATTR_NAME: 'manage-columns-select-attr-name',
    UNDELETABLE_COLUMNS: 'undeletable-columns',
  },
  AGENT_GROUPS_MULTI_SELECT: 'agent-groups-multi-select',
  AGENT_GROUPS_MULTI_SELECT_DROPDOWN: 'agent-groups-multi-select-dropdown',
  AGENT_GROUPS_SINGLE_SELECT_DROPDOWN: 'agent-groups-single-select-dropdown',
  AGENT_GROUPS_SINGLE_SELECT_DROPDOWN_BUTTON: 'agent-groups-single-select-dropdown-button',
  AGENT_GROUPS_SINGLE_SELECT_DROPDOWN_MENU: 'agent-groups-single-select-dropdown-menu',
  BUSINESS_UNITS_MULTI_SELECT: 'business-units-multi-select',
  BUSINESS_UNITS_MULTI_SELECT_DROPDOWN: 'business-units-multi-select-dropdown',
  BUSINESS_UNITS_SINGLE_SELECT_DROPDOWN: 'business-units-single-select-dropdown',
  BUSINESS_UNITS_SINGLE_SELECT_DROPDOWN_BUTTON: 'business-units-single-select-dropdown-button',
  BUSINESS_UNITS_SINGLE_SELECT_DROPDOWN_MENU: 'business-units-single-select-dropdown-menu',
  ENGAGEMENT_INPUT: 'engagement-input',
  AGENT_INPUT: 'agent-input',
  FILTER_VALUE_TABLE: 'filter-values-table',
  MODIFY_OPERATORS: 'modify-operators',
  SELECTED_BUSINESS_UNITS: 'selected-business-units',
  SELECTED_AGENT_GROUPS: 'selected-agent-groups',
  LANGUAGE: 'language',
  LOADER_CONTAINER: 'loader-container',
  LOGIN_PAGE: 'login-page',
  LOGOUT_AGENT_WARNING_TEXT: 'logout-agent-warning-text',
  LOGOUT_AGENT_CONFIRMATION_TEXT: 'logout-agent-confirmation-text',
  MANAGE_VIEW: {
    MANAGE_VIEWS: 'manage-views',
    MANAGE_VIEWS_MENU_CONTENT: 'manage-views-menu-content',
    ADD_MANAGE_VIEW: 'add-manage-view',
    CONFIGURATION_MODE: 'configuration-mode',
    DELETE_MANAGE_VIEW: 'delete-manage-view',
    EDIT_MANAGE_VIEW: 'edit-manage-view',
    RESET: 'reset-manage-view',
    ADD_VIEW_DIALOG_VIEW_NAME: 'add-view-dialog-view-name',
    ADD_VIEW_DIALOG_TAB: 'add-view-dialog-tab',
    ADD_VIEW_DIALOG_ATTRIBUTE_NAME: 'add-view-dialog-attribute-name',
    ADD_VIEW_DIALOG_ATTRIBUTE_TYPE: 'add-view-dialog-attribute-type',
    EDIT_VIEW_DIALOG_VIEW_NAME: 'edit-view-dialog-view-name',
    EDIT_VIEW_DIALOG_TAB: 'edit-view-dialog-tab',
    EDIT_VIEW_DIALOG_ATTRIBUTE_NAME: 'edit-view-dialog-attribute-name',
    EDIT_VIEW_DIALOG_ATTRIBUTE_TYPE: 'edit-view-dialog-attribute-type',
  },
  MAIN_CONTAINER: 'main-container',
  ROTATION_MODE: 'rotation-mode',
  ROTATION_ENABLED: 'rotateEnabled',
  ROTATION_INTERVAL: 'rotationInterval',
  UPDATE_FILTER_NAME: 'update-filter-name',
  VALIDATION_FORM: 'validation-form',
  VIEW_TABLE_MESSAGE: 'view-table-message',
  VIEW_TABLE_WRAPPER: 'view-table-wrapper',
};

export const DEFAULT_TAB_INDEX = {
  QUEUE_REQUESTS: 0,
  QUEUE_AGENT_SERVICEABILITY: 1,
  QUEUE_BUSINESS_RULES: 2,
  QUEUE_PAGES: 3,
  ENGAGEMENT_CHATS: 0,
  ENGAGEMENT_AGENTS: 1,
  ENGAGEMENT_BUSINESS_RULES: 2,
  ENGAGEMENT_PAGES: 3,
  ENGAGEMENT_ESCALATED_CHATS: 4,
  AGENT_STATUSES: 0,
  FOLLOW_UP: 0,
};
