import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { selectCurrentViewName } from '@/selectors/currentViewSelector';
import { ServerLocale, Language } from '@/interfaces/Language';
import { parseAndLogError } from '@/services/loggerService';
import { Type } from '@/actions/sync/userActions';
import { CustomScript } from '@/interfaces/CustomScript';
import { loadCustomScript, postCustomScript } from '@/api/supervisorEngagementClientAPI';
import { CustomScriptActionType, CustomScriptActions } from '@/actions/sync/customScriptActions';
import { MessageActions } from '@/actions/sync/messageActions';
import Severity from '@/enums/Severity';
import { store } from '@/stores/store';
import { ActiveFilterActions } from '@/actions/sync/activeFilterActions';
import { selectAllFilters } from '@/selectors/customScriptSelector';
import { WebStorageActions } from '@/actions/sync/webStorageActions';
import { CURRENT_LOCALE } from '@/constants/WebStorageKey';

function* fetchCustomScript() {
  try {
    const script: CustomScript | void = yield call(loadCustomScript);
    if (script) {
      const {
        language,
        lastUpdateDate,
        locale,
        filter,
      } = script as CustomScript;
      yield put(CustomScriptActions.updateLanguage({
        language,
        lastUpdateDate,
        locale,
      }));
      yield put(WebStorageActions.updateWebStorage({
        key: CURRENT_LOCALE,
        value: locale,
      }));
      if (filter) {
        yield put(CustomScriptActions.updateFilters(filter));
      }
    } else {
      yield put(WebStorageActions.updateWebStorage({
        key: CURRENT_LOCALE,
        value: ServerLocale.US,
      }));
      yield put(CustomScriptActions.updateLanguage({
        language: Language.ENGLISH,
        lastUpdateDate: 0,
        locale: ServerLocale.US,
      }));
    }
  } catch (error) {
    parseAndLogError(error);
  }
}

function* deleteFilter({
  payload: { name, tableId },
}: ReturnType<typeof CustomScriptActions.requestDeleteFilter>) {
  try {
    const { filter }: CustomScript = store.getState().customScript;
    const updatedFilters = filter!.filter((item) => name !== item.name || tableId !== item.tableId);
    yield put(CustomScriptActions.requestInProgress(true));
    yield call(postCustomScript, updatedFilters);
    yield put(CustomScriptActions.deleteFilter(updatedFilters));
    const currentViewName = yield select(selectCurrentViewName);
    yield put(ActiveFilterActions.updateViewActiveFilters({
      activeFilterValue: null,
      viewName: currentViewName,
      filterName: name,
      tableId,
    }));
  } catch (error: any) {
    yield put(MessageActions.addMessageDetails({
      message: error.toString(),
      severity: Severity.ERROR,
    }));
  } finally {
    yield put(CustomScriptActions.requestInProgress(false));
  }
}

function* saveFilterData({
  payload: filterData,
}: ReturnType<typeof CustomScriptActions.addFilterRequest>) {
  try {
    const filters = yield select(selectAllFilters);
    yield call(postCustomScript, [...filters, filterData]);
    yield put(CustomScriptActions.addReduxFilter(filterData));
  } catch (error: any) {
    yield put(MessageActions.addMessageDetails({
      message: error.toString(),
      severity: Severity.ERROR,
    }));
  }
}

function* editFilterData({
  payload: filterData,
  meta: oldName,
}: ReturnType<typeof CustomScriptActions.requestEditFilter>) {
  try {
    const filters = yield select(selectAllFilters);
    const newFilters = [...filters];
    const index = newFilters.findIndex((filter) => (filter.name === oldName
      && filterData.tableId === filter.tableId));
    newFilters[index] = filterData;
    yield call(postCustomScript, newFilters);
    yield put(CustomScriptActions.editFilter(newFilters));
    const currentViewName = yield select(selectCurrentViewName);
    yield put(ActiveFilterActions.updateViewActiveFilters({
      activeFilterValue: filterData,
      viewName: currentViewName,
      filterName: oldName,
      tableId: filterData.tableId as string,
    }));
  } catch (error: any) {
    yield put(MessageActions.addMessageDetails({
      message: error.toString(),
      severity: Severity.ERROR,
    }));
  }
}

export default function* customScriptSaga() {
  yield takeEvery(Type.UPDATE_USER, fetchCustomScript);
  yield takeEvery(CustomScriptActionType.REQUEST_DELETE_FILTER, deleteFilter);
  yield takeEvery(CustomScriptActionType.REQUEST_ADD_FILTER, saveFilterData);
  yield takeEvery(CustomScriptActionType.REQUEST_EDIT_FILTER, editFilterData);
}
