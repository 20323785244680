import { createSelector } from 'reselect';
import {
  AgentSummary,
  EngagementSummary,
  QueueSummary,
  SummaryMetricFields,
} from '@/enums/Summary';
import { StoreState } from '@/stores/store';
import {
  formatNumberToString,
  formatNumberToPercent,
  formatNumberToDuration,
} from '@/utils/formatSummaryItemValue';

const isKpiProcessRequests = (state: StoreState): boolean => (
  state.summary.isKpiOpen
);

const isSummaryProcessRequests = (state: StoreState): boolean => (
  state.summary.isSummaryOpen
);

export const getSummaryProcessing = createSelector(
  [isKpiProcessRequests, isSummaryProcessRequests],
  (isKpiOpen: boolean, isSummaryOpen: boolean): boolean => (
    isKpiOpen || isSummaryOpen
  ),
);

function hasAgentMetrics(field: SummaryMetricFields): field is AgentSummary {
  return Object.keys(AgentSummary).includes(field);
}

function hasEngagementMetrics(field: SummaryMetricFields): field is EngagementSummary {
  return Object.keys(EngagementSummary).includes(field);
}

const selectSummaryMetric = (field: SummaryMetricFields) => ((state: StoreState): number | null => {
  if (hasAgentMetrics(field)) {
    return state.summary.agentSummary[field];
  }
  if (hasEngagementMetrics(field)) {
    return state.summary.engagementSummary[field];
  }
  return state.summary.queueSummary[field];
});

const getSummaryMetricValue = (field: SummaryMetricFields, fn) => createSelector(
  selectSummaryMetric(field),
  (value: number | null): string => fn(value),
);

const getMetricAsString = (field: SummaryMetricFields) => (
  getSummaryMetricValue(field, formatNumberToString)
);

const getMetricAsPercent = (field: SummaryMetricFields) => (
  getSummaryMetricValue(field, formatNumberToPercent)
);

const getMetricAsDuration = (field: SummaryMetricFields) => (
  getSummaryMetricValue(field, formatNumberToDuration)
);

export const getActiveAgents = getMetricAsString(AgentSummary.activeAgents);
export const getAgentUtilization = getMetricAsPercent(AgentSummary.agentUtilization);
export const getAgentsLoggedIn = getMetricAsString(AgentSummary.agentsLoggedIn);
export const getAgentsOnBusy = getMetricAsString(AgentSummary.agentsOnBusy);
export const getAgentsOnBusyInChat = getMetricAsString(AgentSummary.agentsOnBusyInChat);
export const getAvailableEngagementSlots = getMetricAsString(AgentSummary.availableEngagementSlots);
export const getEngagementUtilization = getMetricAsPercent(AgentSummary.engagementUtilization);

export const getActiveEngagements = getMetricAsString(EngagementSummary.activeEngagements);
export const getAvgAgentResponseTime = getMetricAsDuration(EngagementSummary.avgAgentResponseTime);
export const getAvgEngagementDuration = getMetricAsDuration(
  EngagementSummary.avgEngagementDuration,
);
export const getAvgInitialAgentResponseTime = getMetricAsDuration(
  EngagementSummary.avgInitialAgentResponseTime,
);
export const getEngagementsExceedingHandleTimeGoal = getMetricAsString(
  EngagementSummary.engagementsExceedingHandleTimeGoal,
);
export const getEngagementsInLastHour = getMetricAsString(EngagementSummary.engagementsInLastHour);
export const getEngagementsWithinHandleTimeGoal = getMetricAsString(
  EngagementSummary.engagementsWithinHandleTimeGoal,
);
export const getEscalatedChats = getMetricAsString(EngagementSummary.escalatedChats);
export const getMaxEngagementDuration = getMetricAsDuration(
  EngagementSummary.maxEngagementDuration,
);
export const getPercentSaleQualified = getMetricAsPercent(EngagementSummary.percentSaleQualified);
export const getSaleQualifiedEngagements = getMetricAsString(
  EngagementSummary.saleQualifiedEngagements,
);

export const getEngagementsInQueue = getMetricAsString(QueueSummary.engagementsInQueue);
export const getPercentWithinQueueSLA = getMetricAsPercent(QueueSummary.percentWithinQueueSLA);
export const getQueuedEngagementsExceedingSLA = getMetricAsString(
  QueueSummary.queuedEngagementsExceedingSLA,
);
export const getQueuedEngagementsWithinSLA = getMetricAsString(
  QueueSummary.queuedEngagementsWithinSLA,
);
export const getTrendingPercentOfAbandons = getMetricAsPercent(
  QueueSummary.trendingPercentOfAbandons,
);
export const getTrendingPercentWithinQueueSLA = getMetricAsPercent(
  QueueSummary.trendingPercentWithinQueueSLA,
);
export const getMaxTimeInQueue = getMetricAsDuration(QueueSummary.maxTimeInQueue);
export const getAvgTimeInQueue = getMetricAsDuration(QueueSummary.avgTimeInQueue);
export const getTrendingAvgTimeInQueue = getMetricAsDuration(QueueSummary.trendingAvgTimeInQueue);
