import React, {
  Suspense, useEffect, useState,
} from 'react';
import {
  Redirect, Route, Router, Switch,
} from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { StylesProvider } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';
import {
  CHANGE_PASSWORD_PAGE, EXTERNAL_PAGE, INDEX, LOADING, LOGIN,
} from '@/constants/localUrl';
import 'assets/css/app.css';
import LoaderPage from '@/components/pages/LoaderPage';
import history from '@/services/history';
import { getFromWebStorage } from '@/services/webStorageService';
import { TOKEN_INFO, MESSAGES } from '@/constants/WebStorageKey';
import loadMessagesJson from '@/locales/loadMessagesJson';
import useGlobalErrorHandler from '@/hooks/useGlobalErrorHandler';
import useEnvironmentVerifier from '@/hooks/useEnvironmentVerifier';
import useNotifyUserInactivity from '@/hooks/useNotifyUserInactivity';
import { getUiLocale } from '@/selectors/customScriptSelector';
import ErrorBoundary from '@/ErrorBoundary';
import { ApplicationSettingsActions } from '@/actions/sync/applicationSettingsActions';
import isSsoProcessing, { isSsoUser } from '@/utils/isSsoProcessing';
import { getIdpPage } from '@/selectors/oauth2Selector';
import theme from './theme';
import { handleReactIntlError } from '@/utils/getStringByLocaleKey';
import { WebStorageActions } from '@/actions/sync/webStorageActions';
import formatStringAsJson from '@/utils/formatStringAsJson';

const LoginPage = React.lazy(() => import('@/components/pages/LoginPage'));
const SupervisorDesktopPage = React.lazy(() => import('@/components/pages/SupervisorDesktopPage'));
const ChangePasswordPage = React.lazy(() => import('@/components/pages/ChangePasswordPage'));

const LOW_RESOLUTION_BREAK_POINT = 1560;
const cache = createCache({
  key: 'cache',
  nonce: process.env.NONCE,
  prepend: true,
});

const App: React.FC = () => {
  const uiLocale = useSelector(getUiLocale);
  const [localeMessages, setLocaleMessages] = useState({});
  const isAuthenticated = !!getFromWebStorage(TOKEN_INFO, true);
  const isSso = isSsoProcessing();
  const dispatch = useDispatch();
  const isSsoUserPresent: boolean = isSsoUser();

  useEffect(() => {
    dispatch(ApplicationSettingsActions.initApplication());
    const islowResolution: boolean = window.innerWidth <= LOW_RESOLUTION_BREAK_POINT;
    dispatch(ApplicationSettingsActions.updateLowResolution(islowResolution));
  }, [dispatch]);

  useEffect(() => {
    loadMessagesJson(uiLocale).then((msgs) => {
      dispatch(WebStorageActions.updateWebStorage({
        key: MESSAGES,
        value: msgs,
      }));
      const localeMsgs = formatStringAsJson(getFromWebStorage(MESSAGES)) || msgs;
      setLocaleMessages(localeMsgs);
      document.documentElement.setAttribute('lang', uiLocale);
    });
  }, [uiLocale, dispatch]);

  useGlobalErrorHandler();
  useEnvironmentVerifier();
  useNotifyUserInactivity();

  return (
    <CacheProvider value={cache}>
      <StylesProvider injectFirst>
        <ErrorBoundary>
          <ThemeProvider theme={theme}>
            <Router
              history={history}
            >
              {!isSso && !isAuthenticated && (
                <Redirect
                  to={isSsoUserPresent ? `${LOGIN}?sso_user=${isSsoUserPresent}` : LOGIN}
                />
              )}
              <IntlProvider
                locale={uiLocale}
                messages={{ ...localeMessages }}
                onError={handleReactIntlError}
              >
                <Suspense
                  fallback={(<LoaderPage />)}
                >
                  <Switch>
                    <Route
                      path={EXTERNAL_PAGE}
                      component={() => {
                        window.location.href = getIdpPage()!;
                        return null;
                      }}
                    />
                    <Route
                      path={LOGIN}
                      component={LoginPage}
                    />
                    <Route
                      path={`${CHANGE_PASSWORD_PAGE}/:username`}
                      component={ChangePasswordPage}
                    />
                    <Route
                      component={LoaderPage}
                      path={LOADING}
                    />
                    <Route
                      component={SupervisorDesktopPage}
                      path={INDEX}
                    />
                  </Switch>
                </Suspense>
              </IntlProvider>
            </Router>
          </ThemeProvider>
        </ErrorBoundary>
      </StylesProvider>
    </CacheProvider>
  );
};

export default App;
