enum ColumnData {
  ACTIONS = 'actions',
  ACTIVE_ENGAGEMENTS = 'activeEngagements',
  AGENTS = 'agents',
  AGENT_FULL_NAME = 'agentFullName',
  AGENT_FULL_NAMES = 'agentFullNames',
  AGENT_GROUP_NAME = 'agentGroupName',
  AGENT_GROUPS = 'agentGroups',
  AGENT_ID = 'agentID',
  AGENT_NOTES = 'agentNotes',
  AGENT_STATUS = 'agentStatus',
  AGENT_TIME_IN_STATUS = 'agentTimeInStatus',
  ASYNC_ENGAGEMENT = 'asyncEngagement',
  ATTRIBUTES = 'attributes',
  AVG_AGENT_RESPONSE_TIME = 'avgAgentResponseTime',
  AVG_ENGAGEMENT_DURATION = 'avgEngagementDuration',
  AVG_INITIAL_AGENT_RESPONSE_TIME = 'avgInitialAgentResponseTime',
  AVG_MESSAGE_INTERVAL = 'avgMessageInterval',
  AVG_TIME_IN_QUEUE = 'avgTimeInQueue',
  BUSINESS_UNITS = 'businessUnits',
  BUSINESS_UNIT_NAME = 'businessUnitName',
  BUSINESS_RULE_NAME = 'businessRuleName',
  BUSINESS_RULE_ID = 'businessRuleID',
  BUSY_CLICK_COUNT = 'busyClickCount',
  CAN_SERVICE_COUNT = 'canServiceCount',
  CLOSED_ENGAGEMENTS = 'closedEngagements',
  CONVERSATION_ID = 'conversationID',
  CURRENT_PAGES = 'currentPages',
  CURRENT_PAGE_MARKER = 'currentPageMarker',
  CUSTOM_AGENT_ATTRIBUTE = 'agentAttribute',
  CUSTOM_BUSINESS_RULE_ATTRIBUTE = 'businessRuleAttribute',
  CUSTOM_BUSINESS_RULE_ATTRIBUTE_DIVISION = 'businessRuleAttribute.Division',
  CUSTOM_OWNER_ATTRIBUTE = 'ownerAttribute',
  CUSTOM_REQUESTED_ATTRIBUTE = 'routingAttribute',
  CUSTOM_VISITOR_ATTRIBUTE = 'visitorAttribute',
  CUSTOM_VISITOR_ATTRIBUTE_CUSTOMER_VALUe = 'visitorAttribute.Customer Value',
  DEFAULT_DATA = 'defaultData',
  ENGAGEMENTS_IN_QUEUE = 'engagementsInQueue',
  ENGAGEMENT_ACTIVE = 'engagementActive',
  ENGAGEMENT_AVG_AGENT_RESPONSE_TIME = 'engagementAvgAgentResponseTime',
  ENGAGEMENT_DURATION = 'engagementDuration',
  ENGAGEMENT_ID = 'engagementID',
  ENGAGEMENT_MAX_AGENT_RESPONSE_TIME = 'engagementMaxAgentResponseTime',
  ENGAGEMENT_STATUS = 'engagementStatus',
  ENGAGEMENT_TIME_IN_STATUS = 'engagementTimeInStatus',
  ESCALATION_COMMENT = 'escalationComment',
  ESCALATION_REASONS = 'escalationReasons',
  FINAL_OWNING_AGENT_ID = 'finalOwningAgentID',
  FINAL_OWNING_AGENT_FULL_NAME = 'finalOwningAgentFullName',
  IN_CONFERENCE = 'inConference',
  LAUNCH_PAGE_MARKER = 'launchPageMarker',
  LAUNCH_PAGE_ID = 'launchPageID',
  LOGIN_DURATION = 'loginDuration',
  LOGIN_TIME = 'loginTime',
  MAX_AGENT_RESPONSE_TIME = 'maxAgentResponseTime',
  MAX_ENGAGEMENTS = 'maxEngagements',
  MAX_TIME_IN_QUEUE = 'maxTimeInQueue',
  OWNING_BUSINESS_UNIT = 'owningBusinessUnit',
  OWNING_AGENT_GROUP = 'owningAgentGroup',
  OWNING_AGENT_GROUP_NAME = 'owningAgentGroupName',
  PAGES = 'pages',
  PERCENT_ENGAGEMENTS_QUEUED = 'percentEngagementsQueued',
  PERCENT_QUEUE_SLA = 'percentWithinQueueSLA',
  PERSISTENT = 'persistent',
  POSITION = 'position',
  PRIORITY = 'priority',
  QUEUE_ENGAGEMENT_ID = 'queueEngagementID',
  SCORE = 'score',
  SLA = 'sla',
  TIME_IN_QUEUE = 'timeInQueue',
  TIME_SINCE_CUSTOMER_MESSAGE = 'timeSinceCustomerMessage',
  TIME_SINCE_LAST_ENGAGEMENT = 'timeSinceLastEngagement',
  TIME_TO_EXPIRE = 'timeToExpire',
  TOTAL_AVAILABLE_TIME = 'totalAvailableTime',
  TOTAL_BUSY_TIME = 'totalBusyTime',
  TOTAL_CONFERENCE_CHAT_COUNT = 'totalConferenceChatCount',
  TOTAL_NUMBER_OF_AGENT_GROUPS = 'totalNumberOfAGs',
  TOTAL_PERSISTENT_CHAT_COUNT = 'totalPersistentChatCount',
  TOTAL_REQUIRES_ATTENTION_CHAT_COUNT = 'totalRequiresAttentionChatCount',
  TOTAL_TRANSFERRED_CHAT_COUNT = 'totalTransferredChatCount',
  TRANSFERRED = 'transferred',
  TRANSFERRED_COUNT = 'transferredCount',
  TRANSCRIPT = 'transcript',
  VALUE = 'value',
}

export default ColumnData;
