import { Reducer } from 'redux';
import appReducer from '@/reducers/appReducer';
import * as actionTypes from '@/interfaces/actionTypes';

const rootReducer: Reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.USER_LOGGED_OUT:
      return appReducer(undefined, action);
    default:
      return appReducer(state, action);
  }
};

export default rootReducer;
