import {
  all, takeEvery, call, put, select, delay,
} from 'redux-saga/effects';
import { parseAndLogError } from '@/services/loggerService';
import { Type, AgentActions } from '@/actions/sync/agentActions';
import {
  changePassword as changePasswordAPI,
  joinChat,
  verifySession as verifySessionAPI,
} from '@/api/agentEngagementAPI';
import { MessageActions } from '@/actions/sync/messageActions';
import { WebStorageActions } from '@/actions/sync/webStorageActions';
import getStringByLocaleKey from '@/utils/getStringByLocaleKey';
import prepareStringFromTokens from '@/utils/prepareStringFromTokens';
import ERROR from '@/enums/Error';
import Severity from '@/enums/Severity';
import { REGISTERED_ID } from '@/constants/WebStorageKey';
import { Type as UserTypes, RegisteredIdActions } from '@/actions/sync/userActions';
import selectRegisteredId from '@/selectors/registeredIdSelector';
import { getConnectedChatsIds } from '@/selectors/chatManagerSelector';
import localeKeys from '@/constants/localeKeys';
import history from '@/services/history';
import { LOGIN } from '@/constants/localUrl';
import { logoutAgent, loginSupervisor } from '@/api/supervisorEngagementClientAPI';
import { getUserName } from '@/api/oAuth2ClientAPI';
import { Oauth2Actions } from '@/actions/sync/oauth2Actions';
import { ChatActions } from '@/actions/sync/chatActions';

function* logoutAgentById({
  payload: { agentID: agentIdToLogout, agentFullName },
}: ReturnType<typeof AgentActions.logoutAgent>) {
  try {
    const response = yield call(logoutAgent, agentIdToLogout);

    if (response && response.status === 'error') {
      const message = prepareStringFromTokens(getStringByLocaleKey(
        response.errorMessage === localeKeys.AGENT_HAS_CHATS_FORCED_LOGOUT_ERROR
          ? response.errorMessage
          : localeKeys.AGENT_HAS_CHATS_FORCED_LOGOUT_ERROR,
      ), [agentFullName]);
      yield put(MessageActions.addMessageDetails({ message, severity: Severity.ERROR }));
    } else {
      const message = prepareStringFromTokens(
        getStringByLocaleKey(localeKeys.AGENT_LOGOUT_SUCCESSFUL),
        [agentFullName],
      );
      yield put(MessageActions.addMessageDetails({ message, severity: Severity.SUCCESS }));
    }
  } catch (error: any) {
    yield put(MessageActions.addMessageDetails({
      message: error.toString(),
      severity: Severity.ERROR,
    }));
  }
}

function* keepConnection() {
  while (true) {
    yield delay(yield select((state) => state.user.verifySessionInterval));
    try {
      const registeredId = yield (select(selectRegisteredId));
      const agentID = yield (select(getUserName));
      if (!!agentID && !!registeredId) {
        yield call(verifySessionAPI);
      }
    } catch (error) {
      parseAndLogError(error);
    }
  }
}

function* register() {
  try {
    const { registeredId } = yield call(loginSupervisor, true);
    const agentID = yield (select(getUserName));
    if (agentID && registeredId) {
      yield put(WebStorageActions.updateWebStorage({
        key: REGISTERED_ID,
        value: registeredId,
      }));
      yield put(RegisteredIdActions.updateRegisteredId(registeredId));

      const chatIds = yield select(getConnectedChatsIds);
      if (chatIds) {
        yield all(chatIds.split(',').map((chatId) => call(joinChat, chatId)));
        // Once supervisor is connected to chatroom dispatch action to fetch messages
        yield put(ChatActions.fetchInternalTranscript());
      }
    }
  } catch (error: any) {
    if (![401, 422].includes(error.status)) {
      parseAndLogError(error);
      // Once supervisor is connected to chatroom dispatch action to fetch messages
      yield put(ChatActions.fetchInternalTranscript());
    } else if (error.status !== 401) {
      yield put(Oauth2Actions.logout());
    }
  }
}

function* reconnect() {
  yield register();
}

function* safeLogin() {
  const registerId = yield select(selectRegisteredId);
  if (registerId) {
    yield call(keepConnection);
  } else {
    yield register();
    yield keepConnection();
  }
}

function* changePassword({
  payload: loginInfo,
}: ReturnType<typeof AgentActions.changePassword>) {
  try {
    yield call(changePasswordAPI, loginInfo);
    yield put(MessageActions.addMessageDetails({
      message: getStringByLocaleKey(localeKeys.PASSWORD_CHANGED),
      severity: Severity.SUCCESS,
    }));
    history.push(LOGIN);
  } catch (error: any) {
    const message = error.response?.data?.detail
      || (ERROR.NETWORK_ERROR === error.message
        && getStringByLocaleKey(localeKeys.APPLICATION_ERROR_OCCURED));
    yield put(MessageActions.addMessageDetails({
      message,
      severity: Severity.ERROR,
    }));
  } finally {
    yield put(Oauth2Actions.setAuthenticationInProgress(false));
  }
}

function* agentSaga() {
  yield takeEvery(UserTypes.UPDATE_USER, safeLogin);
  yield takeEvery(Type.LOGOUT_AGENT, logoutAgentById);
  yield takeEvery(UserTypes.REQUEST_UPDATE_REGISTERED_ID, reconnect);
  yield takeEvery(Type.CHANGE_PASSWORD, changePassword);
}

export default agentSaga;
