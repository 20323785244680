import { createSelector } from 'reselect';
import { TokenPayload } from '@/interfaces/TokenPayload';
import { store, StoreState } from '@/stores/store';
import { parseJwt } from '@/api/oAuth2ClientAPI';

export const getTokenPayload = (): TokenPayload => store.getState().oauth2.tokenPayload;

export const getIdpPage = (): string | null => store.getState().oauth2.idpPage;

export const getPendingTokenRequest = (): Promise<TokenPayload> | null => (
  store.getState().oauth2.pendingTokenRequest
);

export const selectAuthenticationInProgress = (
  state: StoreState,
): boolean => state.oauth2.authenticationInProgress;

export const selectUserName = createSelector(
  getTokenPayload,
  ({ accessToken }): string => parseJwt(accessToken),
);
