import { createAction } from 'redux-actions';
import { SnackBarMessage } from '@/interfaces/SnackbarMessage';

export enum Type {
  ADD_MESSAGE_DETAILS = 'ADD_MESSAGE_DETAILS',
  CLEAR_MESSAGE_DETAILS = 'CLEAR_MESSAGE_DETAILS',
}

const addMessageDetails = createAction<SnackBarMessage>(Type.ADD_MESSAGE_DETAILS);
const clearMessageDetails = createAction(Type.CLEAR_MESSAGE_DETAILS);

export const MessageActions = { addMessageDetails, clearMessageDetails };

export type MessageActionsType = typeof MessageActions;
