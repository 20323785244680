import { createAction } from 'redux-actions';
import { ActiveFilter, ActiveFilterValue } from '@/interfaces/activeFilter';

export enum ActiveFilterActionsEnum {
  UPDATE_VIEW_ACTIVE_FILTERS = 'UPDATE_VIEW_ACTIVE_FILTERS',
  UPDATE_ACTIVE_FILTER = 'UPDATE_ACTIVE_FILTER',
}

const updateViewActiveFilters = createAction<UpdateViewActiveFiltersPayload>(
  ActiveFilterActionsEnum.UPDATE_VIEW_ACTIVE_FILTERS,
);

const updateActiveFilter = createAction<ActiveFilter, string, ActiveFilterValue>(
  ActiveFilterActionsEnum.UPDATE_ACTIVE_FILTER,
  (key: string, activeFilterValue: ActiveFilterValue): ActiveFilter => ({
    [key]: activeFilterValue,
  }),
);

export type UpdateViewActiveFiltersPayload = {
  activeFilterValue: ActiveFilterValue,
  viewName: string,
  filterName: string,
  tableId: string,
};

export type ActiveFiltersPayloads = UpdateViewActiveFiltersPayload & ActiveFilter;

export const ActiveFilterActions = {
  updateViewActiveFilters,
  updateActiveFilter,
};
