import { createAction } from 'redux-actions';
import { TokenPayload } from '@/interfaces/TokenPayload';

enum Type {
  AUTHENTICATE = 'AUTHENTICATE',
  CHECK_AND_AUTHENTICATE_SSO = 'CHECK_AND_AUTHENTICATE_SSO',
  AUTHENTICATED = 'AUTHENTICATED',
  UPDATE_TOKEN = 'UPDATE_TOKEN',
  RESTORE_TOKEN = 'RESTORE_TOKEN',
  SET_AUTHENTICATION_IN_PROGRESS = 'SET_AUTHENTICATION_IN_PROGRESS',
  SET_PENDING_TOKEN_REQUEST = 'SET_PENDING_TOKEN_REQUEST',
  SET_IDP_PAGE = 'SET_IDP_PAGE',
  LOGOUT = 'LOGOUT',
}

const authenticate = createAction<AuthenticatePayload>(Type.AUTHENTICATE);
const checkAndAuthenticateSso = createAction<void>(Type.CHECK_AND_AUTHENTICATE_SSO);
const authenticated = createAction<void>(Type.AUTHENTICATED);
const updateToken = createAction<TokenPayload>(Type.UPDATE_TOKEN);
const restoreToken = createAction<void>(Type.RESTORE_TOKEN);
const setPendingTokenRequest = createAction<Promise<TokenPayload | void> | null>(
  Type.SET_PENDING_TOKEN_REQUEST,
);
const setIdpPage = createAction(Type.SET_IDP_PAGE);
const setAuthenticationInProgress = createAction<boolean>(
  Type.SET_AUTHENTICATION_IN_PROGRESS,
);
const logout = createAction(Type.LOGOUT);

export const Oauth2Actions = {
  authenticate,
  checkAndAuthenticateSso,
  authenticated,
  updateToken,
  setAuthenticationInProgress,
  restoreToken,
  setPendingTokenRequest,
  setIdpPage,
  logout,
};

export type AuthenticatePayload = {
  username: string,
  password: string,
};

export type Oauth2Payloads = AuthenticatePayload &
TokenPayload &
Promise<TokenPayload> &
null &
string &
void;

export default Type;
