enum ViewName {
  SUMMARY = 'summary',
  QUEUE_CHATS = 'chat_requests',
  QUEUE_AGENTS = 'agent_serviceability',
  QUEUE_RULES = 'q_rules',
  QUEUE_PAGES = 'q_pages',
  ENGAGEMENT_CHATS = 'chats',
  ENGAGEMENT_RULES = 'rules',
  ENGAGEMENT_PAGES = 'pages',
  ENGAGEMENT_ESCALATED = 'escalatedchats',
  AGENTS_ACTIVE = 'agents',
  AGENTS_ALL = 'agents_status',
  CONVERSATION = 'follow_up',
  EXPANDED_TABLE = 'expandedTable',
  BOOKMARK_CHATS = 'bookmark_chats',
  BOOKMARK_CONVERSATION = 'bookmark_conversation',
}

export default ViewName;
