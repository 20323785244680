import { createSelector } from 'reselect';
import SelectedUnit from '@/enums/SelectedUnit';
import { AgentGroup } from '@/interfaces/AgentGroup';
import { BusinessUnit } from '@/interfaces/BusinessUnit';
import { StoreState } from '@/stores/store';
import { selectSelectedSite, selectUnit } from './applicationSettingsSelector';

export const selectRotationInterval = (state: StoreState): number => (
  state.rotation.rotationInterval * 1000
);

export const selectRotationSettings = (state: StoreState): string[] => (
  state.rotation.rotationSettings
);

export const selectDisableManualControls = (state: StoreState): boolean => (
  state.rotation.rotationSettings.length === 1
);

export const selectRotationState = (state: StoreState): boolean => (
  state.rotation.isRotationInProgress
);

export const selectRotationPauseState = (state: StoreState): boolean => (
  state.rotation.isRotationPause
);

export const selectManualRotationPauseState = (state: StoreState): boolean => (
  state.rotation.isManualRotationPause
);

export const selectRotationSettingInfo = createSelector(
  [selectUnit, selectSelectedSite, selectRotationSettings],
  (
    selectedUnit,
    { businessUnits, agentGroups },
    rotationSettingIds,
  ): (BusinessUnit | AgentGroup
    )[] => (
    ((selectedUnit === SelectedUnit.BU && businessUnits) || agentGroups)
      .filter(({ id }) => rotationSettingIds.includes(id))
      .sort(({ id: buAgid1 }, { id: buAgid2 }) => (
        rotationSettingIds.indexOf(buAgid1) - rotationSettingIds.indexOf(buAgid2)
      ))
  ),
);
