import ChatStatus from '@/enums/ChatStatus';
import { MessageType, internalMessageTypes } from '@/enums/MessageType';
import { Message, InternalMessage, Chat } from '@/interfaces/Chat';

export const toUserMessage = (message: InternalMessage, status): Message => {
  const {
    member,
    messageText,
    timestamp,
    userId,
  } = message;
  return {
    content: messageText.replace('(internal only) ', ''),
    timestamp: parseInt(timestamp, 10),
    type: member === 'agent'
      ? MessageType.AGENT_INTERNAL_MESSAGE
      : MessageType.UTILITY_MESSAGE,
    senderName: userId,
    read: status,
  };
};

export const toChatData = (rowData): Chat => ({
  engagementActive: rowData.engagementActive,
  finalOwningAgentFullName: rowData.finalOwningAgentFullName,
  engagementID: rowData.engagementID,
  finalOwningAgentID: rowData.finalOwningAgentID,
  engagementDuration: rowData.engagementDuration,
  engagementAvgAgentResponseTime: rowData.engagementAvgAgentResponseTime,
  businessRuleName: rowData.businessRuleName,
  status: ChatStatus.EXPANDED,
  conversationID: rowData.conversationID,
  businessUnits: rowData.businessUnits,
  agentGroups: rowData.agentGroups,
  inputMessage: '',
});

export const toInternalTranscript = (transcript: Message[]): Message[] => (
  transcript
    .filter((message) => (internalMessageTypes.includes(message.type) && (message.senderId !== '_opener_')))
    .map((transcriptObj) => {
      const { content, timestamp, type } = transcriptObj;
      return {
        content: content.replace('(internal only) ', ''),
        senderName: transcriptObj.senderId || transcriptObj.senderName,
        timestamp,
        type,
      };
    })
);

export const updateInternalTranscriptReadStatus = (transcripts: Message[]): Message[] => (
  transcripts.map(({
    content, timestamp, type, senderName,
  }) => ({
    content, timestamp, type, senderName,
  })));
