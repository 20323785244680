import { createSelector, OutputSelector } from 'reselect';
import {
  Chat,
  InternalTranscripts,
  Message,
  Transcripts,
} from '@/interfaces/Chat';
import localeKeys from '@/constants/localeKeys';
import { StoreState } from '@/stores/store';
import ChatStatus from '@/enums/ChatStatus';
import { gethhmmssFromDuration } from '@/utils/timestamp';

export const getChats = (state: StoreState): Chat[] => (
  state.chatManager.chats
);

export const selectFetchInternalTranscriptInProgress = (state: StoreState): boolean => (
  state.chatManager.fetchInternalTranscriptInProgress
);

export const getChatInfo = (engagementID) => createSelector(
  [getChats],
  (chats): Chat => (
    chats.find((chat) => chat.engagementID === engagementID)
    || {}
  ) as Chat,
);

export const areChatsExist = createSelector(
  [getChats],
  (chats): boolean => !!chats.length,
);

export const getTranscripts = (state: StoreState): Transcripts => (
  state.chatManager.transcripts
);

export const getInternalTranscripts = (state: StoreState): InternalTranscripts => (
  state.chatManager.internalTranscripts
);

export const getConnectedChatsIds = createSelector(
  [getChats],
  (chats): string => chats
    .filter((chat) => chat.isInternal)
    .map((chat) => chat.engagementID)
    .join(','),
);

export const getExpandedChat: OutputSelector<
StoreState,
Chat | undefined,
(res: Chat[]) => Chat | undefined> = createSelector(
  [getChats],
  (chats): Chat | undefined => (
    chats.find((chat) => chat.status === ChatStatus.EXPANDED)
  ),
);

export const getExpandedCollapsedChat: OutputSelector<
StoreState,
Chat | undefined,
(res: Chat[]) => Chat | undefined> = createSelector(
  [getChats],
  (chats): Chat | undefined => (
    chats.find((chat) => chat.status === ChatStatus.EXPANDED_COLLAPSED)
  ),
);

export const getExpandedCollapsedChatId = createSelector(
  [getExpandedCollapsedChat],
  (chat): string | undefined => (
    chat && chat.engagementID
  ),
);

export const getDockedChat: OutputSelector<
StoreState,
Chat | undefined,
(res: Chat[]) => Chat | undefined> = createSelector(
  [getChats],
  (chats): Chat | undefined => (
    chats.find((chat) => chat.status === ChatStatus.DOCKED)
  ),
);

export const getDockedChatId = createSelector(
  [getDockedChat],
  (chat): string | undefined => (
    chat && chat.engagementID
  ),
);

export const getDockedAndMinimizedChats: OutputSelector<
StoreState,
Chat[],
(res: Chat[]) => Chat[]> = createSelector(
  [getChats],
  (chats): Chat[] => (
    chats.filter((chat) => (
      chat.status === ChatStatus.DOCKED || chat.status === ChatStatus.MINIMIZED
    ))
  ),
);

export const getStackedChats: OutputSelector<
StoreState,
Chat[],
(res: Chat[]) => Chat[]> = createSelector(
  [getChats],
  (chats): Chat[] => (
    chats.filter((chat) => chat.status === ChatStatus.STACKED)
  ),
);

export const getExpandedChatId = createSelector(
  [getExpandedChat],
  (chat): string => (
    chat ? chat.engagementID : ''
  ),
);

export const chatExpandedStatus = createSelector(
  getExpandedChatId,
  (chatEngagementId) => !!chatEngagementId,
);

export const chatExpandedInternalStatus = createSelector(
  [getExpandedChat],
  (chat): boolean => (chat ? !!chat.isInternal : false),
);

export const getMinimizedPanelChats = createSelector(
  [getChats],
  (chats): Chat[] => (
    chats.filter((chat) => chat.status === ChatStatus.MINIMIZED)
  ),
);

export const getSortedInternalChats = createSelector(
  [getChats],
  (chats): Chat[] => (
    chats.filter((chat) => chat.isInternal === true)
      .sort((a, b) => Number(b.timestamp) - Number(a.timestamp))
  ),
);

export const getChatsIdRequestString = createSelector(
  [getChats],
  (chats): string => chats.map((chat) => `engagementID=${chat.engagementID}`).join(' OR '),
);

export const getEngagementTranscript = (engagementID) => createSelector(
  [getTranscripts],
  (transcripts): Message[] | undefined => (
    engagementID in transcripts ? transcripts[engagementID] || [] : undefined
  ),
);

export const getEngagementInternalTranscript = (engagementID) => createSelector(
  [getInternalTranscripts],
  (internalTranscripts): Message[] => (
    internalTranscripts[engagementID]
  ),
);

export const getDeliveredInternalTranscript = (engagementID) => createSelector(
  [getEngagementInternalTranscript(engagementID)],
  (internalTranscript): Message[] => (
    internalTranscript.filter((transcript) => !transcript.isUndelivered)
  ),
);

export const checkSendingMessages = (engagementID) => createSelector(
  [getEngagementInternalTranscript(engagementID)],
  (internalTranscript): boolean => {
    let areMessagesSending = false;
    if (internalTranscript) {
      internalTranscript.forEach((message) => {
        if (message.isSending) {
          areMessagesSending = true;
        }
      });
    }
    return areMessagesSending;
  },
);

export const getUnreadMessageCount = (engagementID) => createSelector(
  [getEngagementInternalTranscript(engagementID)],
  (internalTranscript): number => {
    let count = 0;
    if (internalTranscript) {
      internalTranscript.forEach((message) => {
        if ('read' in message && !message.read) {
          count += 1;
        }
      });
    }
    return count;
  },
);

export const getTotalUnreadMessageCount = () => createSelector(
  [getInternalTranscripts, getExpandedChatId],
  (internalTranscripts): number => {
    let count = 0;
    Object.values(internalTranscripts).forEach((value) => {
      value.forEach((message) => {
        if ('read' in message && !message.read) {
          count += 1;
        }
      });
    });
    return count;
  },
);

export const getChatHeaderData = createSelector(
  getExpandedChat,
  (chat) => [
    {
      key: 0,
      header:
        localeKeys.CHAT_ID,
      value: chat && chat.engagementID,
    },
    {
      key: 1,
      header: localeKeys.AGENT,
      value: chat && chat.finalOwningAgentID,
    },
    {
      key: 2,
      header: localeKeys.DURATION,
      value: chat && gethhmmssFromDuration(chat.engagementDuration),
    },
    {
      key: 3,
      header: localeKeys.AVG_RESPONSE,
      value: chat && gethhmmssFromDuration(chat.engagementAvgAgentResponseTime),
    },
  ],
);
