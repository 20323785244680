import { combineReducers, Reducer } from 'redux';
import { userReducer } from '@/reducers/userReducer';
import { StoreState } from '@/stores/store';
import { applicationSettingsReducer } from '@/reducers/applicationSettingsReducer';
import summaryReducer from '@/reducers/summaryReducer';
import { oauth2Reducer } from '@/reducers/oauth2Reducer';
import supervisorViewReducer from '@/reducers/supervisorViewReducer';
import bookmarkedReducer from '@/reducers/bookmarkedReducer';
import messageReducer from '@/reducers/messageReducer';
import tableConfigReducer from './tableConfigReducer';
import activeFilterReducer from './activeFilterReducer';
import chatManagerReducer from './chatManagerReducer';
import conversationManagerReducer from './conversationManagerReducer';
import rotationReducer from './rotationReducer';
import registeredIdReducer from './registeredIdReducer';
import currentViewReducer from './currentViewReducer';
import customScriptReducer from './customScriptReducer';
import tableDataReducer from './tableDataReducer';
import navigationReducer from './navigationReducer';
import onlineStatusReducer from './onlineStatusReducer';
import openActionItemReducer from './openActionItemReducer';

// @ts-ignore
const appReducer: Reducer = combineReducers<StoreState>({
  activeFilter: activeFilterReducer,
  applicationSettings: applicationSettingsReducer,
  bookmarked: bookmarkedReducer,
  chatManager: chatManagerReducer,
  conversationManager: conversationManagerReducer,
  currentView: currentViewReducer,
  customScript: customScriptReducer,
  oauth2: oauth2Reducer,
  rotation: rotationReducer,
  summary: summaryReducer,
  supervisorViews: supervisorViewReducer,
  tableConfig: tableConfigReducer,
  user: userReducer,
  messageInfo: messageReducer,
  // @ts-ignore
  registeredId: registeredIdReducer,
  tableData: tableDataReducer,
  navigation: navigationReducer,
  onlineStatus: onlineStatusReducer,
  openActionItem: openActionItemReducer,
});

export default appReducer;
