import { handleActions, Action } from 'redux-actions';
import {
  ActiveFilterActionsEnum,
  UpdateViewActiveFiltersPayload,
  ActiveFiltersPayloads,
} from '@/actions/sync/activeFilterActions';
import { ActiveFilter, ActiveFilterValue } from '@/interfaces/activeFilter';

/**
 * Default ActiveFilter initial value for Redux.
 */
export const DEFAULT_ACTIVE_FILTER: ActiveFilter = {
};

const processUpdateViewActiveFiltersAction = (
  state: ActiveFilter,
  { payload: { activeFilterValue, filterName, tableId } }: Action<UpdateViewActiveFiltersPayload>,
): ActiveFilter => {
  const updatedActiveFilters = { ...state };

  Object.entries<ActiveFilterValue>(updatedActiveFilters).forEach(([key, value]) => {
    if (value && value.tableId === tableId && filterName === value.name) {
      updatedActiveFilters[key] = activeFilterValue;
    }
  });
  return updatedActiveFilters;
};

const processUpdateActiveFilterAction = (
  state: ActiveFilter,
  action: Action<ActiveFilter>,
): ActiveFilter => (
  {
    ...state,
    ...action.payload,
  }
);

const activeFilterReducer = handleActions<ActiveFilter, ActiveFiltersPayloads>(
  {
    [ActiveFilterActionsEnum.UPDATE_VIEW_ACTIVE_FILTERS]: processUpdateViewActiveFiltersAction,
    [ActiveFilterActionsEnum.UPDATE_ACTIVE_FILTER]: processUpdateActiveFilterAction,
  },
  DEFAULT_ACTIVE_FILTER,
);

export default activeFilterReducer;
