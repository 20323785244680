export const INDEX = '/';
export const LOADING = '/loading';
export const LOGIN = '/login';
export const CHANGE_PASSWORD_PAGE = '/change-password-page';
export const SUMMARY = '/summary';
export const QUEUE = '/queue';
export const ACTIVE_CHATS = '/active';
export const AGENTS = '/agents';
export const UNRESOLVED_CHATS = '/follow-up';
export const BOOKMARKED_CHATS = '/bookmarks';
export const EXTERNAL_PAGE = '/external-page';
