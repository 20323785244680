import { createAction } from 'redux-actions';
import { CurrentView } from '@/interfaces/currentView';

enum Type {
  REQUEST_UPDATE_CURRENT_VIEW = 'REQUEST_UPDATE_CURRENT_VIEW',
  UPDATE_CURRENT_VIEW = 'UPDATE_CURRENT_VIEW',
}

const updateCurrentView = createAction<CurrentView>(
  Type.UPDATE_CURRENT_VIEW,
);

const requestUpdateCurrentView = createAction<CurrentView>(
  Type.REQUEST_UPDATE_CURRENT_VIEW,
);

export const CurrentViewActions = {
  requestUpdateCurrentView,
  updateCurrentView,
};

export type CurrentViewActionsType = typeof CurrentViewActions;

export default Type;
