import {
  call,
  cancel,
  delay,
  fork,
  put, select, take, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { Action } from 'redux-actions';
import SubTabIndex from '@/enums/SubTabIndex';
import { isCurrentViewCustom } from '@/selectors/currentViewSelector';
import {
  selectAgentGroupIds,
  selectApplicationSettings,
  selectBusinessUnitIds,
  selectDefaultSelectedBusinessUnit,
  selectDegradedMode,
  selectIsDegradedModeBannerOpen,
} from '@/selectors/applicationSettingsSelector';
import { RotationActions } from '@/actions/sync/rotationActions';
import { ApplicationSettings } from '@/interfaces/ApplicationSettings';
import { User } from '@/interfaces/User';
import { AgentGroup } from '@/interfaces/AgentGroup';
import { Site } from '@/interfaces/Site';
import SelectedUnit from '@/enums/SelectedUnit';
import { getFromWebStorage } from '@/services/webStorageService';
import formatStringAsJson from '@/utils/formatStringAsJson';
import { Type, ApplicationSettingsActions } from '@/actions/sync/applicationSettingsActions';
import { Type as UserActionsType } from '@/actions/sync/userActions';
import { ViewActionType } from '@/actions/sync/supervisorViewActions';
import comparatorByName from '@/utils/comparators';
import { WebStorageActions } from '@/actions/sync/webStorageActions';
import { getUserName } from '@/api/oAuth2ClientAPI';
import { parseAndLogError } from '@/services/loggerService';
import { loadCustomSettings, postCustomSettings } from '@/api/supervisorEngagementClientAPI';
import { CustomScriptActions } from '@/actions/sync/customScriptActions';

const BUSINESS_UNITS = 'businessUnits';
const AGENT_GROUPS = 'agentGroups';

const updateSelectedSettings = (sites, selectedIds = {}, index = BUSINESS_UNITS) => (
  sites.reduce((acc, site) => {
    const { id: siteId, [index]: agBuData } = site;
    const sortedAgBuData = agBuData.sort(comparatorByName);

    const newSelIds = (selectedIds[siteId] || []).filter((selId) => (
      sortedAgBuData
        .map((bu) => bu.id)
        .includes(selId)
    ));

    return {
      ...acc,
      [siteId]: (newSelIds.length && newSelIds)
        || (sortedAgBuData.length ? [sortedAgBuData[0].id] : []),
    };
  }, {})
);

function updateApplicationSettings(applicationSettings, sites): ApplicationSettings {
  const newApplicationSettings = applicationSettings;
  const {
    selectedSite: { id: siteId },
    selectedBusinessUnitIds,
    selectedAgentGroupIds,
  } = applicationSettings;
  const selectedSite: Site = sites.find((site) => site.id === siteId) || sites[0];
  newApplicationSettings.selectedSite = selectedSite;
  newApplicationSettings.selectedBusinessUnitIds = updateSelectedSettings(
    sites, selectedBusinessUnitIds,
  );
  const agentGroupsFromServer: AgentGroup[] = selectedSite.agentGroups;
  if (agentGroupsFromServer.length > 0) {
    newApplicationSettings.selectedAgentGroupIds = updateSelectedSettings(
      sites, selectedAgentGroupIds, AGENT_GROUPS,
    );
  }
  return newApplicationSettings;
}

const getDefaultSeletedDataForSites = (sites) => (
  sites.reduce((acc, { id, businessUnits, agentGroups }) => ({
    businessUnits: {
      ...acc.businessUnits,
      [id]: [businessUnits.sort(comparatorByName)[0].id],
    },
    agentGroups: {
      ...acc.agentGroups,
      [id]: agentGroups.length ? [agentGroups.sort(comparatorByName)[0].id] : [],
    },
  }), { businessUnits: {}, agentGroups: {} })
);

function* parseApplicationSettings(sites: Site[]) {
  const sortedSites: Site[] = sites.sort(comparatorByName);
  let applicationSettings: ApplicationSettings;
  let savedApplicationSettings: string | null = getFromWebStorage(getUserName());
  if (savedApplicationSettings) {
    savedApplicationSettings = formatStringAsJson(savedApplicationSettings);
    applicationSettings = updateApplicationSettings(savedApplicationSettings, sortedSites);
  } else {
    const initialApplicationSettings = yield select(selectApplicationSettings);
    const selectedSettings = getDefaultSeletedDataForSites(sortedSites);
    applicationSettings = {
      ...initialApplicationSettings,
      selectedUnit: SelectedUnit.BU,
      selectedSite: sortedSites[0],
      selectedBusinessUnitIds: selectedSettings.businessUnits,
      selectedAgentGroupIds: selectedSettings.agentGroups,
    };
  }
  return applicationSettings;
}

function* fillApplicationSettings({ payload }: Action<User>) {
  const { sites }: User = payload;
  const applicationSettings: ApplicationSettings = yield parseApplicationSettings(sites);
  yield put(WebStorageActions.updateWebStorage({
    key: getUserName(),
    value: applicationSettings,
  }));
  yield put(ApplicationSettingsActions.updateApplicationSettings(applicationSettings));
  const rotationSettings = applicationSettings.selectedUnit === SelectedUnit.BU
    ? yield select(selectBusinessUnitIds)
    : yield select(selectAgentGroupIds);
  yield put(RotationActions.updateRotationSettings(rotationSettings));
  const { shouldApplyCustomSettings = true } = yield call(loadCustomSettings);
  yield put(ApplicationSettingsActions.setLastSetting(shouldApplyCustomSettings));
  if (shouldApplyCustomSettings) {
    yield put(ApplicationSettingsActions.getCustomSettings());
  } else {
    yield put(ApplicationSettingsActions.setDefaultCustomSettings());
  }
}

function* updateSubTabIndex() {
  if (yield select(isCurrentViewCustom)) {
    yield put(ApplicationSettingsActions.setSubTabIndex(SubTabIndex.ZERO));
  }
}

function* setNormalBannerTimeout() {
  yield delay(5000);
  yield put(ApplicationSettingsActions.setDegradedBannerOpened(false));
}

function* updateDegradedMode({ payload }: Action<boolean>) {
  const previousDegradedState = yield select(selectDegradedMode);
  const isBannerOpen = yield select(selectIsDegradedModeBannerOpen);
  if (previousDegradedState === payload && !isBannerOpen) {
    return;
  }

  if (previousDegradedState !== payload) {
    yield put(ApplicationSettingsActions.setDegradedBannerOpened(true));
    yield put(ApplicationSettingsActions.setDegradedBannerState(payload));
  }

  if (!payload) {
    const setCloseTimeout = yield fork(setNormalBannerTimeout);
    yield take(Type.UPDATE_RTM_DEGRADED_STATE);
    yield cancel(setCloseTimeout);
  }
}

function* getCustomSettings() {
  try {
    const {
      rotation, customScript, selectedBusinessUnits, selectedAgentGroups,
    } = yield call(loadCustomSettings);
    if (rotation) {
      if (rotation.isRotationInProgress) {
        yield put(RotationActions.startRotation());
        yield put(RotationActions.changeRotationInterval(rotation.rotationInterval));
        yield put(RotationActions.updateRotationSettings(rotation.rotationSettings));
      }
      yield put(RotationActions.setManualRotationPause(rotation.isManualRotationPause));
      yield put(RotationActions.setRotationPause(rotation.isRotationPause));
    }
    if (customScript) {
      yield put(CustomScriptActions.updateLanguage(customScript));
    }
    if (selectedBusinessUnits) {
      yield put(ApplicationSettingsActions.selectedBusinessUnitsChanged(
        Object.values(selectedBusinessUnits)[0],
        Object.keys(selectedBusinessUnits)[0],
      ));
    }
    if (selectedAgentGroups) {
      yield put(ApplicationSettingsActions.selectedAgentGroupsChanged(
        Object.values(selectedAgentGroups)[0],
        Object.keys(selectedAgentGroups)[0],
      ));
    }
  } catch (error) {
    parseAndLogError(error);
  }
}

function* setCustomSettings() {
  try {
    yield call(postCustomSettings);
  } catch (error) {
    parseAndLogError(error);
  }
}

function* setDefaultCustomSettings() {
  try {
    yield put(RotationActions.stopRotation());
    yield put(ApplicationSettingsActions.selectedUnitChanged(SelectedUnit.BU));
    const defaultBusinessUnit = yield select(selectDefaultSelectedBusinessUnit);
    yield put(ApplicationSettingsActions.selectedBusinessUnitsChanged(
      [defaultBusinessUnit.id],
      defaultBusinessUnit.siteId,
    ));
    yield put(RotationActions.updateRotationSettings([defaultBusinessUnit.id]));
  } catch (error) {
    parseAndLogError(error);
  }
}

export default function* applicationSettingsSaga() {
  yield takeEvery(UserActionsType.UPDATE_USER, fillApplicationSettings);
  yield takeEvery([
    Type.SELECTED_AGENT_GROUPS_CHANGED,
    Type.SELECTED_BUSINESS_UNITS_CHANGED,
    ViewActionType.DISCARD_VIEW_CHANGES,
  ], updateSubTabIndex);
  yield takeLatest(Type.UPDATE_RTM_DEGRADED_STATE, updateDegradedMode);
  yield takeEvery(Type.GET_CUSTOM_SETTINGS, getCustomSettings);
  yield takeEvery(Type.SET_CUSTOM_SETTINGS, setCustomSettings);
  yield takeEvery(Type.SET_DEFAULT_CUSTOM_SETTING, setDefaultCustomSettings);
}
