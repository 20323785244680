import {
  takeEvery, put, call, select,
} from 'redux-saga/effects';
import { parseAndLogError } from '@/services/loggerService';
import { Type, ConversationActions } from '@/actions/sync/conversationActions';
import { loadConversation, loadConversationStartDate, loadFollowUpConversation } from '@/api/realtimeClientAPI';
import { selectActiveConversationId } from '@/selectors/conversationManagerSelector';

export function* fetchPreviousChatConversation({
  payload: { engagementID, conversationID },
}: ReturnType<typeof ConversationActions.fetchPreviousChatConversation>) {
  try {
    yield put(ConversationActions.requestInProgress({
      conversationID,
      isLoading: true,
    }));
    const conversationsResponse = yield call(loadConversation, engagementID, conversationID);
    const { engagements } = conversationsResponse;
    yield put(ConversationActions.updateConversationTranscript({ engagements, conversationID }));
  } catch (error) {
    parseAndLogError(error);
  } finally {
    yield put(ConversationActions.requestInProgress({
      conversationID,
      isLoading: false,
    }));
    yield put(ConversationActions.requestCompleted({
      conversationID,
      isLoaded: true,
    }));
  }
}

export function* fetchFollowUpConversation({
  payload: { conversationID },
}: ReturnType<typeof ConversationActions.fetchConversationTranscript>) {
  try {
    yield put(ConversationActions.requestInProgress({
      conversationID,
      isLoading: false,
    }));
    const startDateResponse = yield call(
      loadConversationStartDate, conversationID,
    );
    const startDate = startDateResponse.conversations[0].conversationStartDate;
    const followUpConversationResponse = yield call(
      loadFollowUpConversation, startDate, conversationID,
    );
    const { engagements } = followUpConversationResponse;
    yield put(ConversationActions.updateConversationTranscript({ engagements, conversationID }));
  } catch (error) {
    parseAndLogError(error);
  } finally {
    yield put(ConversationActions.requestInProgress({
      conversationID,
      isLoading: false,
    }));
    yield put(ConversationActions.requestCompleted({
      conversationID,
      isLoaded: true,
    }));
  }
}

export function* startConversationRequests() {
  try {
    if (yield select(selectActiveConversationId)) {
      const conversationID = yield select(selectActiveConversationId);
      yield put(ConversationActions.fetchConversationTranscript({
        conversationID,
      }));
    }
  } catch (error) {
    parseAndLogError(error);
  }
}

export default function* conversationSaga() {
  yield takeEvery(Type.FETCH_ACTIVE_CONVERSATION_TRANSCRIPT, startConversationRequests);
  yield takeEvery(Type.FETCH_PREVIOUS_CHAT_CONVERSATION, fetchPreviousChatConversation);
  yield takeEvery(Type.FETCH_CONVERSATION_TRANSCRIPT, fetchFollowUpConversation);
}
