import Category from '@/enums/Category';
import Views from '@/enums/ViewName';
import LocaleKey from './localeKeys';

export const CategoriesLocaleKeyMap = {
  [Category.SUMMARY]: LocaleKey.SUMMARY,
  [Category.QUEUE]: LocaleKey.QUEUE,
  [Category.ENGAGEMENT]: LocaleKey.ACTIVE,
  [Category.CONVERSATION]: LocaleKey.UNRESOLVED_CHATS,
  [Category.AGENT]: LocaleKey.AGENTS,
  [Category.BOOKMARK]: LocaleKey.BOOKMARKS,
};

export const ViewsLocalKeyMap = {
  [Views.SUMMARY]: LocaleKey.SUMMARY,
  [Views.QUEUE_CHATS]: LocaleKey.CHAT_REQUESTS,
  [Views.QUEUE_AGENTS]: LocaleKey.AGENT_SERVICEABILITY,
  [Views.QUEUE_RULES]: LocaleKey.BUSINESS_RULES,
  [Views.QUEUE_PAGES]: LocaleKey.PAGES,
  [Views.ENGAGEMENT_CHATS]: LocaleKey.CHATS,
  [Views.ENGAGEMENT_RULES]: LocaleKey.BUSINESS_RULES,
  [Views.ENGAGEMENT_PAGES]: LocaleKey.PAGES,
  [Views.ENGAGEMENT_ESCALATED]: LocaleKey.ESCALATED_CHATS,
  [Views.AGENTS_ACTIVE]: LocaleKey.ENGAGEMENT_METRICS,
  [Views.AGENTS_ALL]: LocaleKey.STATUSES,
  [Views.CONVERSATION]: LocaleKey.FOLLOW_UP,
  [Views.BOOKMARK_CHATS]: LocaleKey.CHATS,
  [Views.BOOKMARK_CONVERSATION]: LocaleKey.CONVERSATIONS,
};

/**
 * convention to include/exclude view from category
 *
 * {
 *    [removeFromCategory]: {
 *      [viewName]: [moveToCategory]
 *    }
 * }
 *
 */

export const IncludeExcludeTabs = {
  [Category.ENGAGEMENT]: {
    [Views.AGENTS_ACTIVE]: Category.AGENT,
    [Views.BOOKMARK_CHATS]: Category.BOOKMARK,
  },
  [Category.CONVERSATION]: {
    [Views.BOOKMARK_CONVERSATION]: Category.BOOKMARK,
  },
};

export default { CategoriesLocaleKeyMap, ViewsLocalKeyMap, IncludeExcludeTabs };
