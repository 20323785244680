import {
  call,
  fork,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import guid from '@/utils/guid';
import escapeAngleBrackets from '@/utils/escapeAngleBrackets';
import {
  CSRF_TOKEN,
  removeFromCookie,
  getFromCookie,
  putToCookie,
  SSO_USER,
} from '@/services/cookieService';
import getQueryParameterByName from '@/utils/getQueryParameterByName';
import { parseAndLogError } from '@/services/loggerService';
import {
  clearWebStorageBeforeLogout,
  getFromWebStorage,
} from '@/services/webStorageService';
import Type, { Oauth2Actions } from '@/actions/sync/oauth2Actions';
import { TokenPayload, TokenPayloadResponse } from '@/interfaces/TokenPayload';
import formatStringAsJson from '@/utils/formatStringAsJson';
import {
  authenticateCredentials,
  updateTokens,
  authenticateAuthToken,
  logout,
} from '@/api/oAuth2ClientAPI';
import toTokenPayload from '@/services/converters/tokenPayloadConverter';
import {
  CHANGE_PASSWORD_PAGE,
  LOGIN,
  EXTERNAL_PAGE,
  LOADING,
} from '@/constants/localUrl';
import { tokenPayloadInitialState } from '@/reducers/oauth2Reducer';
import { MessageActions } from '@/actions/sync/messageActions';
import localeKeys from '@/constants/localeKeys';
import Severity from '@/enums/Severity';
import ERROR from '@/enums/Error';
import getStringByLocaleKey from '@/utils/getStringByLocaleKey';
import history from '@/services/history';
import { WebStorageActions } from '@/actions/sync/webStorageActions';
import { USER_LOGGED_OUT } from '@/interfaces/actionTypes';
import isSsoProcessing from '@/utils/isSsoProcessing';
import createLogoutAction from '@/actions/sync/rootActions';
import { logoutAeapi } from '@/api/agentEngagementAPI';
import { loginSupervisor } from '@/api/supervisorEngagementClientAPI';
import { TOKEN_INFO } from '@/constants/WebStorageKey';

export function* authenticateToken(authToken: string) {
  try {
    const token: TokenPayloadResponse = yield call(authenticateAuthToken, authToken);
    const tokenPayload: TokenPayload = toTokenPayload(token);
    yield put(Oauth2Actions.updateToken(tokenPayload));
    yield put(Oauth2Actions.authenticated());
  } catch (error) {
    history.push(LOGIN);
    yield put(MessageActions.addMessageDetails({
      message: getStringByLocaleKey(localeKeys.LOGIN_FAILURE),
      severity: Severity.ERROR,
    }));
    parseAndLogError(error);
  }
}

function* checkAeapiStatus() {
  try {
    history.push(LOADING);
    yield call(loginSupervisor);
  } catch (error: any) {
    if (error?.status === undefined || ![401, 403].includes(error.status)) {
      throw new Error(ERROR.NETWORK_ERROR);
    }
  }
}

function* authenticate({ payload }: ReturnType<typeof Oauth2Actions.authenticate>) {
  const { username, password } = payload;
  try {
    const { data } = yield call(authenticateCredentials, username, password);
    if (data) {
      const tokenPayload: TokenPayload = toTokenPayload(data);
      yield put(Oauth2Actions.updateToken(tokenPayload));
      yield call(checkAeapiStatus);
      yield put(Oauth2Actions.authenticated());
    }
  } catch (error: any) {
    const errorType = error.response?.data.error || error.message;
    yield put(Oauth2Actions.setAuthenticationInProgress(false));
    switch (errorType) {
      case ERROR.INVALID_GRANT:
        yield put(MessageActions.addMessageDetails({
          message: getStringByLocaleKey(localeKeys.INVALID),
          severity: Severity.ERROR,
        }));
        break;
      case ERROR.ACCESS_DENIED:
        yield put(MessageActions.addMessageDetails({
          message: getStringByLocaleKey(localeKeys.IMPROPER_ACCOUNT_CONFIG),
          severity: Severity.ERROR,
        }));
        break;
      case ERROR.ACCOUNT_EXPIRED:
        history.push(`${CHANGE_PASSWORD_PAGE}/${username}`);
        yield put(MessageActions.addMessageDetails({
          message: getStringByLocaleKey(localeKeys.EXPIRED),
          severity: Severity.ERROR,
        }));
        break;
      case ERROR.NETWORK_ERROR:
        yield put(Oauth2Actions.logout());
        yield put(MessageActions.addMessageDetails({
          message: getStringByLocaleKey(localeKeys.APPLICATION_ERROR_OCCURED),
          severity: Severity.ERROR,
        }));
        break;
      default: parseAndLogError(errorType);
    }
  }
}

function* restoreToken() {
  try {
    const tokenPayload = yield select((state) => state.oauth2.tokenPayload);
    if ((tokenPayload === tokenPayloadInitialState)
      && !!getFromWebStorage(TOKEN_INFO, true)) {
      const token: TokenPayload | null = formatStringAsJson(
        getFromWebStorage(TOKEN_INFO, true),
      );
      const error = yield call(updateTokens, token);
      if (error) {
        history.push(LOADING);
        yield put(Oauth2Actions.logout());
      } else {
        yield put(Oauth2Actions.authenticated());
      }
    }
  } catch (error: any) {
    history.push(LOGIN);
    parseAndLogError(error.response);
  }
}

function checkForSsoAndRedirect() {
  const isSSOuser = sessionStorage.getItem(SSO_USER);
  if (isSSOuser === 'true') {
    history.push(`${LOGIN}?sso_user=true`);
  } else {
    history.push(LOGIN);
  }
}

function onLogout() {
  clearWebStorageBeforeLogout();
  checkForSsoAndRedirect();
}

function* updateToken({ payload }: ReturnType<typeof Oauth2Actions.updateToken>) {
  yield put(WebStorageActions.updateWebStorage({
    key: TOKEN_INFO,
    value: payload,
    isSession: true,
  }));
}

export function* checkAndAuthenticateSso() {
  try {
    const authToken = getQueryParameterByName('authToken');
    const isSSO: boolean = isSsoProcessing();
    if (authToken) {
      const remoteCsrfToken = getQueryParameterByName('state');
      const localCsrfToken = getFromCookie(CSRF_TOKEN);
      window.history.replaceState('', '', '/');
      window.history.pushState('', '', '/');
      if (remoteCsrfToken === localCsrfToken) {
        yield authenticateToken(authToken);
      } else {
        history.push(LOGIN);
      }
      yield call(removeFromCookie, CSRF_TOKEN);
    } else if (isSSO) {
      const csrfToken = guid();
      const response = yield call(authenticateCredentials, '', '', csrfToken);
      const isAuthenticated: boolean = !!getFromWebStorage(TOKEN_INFO, true);
      if (response.status === 278) {
        putToCookie(CSRF_TOKEN, csrfToken);
        if (isSSO) {
          if (isAuthenticated) {
            history.replace({
              search: '',
            });
          } else {
            yield put(Oauth2Actions.setIdpPage(escapeAngleBrackets(response.headers['x-sso-location'])));
            sessionStorage.setItem(SSO_USER, 'true');
            history.push(EXTERNAL_PAGE);
          }
        } else {
          yield put(MessageActions.addMessageDetails({
            message: getStringByLocaleKey(localeKeys.LOGIN_FAILURE),
            severity: Severity.ERROR,
          }));
        }
      }
    }
  } catch (error) {
    history.push(LOGIN);
    yield put(MessageActions.addMessageDetails({
      message: getStringByLocaleKey(localeKeys.IMPROPER_ACCOUNT_CONFIG),
      severity: Severity.ERROR,
    }));
  }
}

function* logoutUser() {
  try {
    yield fork(logoutAeapi);
    yield fork(logout);
  } catch (error: any) {
    parseAndLogError(error.response);
  }

  try {
    yield put(createLogoutAction());
  } catch (error: any) {
    parseAndLogError(error.response);
  } finally {
    checkForSsoAndRedirect();
  }
}

export default function* oauth2Saga() {
  yield takeEvery(Type.AUTHENTICATE, authenticate);
  yield takeEvery(Type.UPDATE_TOKEN, updateToken);
  yield takeEvery(Type.RESTORE_TOKEN, restoreToken);
  yield takeEvery(Type.CHECK_AND_AUTHENTICATE_SSO, checkAndAuthenticateSso);
  yield takeEvery(Type.LOGOUT, logoutUser);
  yield takeEvery(USER_LOGGED_OUT, onLogout);
}
