import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import { ServerLocale, LocaleLanguage } from '@/interfaces/Language';
import { CustomScriptActions } from '@/actions/sync/customScriptActions';
import { WebStorageActionType, WebStorageActions } from '@/actions/sync/webStorageActions';
import { Oauth2Actions } from '@/actions/sync/oauth2Actions';
import { RegisteredIdActions } from '@/actions/sync/userActions';
import { getFromWebStorage, saveToWebStorage, removeFromWebStorage } from '@/services/webStorageService';
import formatStringAsJson from '@/utils/formatStringAsJson';
import { TokenPayload } from '@/interfaces/TokenPayload';
import {
  CURRENT_LOCALE, REGISTERED_ID, TOKEN_INFO, VIEW_DATA,
} from '@/constants/WebStorageKey';
import { SupervisorViewActions } from '@/actions/sync/supervisorViewActions';

function* updateWebStorage(
  { payload }: ReturnType<typeof WebStorageActions.updateWebStorage>,
) {
  const { key, value, isSession } = payload;
  yield call(saveToWebStorage, key, value, isSession);
}

function* clearWebStorage({ payload }: ReturnType<typeof WebStorageActions.clearWebStorage>) {
  const { key, isSession } = payload;
  yield call(removeFromWebStorage, key, isSession);
}

function* initalizeViews(views) {
  yield put(SupervisorViewActions.applyViewsFromStorage(views));
}

function* initWebStorage() {
  // update redux store from Local Storage data
  const registeredId = getFromWebStorage(REGISTERED_ID);
  const token: TokenPayload | null = formatStringAsJson(
    getFromWebStorage(TOKEN_INFO, true),
  );
  const currentLocale = getFromWebStorage(CURRENT_LOCALE) as ServerLocale;
  const views = formatStringAsJson(
    getFromWebStorage(VIEW_DATA, true),
  );

  if (token) {
    yield put(Oauth2Actions.updateToken(token));
    yield put(Oauth2Actions.authenticated());
  }
  if (registeredId) {
    yield put(RegisteredIdActions.updateRegisteredId(registeredId));
  }
  if (currentLocale) {
    yield put(CustomScriptActions.updateLanguage({
      language: LocaleLanguage[currentLocale],
      lastUpdateDate: 0,
      locale: currentLocale,
    }));
  }
  if (views) {
    yield initalizeViews(views);
  }
}

export default function* webStorageSaga() {
  yield takeEvery(WebStorageActionType.INIT_WEB_STORAGE, initWebStorage);
  yield takeEvery(WebStorageActionType.UPDATE_WEB_STORAGE, updateWebStorage);
  yield takeEvery(WebStorageActionType.CLEAR_WEB_STORAGE, clearWebStorage);
}
