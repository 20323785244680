interface UrlAuth {
  TOKEN: string;
  LOGOUT: string;
}

interface UrlEAPI {
  CHANGE_PASSWORD: string;
  JOIN_ENGAGEMENT: string;
  LOGOUT: string;
  MESSAGES: string;
  SUPERVISOR: UrlSEAPI;
  VERIFY_SESSION: string;
}

interface UrlSEAPI {
  CUSTOM_FILTER: string;
  SUPERVISOR_VIEW: string;
  LOGGING: string;
  LOGIN: string;
  LOGOUT_AGENT: string;
  EXIT_ENGAGEMENT: string;
  CUSTOM_SETTINGS: string;
}

interface UrlV3 {
  METRIC_REALTIME: string;
  TRANSCRIPT: string;
  CONVERSATION_TRANSCRIPT: string;
}

interface UrlApi {
  SUPERVISOR_SETTINGS: string;
  ENGAGEMENT_API_V2_AGENT: UrlEAPI;
  V3: UrlV3;
}

interface Url {
  OAUTH: UrlAuth;
  API: UrlApi;
}
const ENV = window.NUANCE_ENV;

const URL: Url = {
  OAUTH: {
    TOKEN: `${ENV?.AUTH_DOMAIN}/oauth-server/oauth/token`,
    LOGOUT: `${ENV?.AUTH_DOMAIN}/oauth-server/oauth/logout`,
  },
  API: {
    SUPERVISOR_SETTINGS: `${ENV?.API_DOMAIN}/supervisor/settings`,
    ENGAGEMENT_API_V2_AGENT: {
      CHANGE_PASSWORD: `${ENV?.API_DOMAIN}/engagementAPI/v2/agent/changePassword`,
      JOIN_ENGAGEMENT: `${ENV?.API_DOMAIN}/engagementAPI/v2/agent/joinEngagement`,
      LOGOUT: `${ENV?.API_DOMAIN}/engagementAPI/v2/agent/logout`,
      MESSAGES: `${ENV?.API_DOMAIN}/engagementAPI/v2/agent/messages`,
      SUPERVISOR: {
        CUSTOM_FILTER: `${ENV?.API_DOMAIN}/engagementAPI/v2/agent/supervisor/customFilter`,
        SUPERVISOR_VIEW: `${ENV?.API_DOMAIN}/engagementAPI/v2/agent/supervisor/supervisorView`,
        LOGGING: `${ENV?.API_DOMAIN}/engagementAPI/v2/agent/supervisor/logging`,
        LOGIN: `${ENV?.API_DOMAIN}/engagementAPI/v2/agent/supervisor/login`,
        LOGOUT_AGENT: `${ENV?.API_DOMAIN}/engagementAPI/v2/agent/supervisor/logoutAgent`,
        EXIT_ENGAGEMENT: `${ENV?.API_DOMAIN}/engagementAPI/v2/agent/supervisor/exitEngagement`,
        CUSTOM_SETTINGS: `${ENV?.API_DOMAIN}/engagementAPI/v2/agent/supervisor/customSettings`,
      },
      VERIFY_SESSION: `${ENV?.API_DOMAIN}/engagementAPI/v2/agent/verifySession`,
    },
    V3: {
      METRIC_REALTIME: `${ENV?.API_DOMAIN}/v3/metric/realtime`,
      TRANSCRIPT: `${ENV?.API_DOMAIN}/v3/transcript/realtime`,
      CONVERSATION_TRANSCRIPT: `${ENV?.API_DOMAIN}/v3/transcript/conversation`,
    },
  },
};

export default URL;
