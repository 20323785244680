import { put, takeEvery } from 'redux-saga/effects';
import { Oauth2Actions } from '@/actions/sync/oauth2Actions';
import { WebStorageActions } from '@/actions/sync/webStorageActions';
import { Type } from '@/actions/sync/applicationSettingsActions';

function* initApplication() {
  yield put(WebStorageActions.initWebStorage());
  yield put(Oauth2Actions.checkAndAuthenticateSso());
}

export default function* initApplicationSaga() {
  yield takeEvery(Type.INIT_APPLICATION, initApplication);
}
