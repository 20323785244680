import {
  put, call, select, delay, takeLatest,
} from 'redux-saga/effects';
import { DimensionMetrics } from '@/interfaces/DimensionMetrics';
import { parseAndLogError } from '@/services/loggerService';
import {
  buildBuAndAgParams,
  getCurrentSiteId,
  selectRTMRequestInterval,
} from '@/selectors/applicationSettingsSelector';
import AppURLs from '@/api/url';
import { Type as ApplicationActionType } from '@/actions/sync/applicationSettingsActions';
import {
  loadAgentGroups,
  loadTableData,
} from '@/api/realtimeClientAPI';
import CurrentViewActionsType from '@/actions/sync/currentViewActions';
import { RealtimeMetricResponse } from '@/interfaces/responses/RealtimeMetricsResponse';
import createRequiredFieldsString from '@/utils/createRequiredFieldsString';
import { TableDataActions } from '@/actions/sync/tableDataActions';
import { CurrentView } from '../interfaces/currentView';
import { selectShouldStopRequests } from '../selectors/tableDataSelector';
import { selectCurrentView } from '../selectors/currentViewSelector';
import Category from '@/enums/Category';
import Dimension from '@/enums/Dimension';

export function* fetchRTM(currentView) {
  try {
    const {
      category,
      dimension,
      columns,
      returnFields,
      queryFilter,
    } = currentView;

    if (yield select(getCurrentSiteId)) {
      const url: string = `${`${AppURLs.API.V3.METRIC_REALTIME}?`
        + `returnFields=${createRequiredFieldsString(columns, returnFields)}`
        + `&site=${yield select(getCurrentSiteId)}`
        + `${yield select(buildBuAndAgParams)}`
        + `&category=${category}`
        + `&dimension=${dimension}`
        + '&output=json'}${queryFilter
        ? `&filter=${queryFilter}`
        : ''}`;
      const response = yield call(loadTableData, url);

      if ((category === Category.AGENT || Category.ENGAGEMENT || Category.QUEUE)
        && dimension === Dimension.AGENTS) {
        const agentResponse = yield call(loadAgentGroups);
        agentResponse.agents.forEach((agent) => {
          response.agents.forEach((element, index) => {
            if (agent.agentID === element.agentID) {
              response.agents[index].totalNumberOfAGs = agent.totalNumberOfAGs;
              response.agents[index].agentGroups = agent.agentGroups;
            }
          });
        });
      }
      yield put(TableDataActions.updateFetchedData(
        response || {} as RealtimeMetricResponse<DimensionMetrics>,
      ));
    }
  } catch (error) {
    parseAndLogError(error);
  }
}

export function* startRequests() {
  let currentView: CurrentView = yield select(selectCurrentView);
  yield put(TableDataActions.setLoadingData(true));
  while (currentView.columns.length) {
    if (!(yield select(selectShouldStopRequests))) {
      // for Pinned Chats and Pinned Conversation tabs we need to stop sending requests
      // if there is no pinned Chats or Conversation
      yield call(fetchRTM, currentView);
    } else {
      yield put(TableDataActions.updateFetchedData({} as RealtimeMetricResponse<DimensionMetrics>));
    }
    yield put(TableDataActions.setLoadingData(false));
    yield delay(yield select(selectRTMRequestInterval));
    currentView = yield select(selectCurrentView);
  }
}

export function* watchRTMetrics() {
  yield takeLatest([
    CurrentViewActionsType.UPDATE_CURRENT_VIEW,
    ApplicationActionType.SELECTED_UNIT_CHANGED,
    ApplicationActionType.SELECTED_BUSINESS_UNITS_CHANGED,
    ApplicationActionType.SELECTED_AGENT_GROUPS_CHANGED,
  ], startRequests);
}
