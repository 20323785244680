import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { store } from '@/stores/store';
import { OnlineStatusActions } from '@/actions/sync/onlineStatusActions';
import { getTokenPayload } from '@/selectors/oauth2Selector';
import escapeCRLF from '@/utils/escapeCRLF';
import RequestMethod from '@/enums/RequestMethod';
import ContentType from '@/constants/contentType';

axios.interceptors.response.use((response) => {
  const { onlineStatus } = store.getState();
  if (!onlineStatus) {
    store.dispatch(OnlineStatusActions.updateOnlineStatus(true));
  }
  return response;
}, (error) => {
  const errorStatus = error.response?.status;
  const { onlineStatus } = store.getState();

  if (!errorStatus && onlineStatus) {
    store.dispatch(OnlineStatusActions.updateOnlineStatus(false));
  }
  return Promise.reject(error);
});

export function send<T>(
  method: RequestMethod,
  url: string,
  data?: string | null,
  headers?: AxiosRequestHeaders,
  authorized?: boolean,
  timeout?: number,
): Promise<AxiosResponse<T>> {
  const { accessToken } = getTokenPayload();
  if (authorized && accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${escapeCRLF(accessToken.toString())}`;
  }

  axios.defaults.headers.common.Accept = ContentType.JSON;
  axios.defaults.headers.post['Content-Type'] = ContentType.FORM;

  if (timeout) {
    axios.defaults.timeout = timeout;
  }

  return axios(
    {
      url,
      method,
      data,
      headers,
    },
  );
}

export function get<T>(
  url: string,
  data: string | null,
  headers?: AxiosRequestHeaders,
): Promise<AxiosResponse<T>> {
  return send(RequestMethod.GET, url, data, headers);
}

export function post<T>(
  url: string,
  data: string | null,
  headers?: AxiosRequestHeaders,
): Promise<AxiosResponse<T>> {
  return send(RequestMethod.POST, url, data, headers);
}

export function put<T>(
  url: string,
  data: string | null,
  headers?: AxiosRequestHeaders,
): Promise<AxiosResponse<T>> {
  return send(RequestMethod.PUT, url, data, headers);
}
