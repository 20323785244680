import {
  put, call, select, delay, takeLatest,
} from 'redux-saga/effects';
import { parseAndLogError } from '@/services/loggerService';
import { getCurrentSiteId, selectRTMRequestInterval } from '@/selectors/applicationSettingsSelector';
import { getSummaryProcessing } from '@/selectors/summarySelector';
import { Type, SummaryActions } from '@/actions/sync/summaryActions';
import { Type as ApplicationActionType } from '@/actions/sync/applicationSettingsActions';
import {
  loadQueueSummary,
  loadEngagementSummary,
  loadAgentSummary,
} from '@/api/realtimeClientAPI';

export function* fetchSummaryRequests() {
  try {
    const queueResponse = yield call(loadQueueSummary);
    yield put(SummaryActions.updateQueue(queueResponse.summary));
  } catch (error) {
    parseAndLogError(error);
  }
  try {
    const engagementResponse = yield call(loadEngagementSummary);
    yield put(SummaryActions.updateEngagement(engagementResponse.summary));
  } catch (error) {
    parseAndLogError(error);
  }
  try {
    const agentResponse = yield call(loadAgentSummary);
    yield put(SummaryActions.updateAgent(agentResponse.summary));
  } catch (error) {
    parseAndLogError(error);
  }
}

export function* startRequests() {
  while (yield select(getSummaryProcessing)) {
    const siteId = yield select(getCurrentSiteId);
    if (siteId) {
      yield call(fetchSummaryRequests);
    }
    yield delay(yield select(selectRTMRequestInterval));
  }
}

export function* watchSummary() {
  yield takeLatest([
    Type.KPI_OPEN,
    Type.SUMMARY_OPEN,
    ApplicationActionType.SELECTED_UNIT_CHANGED,
    ApplicationActionType.SELECTED_BUSINESS_UNITS_CHANGED,
    ApplicationActionType.SELECTED_AGENT_GROUPS_CHANGED,
  ], startRequests);
}
