import { handleActions } from 'redux-actions';
import { CustomScript } from '@/interfaces/CustomScript';
import { CustomScriptActionType } from '@/actions/sync/customScriptActions';
import { Language, ServerLocale } from '@/interfaces/Language';

export const customScriptInitialState = {
  language: Language.ENGLISH,
  lastUpdateDate: 0,
  locale: ServerLocale.US,
  filter: [],
  isFetching: false,
};

const processFiltersAction = (state, action): CustomScript => {
  const { payload } = action;
  return { ...state, filter: payload };
};

const addNewFilter = (state, action): CustomScript => ({
  ...state,
  filter: [...state.filter, action.payload],
});

const editFilter = (state, action): CustomScript => ({
  ...state,
  filter: [...action.payload],
});

const updateRequestStatus = (state, action): CustomScript => {
  const { payload } = action;
  return { ...state, isFetching: payload };
};

function isLanguageData(data: CustomScript): data is CustomScript {
  const isLanguage = Object.values(Language).includes(data.language);
  const isLocale = Object.values(ServerLocale).includes(data.locale);
  return isLanguage && isLocale;
}

const updateLanguageData = (state, action): CustomScript => {
  const languageData = action.payload && isLanguageData(action.payload)
    ? action.payload
    : customScriptInitialState;
  return { ...state, ...languageData };
};

const customScriptReducer = handleActions<CustomScript>(
  {
    [CustomScriptActionType.CUSTOM_SCRIPT_REQUEST_IN_PROGRESS]: updateRequestStatus,
    [CustomScriptActionType.UPDATE_FILTERS]: processFiltersAction,
    [CustomScriptActionType.DELETE_FILTER]: processFiltersAction,
    [CustomScriptActionType.UPDATE_LANGUAGE]: updateLanguageData,
    [CustomScriptActionType.ADD_FILTER]: addNewFilter,
    [CustomScriptActionType.EDIT_FILTER]: editFilter,
  },
  customScriptInitialState,
);

export default customScriptReducer;
