import { createAction } from 'redux-actions';
import { ApplicationSettings } from '@/interfaces/ApplicationSettings';
import { AgentGroup } from '@/interfaces/AgentGroup';
import { BusinessUnit } from '@/interfaces/BusinessUnit';
import { Site } from '@/interfaces/Site';

export enum Type {
  INIT_APPLICATION = 'INIT_APPLICATION',
  UPDATE_APPLICATION_SETTINGS = 'UPDATE_APPLICATION_SETTINGS',
  SELECTED_SITE_CHANGED = 'SELECTED_SITE_CHANGED',
  SELECTED_UNIT_CHANGED = 'SELECTED_UNIT_CHANGED',
  SELECTED_AGENT_GROUPS_CHANGED = 'SELECTED_AGENT_GROUPS_CHANGED',
  SELECTED_BUSINESS_UNITS_CHANGED = 'SELECTED_BUSINESS_UNITS_CHANGED',
  SHOW_TRANSCRIPT_DETAILS = 'SHOW_TRANSCRIPT_DETAILS',
  SHOW_COLUMN_SEARCH = 'SHOW_COLUMN_SEARCH',
  SET_SUB_TAB_INDEX = 'SET_SUB_TAB_INDEX',
  PORTAL_SETTINGS_CHANGED = 'PORTAL_SETTINGS_CHANGED',
  UPDATE_SEARCH_VALUE = 'UPDATE_SEARCH_VALUE',
  UPDATE_RTM_DEGRADED_STATE = 'UPDATE_RTM_DEGRADED_STATE',
  SET_DEGRADED_BANNER_STATE = 'SET_DEGRADED_BANNER_STATE',
  SET_DEGRADED_BANNER_OPENED = 'SET_DEGRADED_BANNER_OPENED',
  UPDATE_LOW_RESOLUTION = 'UPDATE_LOW_RESOLUTION',
  GET_CUSTOM_SETTINGS = 'GET_CUSTOM_SETTINGS',
  SET_CUSTOM_SETTINGS = 'SET_CUSTOM_SETTINGS',
  SET_LAST_SETTING = 'SET_LAST_SETTING',
  SET_DEFAULT_CUSTOM_SETTING = 'SET_DEFAULT_CUSTOM_SETTING',
}

const initApplication = createAction(Type.INIT_APPLICATION);
const updateApplicationSettings = createAction<ApplicationSettings>(
  Type.UPDATE_APPLICATION_SETTINGS,
);
const selectedSiteChanged = createAction<Site>(Type.SELECTED_SITE_CHANGED);
const selectedUnitChanged = createAction<string>(Type.SELECTED_UNIT_CHANGED);
const selectedAgentGroupsChanged = createAction<string[], number>(
  Type.SELECTED_AGENT_GROUPS_CHANGED,
  (selectedAgIds: string[]): string[] => selectedAgIds,
  (selectedAgIds: string[], siteId: number): number => siteId,
);
const selectedBusinessUnitsChanged = createAction<string[], number>(
  Type.SELECTED_BUSINESS_UNITS_CHANGED,
  (selectedBuIds: string[]): string[] => selectedBuIds,
  (selectedBuIds: string[], siteId: number): number => siteId,
);
const showTranscriptDetails = createAction<boolean>(Type.SHOW_TRANSCRIPT_DETAILS);
const showColumnSearch = createAction<string>(Type.SHOW_COLUMN_SEARCH);
const updateLowResolution = createAction<boolean>(Type.UPDATE_LOW_RESOLUTION);
const setSubTabIndex = createAction<number>(Type.SET_SUB_TAB_INDEX);
const portalSettingsChanged = createAction<boolean>(Type.PORTAL_SETTINGS_CHANGED);
const updateSearchValue = createAction<string>(Type.UPDATE_SEARCH_VALUE);
const updateRTMDegradedState = createAction<boolean>(Type.UPDATE_RTM_DEGRADED_STATE);
const setDegradedBannerState = createAction<boolean>(Type.SET_DEGRADED_BANNER_STATE);
const setDegradedBannerOpened = createAction<boolean>(Type.SET_DEGRADED_BANNER_OPENED);
const getCustomSettings = createAction(Type.GET_CUSTOM_SETTINGS);
const setCustomSettings = createAction(Type.SET_CUSTOM_SETTINGS);
const setLastSetting = createAction<boolean>(Type.SET_LAST_SETTING);
const setDefaultCustomSettings = createAction(Type.SET_DEFAULT_CUSTOM_SETTING);

export const ApplicationSettingsActions = {
  initApplication,
  updateApplicationSettings,
  selectedUnitChanged,
  selectedSiteChanged,
  selectedAgentGroupsChanged,
  selectedBusinessUnitsChanged,
  showTranscriptDetails,
  showColumnSearch,
  setSubTabIndex,
  portalSettingsChanged,
  updateSearchValue,
  updateRTMDegradedState,
  setDegradedBannerState,
  setDegradedBannerOpened,
  updateLowResolution,
  getCustomSettings,
  setCustomSettings,
  setLastSetting,
  setDefaultCustomSettings,
};

export type ApplicationSettingsPayloads =
  Site &
  string &
  AgentGroup[] &
  BusinessUnit[] &
  boolean &
  string &
  number;

export type ApplicationSettingsActionsType = typeof ApplicationSettingsActions;

export default Type;
