import { createAction } from 'redux-actions';
import { DimensionMetrics } from '@/interfaces/DimensionMetrics';
import { RealtimeMetricResponse } from '@/interfaces/responses/RealtimeMetricsResponse';

export enum Type {
  UPDATE_FETCHED_DATA = 'UPDATE_FETCHED_DATA',
  SET_LOADING = 'SET_LOADING',
  SHOULD_STOP_REQUESTS = 'SHOULD_SEND_REQUESTS',
}

const updateFetchedData = createAction<RealtimeMetricResponse<DimensionMetrics>>(
  Type.UPDATE_FETCHED_DATA,
);
const setLoading = createAction<boolean>(Type.SET_LOADING);
const shouldStopRequests = createAction<boolean>(Type.SHOULD_STOP_REQUESTS);

export const TableDataActions = {
  updateFetchedData,
  setLoadingData: setLoading,
  shouldStopRequests,
};
