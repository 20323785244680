export enum AgentSummary {
  activeAgents = 'activeAgents',
  agentUtilization = 'agentUtilization',
  agentsLoggedIn = 'agentsLoggedIn',
  agentsOnBusy = 'agentsOnBusy',
  agentsOnBusyInChat = 'agentsOnBusyInChat',
  availableEngagementSlots = 'availableEngagementSlots',
  engagementUtilization = 'engagementUtilization',
}

export enum EngagementSummary {
  activeEngagements = 'activeEngagements',
  avgAgentResponseTime = 'avgAgentResponseTime',
  avgEngagementDuration = 'avgEngagementDuration',
  avgInitialAgentResponseTime = 'avgInitialAgentResponseTime',
  engagementsExceedingHandleTimeGoal = 'engagementsExceedingHandleTimeGoal',
  engagementsInLastHour = 'engagementsInLastHour',
  engagementsWithinHandleTimeGoal = 'engagementsWithinHandleTimeGoal',
  escalatedChats = 'escalatedChats',
  maxEngagementDuration = 'maxEngagementDuration',
  nonDispositionEngagements = 'nonDispositionEngagements',
  percentSaleQualified = 'percentSaleQualified',
  saleQualifiedEngagements = 'saleQualifiedEngagements',
}

export enum QueueSummary {
  engagementsInQueue = 'engagementsInQueue',
  percentWithinQueueSLA = 'percentWithinQueueSLA',
  queuedEngagementsExceedingSLA = 'queuedEngagementsExceedingSLA',
  queuedEngagementsWithinSLA = 'queuedEngagementsWithinSLA',
  trendingPercentOfAbandons = 'trendingPercentOfAbandons',
  trendingPercentWithinQueueSLA = 'trendingPercentWithinQueueSLA',
  maxTimeInQueue = 'maxTimeInQueue',
  avgTimeInQueue = 'avgTimeInQueue',
  trendingAvgTimeInQueue = 'trendingAvgTimeInQueue',
}

export type SummaryMetricFields = AgentSummary | EngagementSummary | QueueSummary;
