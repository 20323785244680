import { createSelector } from 'reselect';
import { CurrentView } from '@/interfaces/currentView';
import { StoreState } from '@/stores/store';
import { CustomColumn } from '@/components/organisms/CustomTable/customTable.types';
import ColumnData from '@/enums/ColumnData';
import ViewName from '@/enums/ViewName';

export const selectCurrentView = (state: StoreState): CurrentView => (
  state.currentView
);

export const selectCurrentViewPageType = createSelector(
  selectCurrentView,
  (currentView): string => currentView.pageType,
);

export const selectCurrentViewSubTabName = createSelector(
  selectCurrentView,
  (currentView): string | undefined => currentView.subTabName,
);

export const selectCurrentViewName = createSelector(
  selectCurrentView,
  (currentView): string => currentView.name,
);

export const isCurrentViewFollowUp = createSelector(
  selectCurrentViewName,
  (currentViewName): boolean => currentViewName === ViewName.CONVERSATION,
);

export const selectCurrentViewColumns = createSelector(
  selectCurrentView,
  ({ columns }) => columns.reduce((acc, columnData) => ({
    ...acc,
    [columnData.dataKey]: columnData,
  }), {}),
);

export const hasActiveAlert = createSelector(
  selectCurrentView,
  ({ columns }: CurrentView): boolean => (
    columns.some((column) => (column.visible
        && column.alerts
        && (column.alerts.red?.isAlertActive || column.alerts.yellow?.isAlertActive)))
  ),
);

export const isCurrentViewCustom = createSelector(
  selectCurrentView,
  (currentView): boolean => !!currentView.isCustom,
);

export const selectHiddenColumns = createSelector(
  selectCurrentView,
  ({ columns }: CurrentView): string[] => (
    columns
      .filter((column) => !column.visible)
      .map(({ dataKey }) => dataKey)
  ),
);

export const selectColumnByKey = (columnName: ColumnData) => createSelector(
  selectCurrentView,
  ({ columns }: CurrentView): (CustomColumn | undefined) => (
    columns.find(({ dataKey }) => dataKey === columnName)
  ),
);
