enum Category {
  SUMMARY = 'summary',
  QUEUE = 'queue',
  ENGAGEMENT = 'engagement',
  AGENT = 'agent',
  CONVERSATION = 'conversation',
  BOOKMARK = 'bookmark',
}

export default Category;
