import { createAction } from 'redux-actions';
import {
  SettingCustomViews, CustomViewRequestBody, DeleteViewPayload,
  ResetDefaultViewPayload, ResetCustomViewPayload, CustomViewsData,
} from '@/interfaces/SupervisorView';
import { CustomColumn } from '@/components/organisms/CustomTable/customTable.types';

export enum ViewActionType {
  FETCHING_VIEWS = 'FETCHING_VIEWS',
  APPLY_VIEWS = 'APPLY_VIEWS',
  REQUEST_ADD_CUSTOM_VIEW = 'REQUEST_ADD_CUSTOM_VIEW',
  REQUEST_EDIT_CUSTOM_VIEW = 'REQUEST_EDIT_CUSTOM_VIEW',
  ADD_CUSTOM_VIEW = 'ADD_CUSTOM_VIEW',
  EDIT_CUSTOM_VIEW = 'EDIT_CUSTOM_VIEW',
  DELETE_CUSTOM_VIEW = 'DELETE_CUSTOM_VIEW',
  REQUEST_DELETE_CUSTOM_VIEW = 'REQUEST_DELETE_CUSTOM_VIEW',
  SUPERVISOR_VIEW_REQUEST_IN_PROGRESS = 'SUPERVISOR_VIEW_REQUEST_IN_PROGRESS',
  SUPERVISOR_VIEW_REQUEST_COMPLETED = 'SUPERVISOR_VIEW_REQUEST_COMPLETED',
  RESET_DEFAULT_VIEW = 'RESET_DEFAULT_VIEW',
  RESET_CUSTOM_VIEW = 'RESET_CUSTOM_VIEW',
  UPDATE_VIEW_COLUMNS = 'UPDATE_VIEW_COLUMNS',
  UPDATE_VIEW_COLUMNS_REORDERED = 'UPDATE_VIEW_COLUMNS_UPDATE_VIEW_COLUMNS_REORDERED',
  APPLY_VIEWS_FROM_WEB_STORAGE = 'APPLY_VIEWS_FROM_WEB_STORAGE',
  COMMIT_VIEW_CHANGES = 'COMMIT_VIEW_CHANGES',
  DISCARD_VIEW_CHANGES = 'DISCARD_VIEW_CHANGES',
}

const fetchingViews = createAction<string>(ViewActionType.FETCHING_VIEWS);
const applyViews = createAction<ApplyViewsPayload>(ViewActionType.APPLY_VIEWS);
const requestAddCustomView = createAction<AddViewsPayload>(ViewActionType.REQUEST_ADD_CUSTOM_VIEW);
const requestEditCustomView = createAction<CustomViewRequestBody, string>(
  ViewActionType.REQUEST_EDIT_CUSTOM_VIEW,
  (viewData: CustomViewRequestBody): CustomViewRequestBody => viewData,
  (viewData: CustomViewRequestBody, oldViewName: string) => oldViewName,
);
const addCustomView = createAction<CustomViewRequestBody>(
  ViewActionType.ADD_CUSTOM_VIEW,
);
const editCustomView = createAction<CustomViewRequestBody, string>(
  ViewActionType.EDIT_CUSTOM_VIEW,
  (viewData: CustomViewRequestBody): CustomViewRequestBody => viewData,
  (viewData: CustomViewRequestBody, oldViewName: string) => oldViewName,
);
const deleteCustomView = createAction<DeleteViewPayload>(ViewActionType.DELETE_CUSTOM_VIEW);
const requestDeleteCustomView = createAction<DeleteViewPayload>(
  ViewActionType.REQUEST_DELETE_CUSTOM_VIEW,
);
const requestInProgress = createAction<string>(ViewActionType.SUPERVISOR_VIEW_REQUEST_IN_PROGRESS);
const requestCompleted = createAction<string>(ViewActionType.SUPERVISOR_VIEW_REQUEST_COMPLETED);
const resetDefaultView = createAction<ResetDefaultViewPayload>(ViewActionType.RESET_DEFAULT_VIEW);
const resetCustomView = createAction<ResetCustomViewPayload>(ViewActionType.RESET_CUSTOM_VIEW);
const updateViewColumns = createAction<CustomColumn[]>(ViewActionType.UPDATE_VIEW_COLUMNS);
const updateViewColumnsReordered = createAction<CustomColumn[]>(
  ViewActionType.UPDATE_VIEW_COLUMNS_REORDERED,
);
const commitViewChanges = createAction<CommitChangesPayload>(
  ViewActionType.COMMIT_VIEW_CHANGES,
);
const discardViewChanges = createAction(
  ViewActionType.DISCARD_VIEW_CHANGES,
);
const applyViewsFromStorage = createAction<CustomViewsData>(
  ViewActionType.APPLY_VIEWS_FROM_WEB_STORAGE,
);

export const SupervisorViewActions = {
  fetchingViews,
  applyViews,
  requestAddCustomView,
  requestEditCustomView,
  addCustomView,
  editCustomView,
  deleteCustomView,
  requestDeleteCustomView,
  requestInProgress,
  requestCompleted,
  resetDefaultView,
  resetCustomView,
  updateViewColumns,
  updateViewColumnsReordered,
  applyViewsFromStorage,
  commitViewChanges,
  discardViewChanges,
};

export type SupervisorViewActionsType = typeof SupervisorViewActions;
type ApplyViewsPayload = {
  settingId: string;
  settingViews: SettingCustomViews;
  siteId: string;
  siteName: string;
  name: string;
  unit: string;
};

type AddViewsPayload = {
  requestBody: CustomViewRequestBody;
  tabIndex?: number;
};

export type AddViewPayload = {
  type: typeof ViewActionType.REQUEST_ADD_CUSTOM_VIEW;
  payload: AddViewsPayload;
};

export type EditViewPayload = {
  type: typeof ViewActionType.REQUEST_EDIT_CUSTOM_VIEW;
  payload: CustomViewRequestBody;
  meta?: string;
};

export type CommitChangesPayload = {
  logout?: boolean;
  commitForAllSites?: boolean;
};
