import { createSelector } from 'reselect';
import { RealtimeMetricResponse } from '@/interfaces/responses/RealtimeMetricsResponse';
import { CurrentView } from '@/interfaces/currentView';
import { selectCurrentView } from '@/selectors/currentViewSelector';
import { StoreState } from '@/stores/store';
import { DimensionMetrics } from '@/interfaces/DimensionMetrics';

export const selectFetchedData = (state: StoreState) => state.tableData.fetchedData;
export const selectDataLoadingState = (state: StoreState) => state.tableData.isLoading;
export const selectShouldStopRequests = (state: StoreState) => state.tableData.shouldStopRequests;

export const selectDimensionMetrics = createSelector(
  [selectFetchedData, selectCurrentView],
  (data: RealtimeMetricResponse<DimensionMetrics>, view: CurrentView): DimensionMetrics[] => (
    data[view.dimension] || []
  ),
);
