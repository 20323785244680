import { logMessage } from '@/api/supervisorEngagementClientAPI';
import LogLevel from '@/enums/LogLevel';
import { MessageDetail } from '@/interfaces/Logger';

export function logError(title: string, detail?: MessageDetail): Promise<void> | void {
  return logMessage(title, LogLevel.ERROR, detail);
}

export function logWarn(title: string, detail?: MessageDetail): Promise<void> | void {
  return logMessage(title, LogLevel.WARN, detail);
}

export function logInfo(title: string, detail?: MessageDetail): Promise<void> | void {
  return logMessage(title, LogLevel.INFO, detail);
}

export function logDebug(title: string, detail?: MessageDetail): Promise<void> | void {
  return logMessage(title, LogLevel.DEBUG, detail);
}

export function logConsole(error): void {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function parseAndLogError(error, info?): void {
  let title;
  let detail;

  if (!error) {
    detail = {};
    title = 'An error has occurred but here is no any information about it.';
  } else if (error instanceof TypeError) {
    detail = { stack: error.stack };
    title = error.message;
  } else if (error && error.response instanceof XMLHttpRequest) {
    detail = {
      status: error.status,
      data: error.data,
      url: error.config && error.config.url,
    };
    title = error.statusText;
  } else {
    detail = { stack: info && info.componentStack };
    title = JSON.stringify(error);
  }
  if (window.navigator.onLine) {
    logError(title, detail);
  }
  logConsole(error);
}
