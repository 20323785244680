import { handleActions } from 'redux-actions';
import { Type } from '@/actions/sync/navigationActions';
import { NavigationConfig } from '@/interfaces/NavigationConfig';

const initialState: NavigationConfig = {
  isOpen: false,
};

const updateNavigationDrawerState = (isOpen) => (
  state: NavigationConfig,
): NavigationConfig => ({ ...state, isOpen });

const navigationReducer = handleActions<NavigationConfig, boolean>(
  {
    [Type.OPEN_NAVIGATION_DRAWER]: updateNavigationDrawerState(true),
    [Type.CLOSE_NAVIGATION_DRAWER]: updateNavigationDrawerState(false),
  },
  initialState,
);

export default navigationReducer;
