export enum AttributeTypes {
  AGENT = 'agent',
  RULE = 'br_custom',
  REQUESTED = 'br_agent',
  VISITOR = 'visitor',
}

export enum AttributeKeys {
  AGENT = 'agentAttribute',
  OWNER = 'agentAttribute',
  RULE = 'businessRuleAttribute',
  REQUESTED = 'routingAttribute',
  VISITOR = 'visitorAttribute',
}

export enum AttributeLabels {
  AGENT = 'Agent',
  AGENT_SHORT = ' (AA)',
  OWNER = 'Owner',
  OWNER_SHORT = ' (OA)',
  REQUESTED = 'Requested',
  REQUESTED_SHORT = ' (RA)',
  RULE = 'Business Rule',
  RULE_SHORT = ' (BR)',
  VISITOR = 'Visitor',
  VISITOR_SHORT = ' (VA)',
}
