import { handleActions } from 'redux-actions';
import { Type } from '@/actions/sync/userActions';

const registeredId = '';

const updateRegisteredId = (state, action) => action.payload;

const registeredIdReducer = handleActions<string>(
  {
    [Type.UPDATE_REGISTERED_ID]: updateRegisteredId,
  },
  registeredId,
);

export default registeredIdReducer;
