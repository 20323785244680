import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#1B3D59',
    },
    secondary: {
      main: '#00c3c6',
    },
    background: {
      default: '#fafafa',
      paper: '#fff',
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: 'transparent',
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        nativeInput: {
          '&.MuiInput-input': {
            paddingRight: 40,
          },
          height: 20,
          lineHeight: '20px',
          borderRadius: '4px 4px 0px 0px',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 3px 8px 1px rgba(66, 66, 66, 0.32) !important',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          '&:hover': {
            backgroundColor: 'rgba(27, 61, 89, 0.05)',
          },
          '&.MuiButtonBase-root': {
            fontSize: 14,
          },
          '& > span.MuiTouchRipple-root': {
            display: 'none',
          },
          '&:focus,&:hover': {
            '& > span.MuiTouchRipple-root': {
              display: 'block',
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '& > span.MuiTouchRipple-root': {
            display: 'none',
          },
          '&:focus,&:hover': {
            '& > span.MuiTouchRipple-root': {
              display: 'block',
            },
          },
        },
        containedInherit: {
          '&:hover': {
            backgroundColor: '#f3f5f6',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: '4px 4px 0px 0px',
          '&:hover': {
            '&:before, &:after': {
              borderBottom: 'none !important',
            },
          },
          '&:before, &:after': {
            borderBottom: 'none',
          },
          '&.Mui-error:not(.Mui-disabled), &.Mui-error:hover:not(.Mui-disabled)': {
            borderBottom: '2px solid #d91e1e',
          },
          '&.Mui-focused, & MuiSelect:focus, &:focus': {
            color: '#424242',
            borderBottom: '3px solid #00c3c6',
            backgroundColor: '#FFFFFF',
          },
          '& MuiSelect, &': {
            borderBottom: '1px solid #1b3d59',
            '&:active': {
              borderBottom: '1px solid #424242',
            },
          },
          height: 56,
          '&.Mui-disabled': {
            color: '#d7d7d7',
            borderBottom: '1px solid #d7d7d7',
            backgroundColor: '#f7f7f7',
          },
          '&.Mui-disabled:before': {
            border: 'none',
          },
          '&.Mui-disabled:after': {
            border: 'none',
          },
          '& .MuiInputBase-input': {
            color: '#424242',
            lineHeight: '20px',
            '&.Mui-disabled': {
              color: '#d7d7d7',
            },
          },
          '& .MuiAutocomplete-input': {
            color: '#ffffff',
            '&:focus': {
              color: '#424242',
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: 'rgb(255, 76, 76)',
            fontFamily: 'lato',
            fontWeight: 'normal',
            fontSize: 11,
            lineHeight: '11px',
            width: '213px',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
        shrink: {
          fontSize: 12,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
          color: '#FFFFFF',
          fontFamily: 'Roboto',
          letterSpacing: '0',
          background: '#424242',
          fontWeight: 'normal',
          borderRadius: 4,
          boxShadow: '0px 4px 8px 0px rgba(66, 66, 66, 0.32)',
          lineHeight: '16px',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          zIndex: 20,
          marginRight: '15px',
          marginTop: '16px',
          backgroundColor: '#1B3D59',
          'border-radius': '8px 8px 0 0',
          'text-align': 'center',
          width: '56px',
          height: '44px',
          '&:hover': {
            backgroundColor: '#2c618c',
          },
          'box-shadow': '0px 4px 5px 3px rgba(0,0,0,0.17)',
          cursor: 'pointer',
          '& .MuiBadge-badge': {
            height: '29px',
            width: '29px',
            borderRadius: '15px',
          },
          '& .MuiPaper-rounded': {
            width: '56px',
            borderRadius: '0px',
            background: 'transparent',
          },
          '& .MuiBadge-colorError': {
            backgroundColor: '#d91e1e',
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '@media (min-width: 600px)': {
            left: 90,
          },
        },
      },
    },
  },
});
