import {
  call, spawn, all, delay,
} from 'redux-saga/effects';
import { logError } from '@/services/loggerService';
import userSaga from '@/sagas/userSaga';
import webStorageSaga from '@/sagas/webStorageSaga';
import supervisorViewSaga from '@/sagas/supervisorViewSaga';
import customScriptSaga from '@/sagas/customScriptSaga';
import rotationSaga from '@/sagas/rotationSaga';
import { watchSummary } from '@/sagas/summarySaga';
import { watchChat } from '@/sagas/chatManagerSaga';
import agentSaga from '@/sagas/agentSaga';
import oauth2Saga from '@/sagas/oauth2Saga';
import selectedUnitsSaga from '@/sagas/selectedUnitsSaga';
import applicationSettingsSaga from '@/sagas/applicationSettingsSaga';
import initApplicationSaga from '@/sagas/initApplicationSaga';
import conversationSaga from '@/sagas/conversationManagerSaga';
import viewsSaga from '@/sagas/viewsSaga';
import currentViewSaga from '@/sagas/currentViewSaga';
import { watchRTMetrics } from './realtimeMetricsSaga';

export default function* rootSaga() {
  const sagas = [
    initApplicationSaga,
    oauth2Saga,
    webStorageSaga,
    userSaga,
    agentSaga,
    applicationSettingsSaga,
    supervisorViewSaga,
    customScriptSaga,
    rotationSaga,
    selectedUnitsSaga,
    watchSummary,
    watchChat,
    conversationSaga,
    viewsSaga,
    watchRTMetrics,
    currentViewSaga,
  ];

  yield all(sagas.map((saga) => spawn(function* callSagas() {
    while (true) {
      try {
        yield call(saga);
      } catch (error) {
        logError('Saga error, the saga will be restarted');
      }
      yield delay(1000); // Workaround to avoid infinite error loops
    }
  })));
}
