export enum MessageType {
  AGENT_DISCONNECTS = 'AGENT_DISCONNECTS',
  AGENT_RECONNECTS = 'AGENT_RECONNECTS',
  AGENT_ENDS_CHAT = 'AGENT_ENDS_CHAT',
  AGENT_INTERNAL_MESSAGE = 'AGENT_INTERNAL_MESSAGE',
  AGENT_MESSAGE = 'AGENT_MESSAGE',
  AUTOMATON_MESSAGE = 'AUTOMATON_MESSAGE',
  CHAT_CUSTOMER_CHAT_LINE_SENT = 'chat.customerChatlineSent',
  CHAT_AGENT_CHAT_LINE_SENT = 'chat.agentChatlineSent',
  CHAT_AGENT_FOLLOW_UP = 'chat.agentFollowUp',
  CLIENT_ENDS_CHAT = 'CLIENT_ENDS_CHAT',
  CLIENT_MESSAGE = 'CLIENT_MESSAGE',
  ILLEGAL_WORD = 'ILLEGAL_WORD',
  MARKER_PAGE = 'MARKER_PAGE',
  MARKER_URL = 'MARKER_URL',
  NOTIFY_CLIENT = 'NOTIFY_CLIENT',
  UTILITY_MESSAGE = 'UTILITY_MESSAGE',
  AUTOMATON_CUSTOMER_RESPONDED = 'AUTOMATON_CUSTOMER_RESPONDED',
}

export const internalMessageTypes: string[] = [
  MessageType.UTILITY_MESSAGE,
  MessageType.AGENT_INTERNAL_MESSAGE,
];
