import { createSelector } from 'reselect';
import { Chat } from '@/interfaces/Chat';
import SelectedUnit from '@/enums/SelectedUnit';
import { StoreState } from '@/stores/store';
import { AgentGroup } from '@/interfaces/AgentGroup';
import { BusinessUnit } from '@/interfaces/BusinessUnit';
import { Site } from '@/interfaces/Site';
import { ApplicationSettings, SelectedBuAGIds } from '@/interfaces/ApplicationSettings';
import { selectUserRoles, selectUserSites } from './userDataSelector';

const DEFAULT_REQUEST_INTERVAL = 2000;
const DEGRADED_MODE_ADDITIONAL_DELAY = 20000;

export const selectApplicationSettings = (state: StoreState): ApplicationSettings => (
  state.applicationSettings
);

export const selectSelectedSite = (state: StoreState): Site => (
  state.applicationSettings.selectedSite
);

export const selectDefaultSelectedBusinessUnit = (state: StoreState): BusinessUnit => (
  state.applicationSettings.selectedSite.businessUnits[0]
);

export const selectShouldApplyLastSettings = (state: StoreState): boolean => (
  state.applicationSettings.shouldApplyLastSettings
);

export const selectAllBuIds = (state: StoreState): SelectedBuAGIds => (
  state.applicationSettings.selectedBusinessUnitIds
);

export const selectAllAgIds = (state: StoreState): SelectedBuAGIds => (
  state.applicationSettings.selectedAgentGroupIds
);

export const selectBusinessUnitIds = createSelector(
  [selectSelectedSite, selectAllBuIds],
  ({ id }, selectedBusinessUnitIds) => selectedBusinessUnitIds[id] || [],
);

export const selectBusinessUnits = createSelector(
  [selectSelectedSite, selectBusinessUnitIds],
  ({ businessUnits = [] }, buIds): BusinessUnit[] => businessUnits
    .filter((bu) => buIds.includes(bu.id)),
);

export const selectAgentGroupIds = createSelector(
  [selectSelectedSite, selectAllAgIds],
  ({ id }, selectedAgentGroupIds) => selectedAgentGroupIds[id] || [],
);

export const selectAgentGroups = createSelector(
  [selectSelectedSite, selectAgentGroupIds],
  ({ agentGroups = [] }, agIds): AgentGroup[] => agentGroups.filter((bu) => agIds.includes(bu.id)),
);

export const selectUnit = (state: StoreState): SelectedUnit => (
  state.applicationSettings.selectedUnit
);

export const selectSubTabIndex = (state: StoreState): number => (
  state.applicationSettings.subTabIndex
);

export const selectPortalSettingsChanged = (state: StoreState): boolean => (
  state.applicationSettings.portalSettingsChanged
);

export const selectSearchValue = (state: StoreState): string => (
  state.applicationSettings.searchValue
);

export const selectIsDegradedModeBannerOpen = (state: StoreState): boolean => (
  state.applicationSettings.degradedServiceMode.isBannerOpen
);

export const selectDegradedMode = (state: StoreState): boolean => (
  state.applicationSettings.degradedServiceMode.isOn
);

export const selectIsLowResolution = (state: StoreState): boolean => (
  state.applicationSettings.isLowResolution
);

export const buildBuAndAgParams = createSelector(
  [selectBusinessUnits, selectAgentGroups, selectUnit],
  (
    businessUnits: BusinessUnit[],
    agentGroups: AgentGroup[],
    unit: SelectedUnit,
  ): string => (unit === SelectedUnit.BU
    ? `&bu=${businessUnits.map((bu) => bu.id).join(',')}`
    : `&ag=${agentGroups.map((ag) => ag.id).join(',')}`),
);

export const getCurrentSiteId = createSelector(
  selectSelectedSite,
  (site): string => site.id,
);

export const selectCurrentSite = createSelector(
  [selectUserSites, getCurrentSiteId],
  (sites: Site[], currentSiteId: string): Site | undefined => (
    sites.find((site) => site.id === currentSiteId)
  ),
);

export const checkIfCanChatWithAgentAtConversation = (
  activeConversation,
) => createSelector(selectSelectedSite,
  (site): boolean | undefined => {
    const businessUnit: BusinessUnit | undefined = site && site.businessUnits
      && site.businessUnits.find((bu) => bu.id === activeConversation.owningBusinessUnit);

    const agentGroup: AgentGroup | undefined = site && site.agentGroups
      && site.agentGroups.find((ag) => ag.id === activeConversation.owningAgentGroup);

    return agentGroup
      ? agentGroup?.setting.liCanChatWithAgents
      : businessUnit?.setting.liCanChatWithAgents;
  });

export const getRequestInterval = createSelector(
  selectSelectedSite,
  (site): number => (
    site && site.setting
      ? site.setting.requestInterval * 1000
      : DEFAULT_REQUEST_INTERVAL
  ),
);

export const selectRTMRequestInterval = createSelector(
  [getRequestInterval, selectDegradedMode],
  (requestInterval: number, degradedState: boolean): number => (
    degradedState
      ? requestInterval + DEGRADED_MODE_ADDITIONAL_DELAY
      : requestInterval
  ),
);

export const getCurrentSetting = createSelector(
  [selectBusinessUnits, selectAgentGroups, selectUnit],
  (
    businessUnits: BusinessUnit[],
    agentGroups: AgentGroup[],
    unit: SelectedUnit,
  ): BusinessUnit[] | AgentGroup[] => (unit === SelectedUnit.BU
    ? businessUnits
    : agentGroups),
);

export const isSingleSettingMode = createSelector(
  [getCurrentSetting],
  (currentSettings: BusinessUnit[] | AgentGroup[]): boolean => (
    currentSettings.length === 1
  ),
);

export const getCurrentSettingId = createSelector(
  [selectBusinessUnits, selectAgentGroups, selectUnit],
  (
    businessUnits: BusinessUnit[],
    agentGroups: AgentGroup[],
    unit: SelectedUnit,
  ): string => (unit === SelectedUnit.BU
    ? businessUnits.map((bu) => bu.settingId).join()
    : agentGroups.map((ag) => ag.settingId).join()),
);

export const getIsColumnSearchVisible = (state: StoreState): boolean => (
  state.applicationSettings.isColumnSearchVisible
);

export const getShowTranscriptDetails = createSelector(
  selectApplicationSettings,
  (applicationSettings): boolean => (
    applicationSettings.showTranscriptDetails
  ),
);

export const isAdministrator = createSelector(
  selectUserRoles,
  (roles: string[]): boolean => (
    roles && roles.length > 0 && roles.indexOf('administrator') > -1
  ),
);

export const checkIfCanChatWithAgent = (chat: Chat) => createSelector(
  [selectSelectedSite],
  (site: Site): boolean => {
    const { agentGroups } = chat;
    if (agentGroups) {
      for (let i = 0; i < agentGroups.length; i += 1) {
        const ag = agentGroups[i];
        const agentGroup = site.agentGroups && site.agentGroups
          .find((siteAgentGroup) => siteAgentGroup.id === ag.agentGroupID.toString());
        if (agentGroup) {
          return agentGroup.setting.liCanChatWithAgents;
        }
      }
    }

    const { businessUnits } = chat;
    if (businessUnits) {
      for (let i = 0; i < businessUnits.length; i += 1) {
        const bu = businessUnits[i];
        const businessUnit = site.businessUnits && site.businessUnits
          .find((siteBusinessUnit) => siteBusinessUnit.id === bu.businessUnitID.toString());
        if (businessUnit) {
          return businessUnit.setting.liCanChatWithAgents;
        }
      }
    }

    return site.setting?.liCanChatWithAgents;
  },
);

export const hasNoTranscriptRole = createSelector(
  selectUserRoles,
  (roles: string[]): boolean => (
    roles && roles.length > 0 && roles.indexOf('noTranscript') > -1
  ),
);

export const getSortedCurrentSettingId = createSelector(
  [getCurrentSettingId],
  (settingId: string): string => settingId.split(',').sort().join(),
);
