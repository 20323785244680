import { Message } from '@/interfaces/Chat';

const toConversationTranscript = (
  transcript: Message[],
) => {
  let chatID;
  const chatsMap = {};
  transcript.forEach((element) => {
    if (element.senderType === 'TRANSCRIPT_SUB_HEADER') {
      chatID = element.content.slice(9);
    } else {
      chatsMap[chatID] = chatsMap[chatID] || [];
      chatsMap[chatID].push(element);
    }
  });
  return chatsMap;
};

export default toConversationTranscript;
