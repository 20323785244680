import { makeStyles, createStyles } from '@mui/styles';

export default makeStyles(() => createStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: '#12293D',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 400,
    minHeight: 400,
  },

  logoWrapper: {
    position: 'absolute',
  },

  spinner: {
    width: 350,
    height: 350,
    borderRadius: '50%',
    border: '4px solid transparent',
    borderTopColor: '#00C3C6',
    borderRightColor: '#00C3C6',
    boxShadow: '0px 0px 12px 6px rgba(0, 0, 0, 0.3)',

    animation: '$spin 2s linear infinite', /* Chrome, Firefox 16+, IE 10+, Opera */
  },
  spinnerStart: {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    background: '#00C3C6',
    margin: '292px',
  },

  '@keyframes spin': {
    '0%': {
      WebkitTransform: 'rotate(0deg)', /* Chrome, Opera 15+, Safari 3.1+ */
      MsTransform: 'rotate(0deg)', /* IE 9 */
      transform: 'rotate(0deg)', /* Firefox 16+, IE 10+, Opera */
    },
    '100%': {
      WebkitTransform: 'rotate(360deg)', /* Chrome, Opera 15+, Safari 3.1+ */
      MsTransform: 'rotate(360deg)', /* IE 9 */
      transform: 'rotate(360deg)', /* Firefox 16+, IE 10+, Opera */
    },
  },
}));
