import { createAction } from 'redux-actions';
import { TokenPayload } from '@/interfaces/TokenPayload';
import { ApplicationSettings } from '@/interfaces/ApplicationSettings';
import { CustomViewsData } from '@/interfaces/SupervisorView';

export enum WebStorageActionType {
  INIT_WEB_STORAGE = 'INIT_WEB_STORAGE',
  UPDATE_WEB_STORAGE = 'UPDATE_WEB_STORAGE',
  CLEAR_WEB_STORAGE = 'CLEAR_WEB_STORAGE',
}

const initWebStorage = createAction(WebStorageActionType.INIT_WEB_STORAGE);
const updateWebStorage = createAction<UpdateWebStoragePayload>(
  WebStorageActionType.UPDATE_WEB_STORAGE,
);
const clearWebStorage = createAction<ClearWebStoragePayload>(
  WebStorageActionType.CLEAR_WEB_STORAGE,
);

export type UpdateWebStoragePayload = {
  key: string | null,
  value: ApplicationSettings | TokenPayload | CustomViewsData | string,
  isSession?: boolean,
};

export type ClearWebStoragePayload = {
  key: string | null,
  isSession?: boolean,
};

export const WebStorageActions = { initWebStorage, updateWebStorage, clearWebStorage };
