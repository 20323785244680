import { createAction } from 'redux-actions';
import { User } from '@/interfaces/User';

export enum Type {
  UPDATE_USER = 'UPDATE_USER',
  NOTIFY_USER_ACTIVITY = 'NOTIFY_USER_ACTIVITY',
  SHOW_LOGOUT_NOTIFICATION = 'SHOW_LOGOUT_NOTIFICATION',
  PIN_CHAT = 'PIN_CHAT',
  UNPIN_CHAT = 'UNPIN_CHAT',
  PIN_CONVERSATION = 'PIN_CONVERSATION',
  UNPIN_CONVERSATION = 'UNPIN_CONVERSATION',
  UPDATE_REGISTERED_ID = 'UPDATE_REGISTERED_ID',
  REQUEST_UPDATE_REGISTERED_ID = 'REQUEST_UPDATE_REGISTERED_ID',
}

const updateUser = createAction<User>(Type.UPDATE_USER);
const notifyUserActivity = createAction(Type.NOTIFY_USER_ACTIVITY);
const showLogoutNotification = createAction<boolean>(Type.SHOW_LOGOUT_NOTIFICATION);
const updateRegisteredId = createAction<string>(Type.UPDATE_REGISTERED_ID);
const requestUpdateRegisteredId = createAction(Type.REQUEST_UPDATE_REGISTERED_ID);
const pinChat = createAction<string>(Type.PIN_CHAT);
const unpinChat = createAction<string>(Type.UNPIN_CHAT);
const pinConversation = createAction<string>(Type.PIN_CONVERSATION);
const unpinConversation = createAction<string>(Type.UNPIN_CONVERSATION);

export const UserActions = {
  updateUser,
  notifyUserActivity,
  showLogoutNotification,
};

export const BookmarkedActions = {
  pinChat,
  unpinChat,
  pinConversation,
  unpinConversation,
};

export const RegisteredIdActions = {
  updateRegisteredId,
  requestUpdateRegisteredId,
};

export type UserActionsType = typeof UserActions;
export type BookmarkedActionsType = typeof BookmarkedActions;
export type RegisteredIdActionsType = typeof RegisteredIdActions;
