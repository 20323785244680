import { createAction } from 'redux-actions';
import {
  QueueSummary,
  EngagementSummary,
  AgentSummary,
} from '@/interfaces/Summary';

export enum Type {
  KPI_OPEN = 'KPI_OPEN',
  KPI_CLOSE = 'KPI_CLOSE',
  SUMMARY_OPEN = 'SUMMARY_OPEN',
  SUMMARY_CLOSE = 'SUMMARY_CLOSED',
  UPDATE_QUEUE = 'UPDATE_QUEUE',
  UPDATE_ENGAGEMENT = 'UPDATE_ENGAGEMENT',
  UPDATE_AGENT = 'UPDATE_AGENT',
}

const kpiOpen = createAction(Type.KPI_OPEN);
const kpiClose = createAction(Type.KPI_CLOSE);
const summaryOpen = createAction(Type.SUMMARY_OPEN);
const summaryClose = createAction(Type.SUMMARY_CLOSE);
const updateQueue = createAction<QueueSummary>(Type.UPDATE_QUEUE);
const updateEngagement = createAction<EngagementSummary>(Type.UPDATE_ENGAGEMENT);
const updateAgent = createAction<AgentSummary>(Type.UPDATE_AGENT);

export const SummaryActions = {
  kpiOpen,
  kpiClose,
  summaryOpen,
  summaryClose,
  updateQueue,
  updateEngagement,
  updateAgent,
};

export type SummaryActionsType = typeof SummaryActions;
