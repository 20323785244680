import { createAction } from 'redux-actions';
import { Filter, CustomScript } from '@/interfaces/CustomScript';

export enum CustomScriptActionType {
  REQUEST_ADD_FILTER = 'REQUEST_ADD_FILTER',
  ADD_FILTER = 'ADD_FILTER',
  REQUEST_EDIT_FILTER = 'REQUEST_EDIT_FILTER',
  EDIT_FILTER = 'EDIT_FILTER',
  DELETE_FILTER = 'DELETE_FILTER',
  REQUEST_DELETE_FILTER = 'REQUEST_DELETE_FILTER',
  CUSTOM_SCRIPT_REQUEST_IN_PROGRESS = 'CUSTOM_SCRIPT_REQUEST_IN_PROGRESS',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
  UPDATE_LANGUAGE = 'UPDATE_LANGUAGE',
}
const deleteFilter = createAction<Filter[]>(CustomScriptActionType.DELETE_FILTER);
const requestDeleteFilter = createAction<{ name: string; tableId: string; }>(
  CustomScriptActionType.REQUEST_DELETE_FILTER,
);
const requestInProgress = createAction<boolean>(
  CustomScriptActionType.CUSTOM_SCRIPT_REQUEST_IN_PROGRESS,
);
const updateFilters = createAction<Filter[]>(CustomScriptActionType.UPDATE_FILTERS);
const updateLanguage = createAction<CustomScript>(CustomScriptActionType.UPDATE_LANGUAGE);
const addFilterRequest = createAction<Filter>(CustomScriptActionType.REQUEST_ADD_FILTER);
const addReduxFilter = createAction<Filter>(CustomScriptActionType.ADD_FILTER);
const editFilter = createAction<Filter[]>(CustomScriptActionType.EDIT_FILTER);
const requestEditFilter = createAction<Filter, string>(
  CustomScriptActionType.REQUEST_EDIT_FILTER,
  (filterData: Filter): Filter => filterData,
  (filterData: Filter, oldName: string): string => oldName,
);

export const CustomScriptActions = {
  deleteFilter,
  requestDeleteFilter,
  requestInProgress,
  updateFilters,
  updateLanguage,
  addFilterRequest,
  addReduxFilter,
  requestEditFilter,
  editFilter,
};
export type CustomScriptActionsType = typeof CustomScriptActions;
