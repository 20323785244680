import { createSelector } from 'reselect';
import ColumnData from '@/enums/ColumnData';
import { isAdministrator, selectCurrentSite } from '@/selectors/applicationSettingsSelector';
import { CustomViewsData, CustomView, ViewColumn } from '@/interfaces/SupervisorView';
import { StoreState } from '@/stores/store';
import Category from '@/enums/Category';
import { IncludeExcludeTabs } from '@/constants/categoryViewMap';
import { AttributesData, ViewAttributesData } from '@/interfaces/AttributesData';
import { Site } from '@/interfaces/Site';
import buildViewAttributesData, { buildAttributesForCurrentView } from '@/services/attributeService';
import { CurrentView } from '@/interfaces/currentView';
import { DimensionType } from '../interfaces/SupervisorView';
import { getCurrentSettingId } from './applicationSettingsSelector';
import { selectCurrentView } from './currentViewSelector';

const selectViewsData = (state: StoreState): CustomViewsData => state.supervisorViews;

export const isSupervisorViewReceived = createSelector(
  [selectViewsData, getCurrentSettingId],
  (viewData, settingId): boolean => (!!viewData[settingId]),
);

export const isSupervisorViewLoading = createSelector(
  [selectViewsData, getCurrentSettingId],
  (viewData, settingId): boolean => (!!viewData[settingId] && !!viewData[settingId].isFetching),
);

export const selectSupervisorViewData = createSelector(
  [selectViewsData, getCurrentSettingId],
  (viewData, settingId) => viewData[settingId],
);

export const selectViewByViewName = (category, viewName) => createSelector(
  [selectViewsData, getCurrentSettingId],
  (viewData, settingId): CustomView | undefined => {
    const settingViews = viewData[settingId] && viewData[settingId].settingViews;
    const categoryViews = settingViews
      ? settingViews[category] || []
      : [];

    return categoryViews.length > 0
      ? categoryViews
        .filter((view) => !view.isDeleted)
        .find((view) => view.name === viewName)
      : undefined;
  },
);

const getCategoryViews = (supervisorViews, settingId, categoryName, includeDeletedViews) => (
  supervisorViews[settingId]
  && supervisorViews[settingId].settingViews
  && supervisorViews[settingId].settingViews[categoryName]
  && supervisorViews[settingId].settingViews[categoryName]
    .filter((view) => includeDeletedViews || !view.isDeleted))
  || null;

export const selectCategoryViews = (categoryName, includeDeletedViews = false) => createSelector(
  [
    selectViewsData,
    getCurrentSettingId,
  ],
  (supervisorViews, settingId) => (
    getCategoryViews(supervisorViews, settingId, categoryName, includeDeletedViews)
  ),
);

export const selectDefaultViewsData = (category) => createSelector(
  [selectCategoryViews(category, true), isAdministrator],
  (views: CustomView[], isAdmin): {} | null => (
    views && views
      .filter((view) => !view.attributesHeader)
      .reduce((acc, view) => ({
        ...acc,
        [view.name]: { ...view, isEdited: isAdmin && view.isEdited },
      }), {})
  ),
);

export const selectCategoryCustomViews = (category) => createSelector(
  selectCategoryViews(category),
  (views: CustomView[]): CustomView[] | null => (
    views && views.filter(
      (view) => view.attributesHeader && view.attributesHeader.length > 0,
    )
  ),
);

export const selectCustomViewNames = createSelector(
  [selectCategoryCustomViews(Category.QUEUE), selectCategoryCustomViews(Category.ENGAGEMENT)],
  (queueCustomViews, engagementCustomViews) => {
    const queueViews: CustomView[] = queueCustomViews || [];
    const engagementViews: CustomView[] = engagementCustomViews || [];
    const views: CustomView[] = queueViews.concat(engagementViews);
    return views.map((view) => view.name.toLowerCase());
  },
);

export const getViewColumns = (categoryName: string, viewName: string) => createSelector(
  selectCategoryViews(categoryName),
  (views): ViewColumn[] => {
    const view = views ? views.find((item) => item.name === viewName) : undefined;
    return view && view.columns;
  },
);

export const selectViewCustomColumns = (categoryName: string, viewName: string) => createSelector(
  getViewColumns(categoryName, viewName),
  (columns): ViewColumn[] => columns && columns.filter((viewColumn) => (
    !Object.keys(ColumnData).find((dataKey) => dataKey === viewColumn.data)
  )),
);

export const getViewOperations = (categoryName: string, viewName: string) => createSelector(
  selectCategoryViews(categoryName),
  (views) => {
    const view = views ? views.find((item) => item.name === viewName) : undefined;
    return view && view.operations ? view.operations : null;
  },
);

export const getViewLoadingStatus = createSelector(
  [selectViewsData, getCurrentSettingId],
  (viewData, settingId): boolean => !!(viewData[settingId] && viewData[settingId].isFetching),
);

export const selectCurrentSiteAttributes = createSelector(
  selectCurrentSite,
  (currentSite: Site | undefined): AttributesData | undefined => (
    currentSite && currentSite.attributeNames
  ),
);

export const getViewAttributesData = (category: Category) => createSelector(
  selectCurrentSiteAttributes,
  (attributesData: AttributesData | undefined): ViewAttributesData[] => (attributesData
    ? buildViewAttributesData(category, attributesData)
    : []),
);

export const getAttributeColumnLabel = (
  category: Category,
  dimension: DimensionType,
) => createSelector(
  getViewAttributesData(category),
  (data: ViewAttributesData[]): string => {
    const [key, name] = dimension.split('.');
    const attribute = data.find((item) => item.key === key);
    return attribute ? `${name}${attribute.shortLabel}` : '';
  },
);

export const selectAttributesForCurrentView = () => createSelector(
  [selectCurrentSiteAttributes, selectCurrentView],
  (attributesData: AttributesData | undefined, currentView: CurrentView): ViewAttributesData[] => (
    attributesData
      ? buildAttributesForCurrentView(attributesData, currentView)
      : []
  ),
);

export const getCommitChangesStatus = createSelector(
  [selectViewsData, getCurrentSettingId, isAdministrator],
  (viewData, settingId, isAdmin): boolean => (isAdmin
    && !!viewData[settingId]
    && !!viewData[settingId].isEdited),
);

export const selectCaterogywiseEditedStatus = createSelector(
  [selectSupervisorViewData, isAdministrator],
  (supervisorData, isAdmin): { [key: string]: boolean } => (
    Object.entries(supervisorData?.settingViews || {})
      .reduce((acc, [categoryName, views]) => {
        const tabsData = IncludeExcludeTabs[categoryName] || [];
        const otherCategoryData = {};

        const isEdited: boolean = !!views.filter((view) => {
          if (tabsData[view.name]) {
            otherCategoryData[tabsData[view.name]] = otherCategoryData[tabsData[view.name]]
              || view.isEdited;
          }
          return !tabsData[view.name] && (view.isEdited || view.isDeleted);
        }).length;

        return ({
          ...acc,
          [Category.BOOKMARK]: isAdmin && (acc[Category.BOOKMARK]
            || otherCategoryData[Category.BOOKMARK]),
          [Category.AGENT]: isAdmin && (acc[Category.AGENT] || otherCategoryData[Category.AGENT]),
          [categoryName]: isAdmin && (acc[categoryName] || isEdited),
        });
      }, {})
  ),
);

export const selectAllUnsavedChanges = createSelector(
  [selectViewsData, isAdministrator],
  (supervisorViews, isAdmin) => (Object.entries(supervisorViews)
    .reduce((acc, [settingId, supervisorViewsData]) => (isAdmin && supervisorViewsData.isEdited
      ? { ...acc, [settingId]: supervisorViewsData }
      : acc), {})
  ),
);
