import {
  addMilliseconds, format, intervalToDuration, isValid, parse,
} from 'date-fns';
import AlertType from '@/enums/AlertType';
import CellValueFormat from '@/enums/CellValueFormat';

const validateTimeString = (timeStr: string): boolean => {
  const [hh, mm, ss] = timeStr.split(':');
  if (+hh < 10) {
    return hh.startsWith('0')
      && Number.isInteger(+hh) && isValid(parse(`${mm}:${ss}`, 'mm:ss', new Date()));
  }
  return Number.isInteger(+hh) && isValid(parse(`${mm}:${ss}`, 'mm:ss', new Date()));
};

export default (alertType: AlertType, timeOrDate: string): number => {
  if (
    alertType === AlertType.TIME
    && validateTimeString(timeOrDate)
  ) {
    const [hh, mm, ss] = timeOrDate.split(':');
    return (+hh * 60 * 60 + +mm * 60 + +ss) * 1000;
  }

  if (alertType === AlertType.DATE_TIME) {
    const dateTime = parse(timeOrDate, CellValueFormat.DATE_TIME_FORMAT, new Date(Date.now()));
    if (isValid(dateTime)) {
      return dateTime.getTime() - (dateTime.getTimezoneOffset() * 60 * 1000);
    }
  }
  return 0;
};

export function getUTCTimestampFromLocal(): number {
  const localTime = format(new Date(), CellValueFormat.DATE_TIME_FORMAT);
  const utc = parse(localTime, CellValueFormat.DATE_TIME_FORMAT, new Date());
  return utc.getTime() - utc.getTimezoneOffset() * 60 * 1000;
}

export function getLocalTimestampFromUTC(timestamp: number): number {
  if (isValid(timestamp)) {
    return new Date(timestamp).getTime() + new Date().getTimezoneOffset() * 60 * 1000;
  }
  return 0;
}

export function getUTCTimestampFromDate(date: Date): number {
  return isValid(date) ? date.getTime() - date.getTimezoneOffset() * 60 * 1000 : 0;
}

export function gethhmmssFromDuration(duration: number | string | undefined): string {
  let interval = duration || 0;
  if (typeof interval === 'string') {
    if (Number.isInteger(+interval)) {
      interval = +interval;
    } else if (validateTimeString(interval)) {
      const [hh, mm, ss] = interval.split(':');
      interval = (+hh * 60 * 60 + +mm * 60 + +ss) * 1000;
    } else {
      interval = 0;
    }
  }
  // to check if milliseconds is greater than 500 then increment 1 second
  const ms = interval % 1000;
  if (ms > 500) {
    interval += 1000;
  }

  const startDate = new Date();
  const {
    months, days, hours, minutes, seconds,
  } = intervalToDuration({
    start: startDate,
    end: addMilliseconds(startDate, interval),
  });
  const d = ((months || 0) * 31) + (days || 0);
  const hrs = d * 24 + (hours || 0);
  const { hour, min, sec } = {
    hour: hrs ? `${hrs}`.padStart(2, '0') : '00',
    min: minutes ? `${minutes}`.padStart(2, '0') : '00',
    sec: seconds ? `${seconds}`.padStart(2, '0') : '00',
  };

  return `${hour}:${min}:${sec}`;
}
