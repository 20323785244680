import { createAction } from 'redux-actions';

enum Type {
  ROTATION_INTERVAL_CHANGED = 'ROTATION_INTERVAL_CHANGED',
  START_ROTATION = 'START_ROTATION',
  STOP_ROTATION = 'STOP_ROTATION',
  SET_ROTATION_PAUSE = 'SET_ROTATION_PAUSE',
  REQUEST_ROTATION_PAUSE = 'REQUEST_ROTATION_PAUSE',
  SET__MANUAL_ROTATION_PAUSE = 'SET__MANUAL_ROTATION_PAUSE',
  UPDATE_ROTATION_SETTINGS = 'UPDATE_ROTATION_SETTINGS',
  NEXT_ROTATION_SETTINGS = 'NEXT_ROTATION_SETTING',
  PREVIOUS_ROTATION_SETTINGS = 'PREVIOUS_ROTATION_SETTINGS',
  AUTO_ROTATE_SETTINGS = 'AUTO_ROTATE_SETTINGS',
}

const changeRotationInterval = createAction<number>(Type.ROTATION_INTERVAL_CHANGED);
const startRotation = createAction<void>(Type.START_ROTATION);
const autoRotation = createAction<void>(Type.AUTO_ROTATE_SETTINGS);
const stopRotation = createAction<void>(Type.STOP_ROTATION);
const setRotationPause = createAction<boolean>(Type.SET_ROTATION_PAUSE);
const requestRotationPause = createAction<void>(Type.REQUEST_ROTATION_PAUSE);
const setManualRotationPause = createAction<boolean>(Type.SET__MANUAL_ROTATION_PAUSE);
const updateRotationSettings = createAction<string[]>(Type.UPDATE_ROTATION_SETTINGS);
const nextRotationSettings = createAction<void>(Type.NEXT_ROTATION_SETTINGS);
const previousRotationSettings = createAction<void>(Type.PREVIOUS_ROTATION_SETTINGS);

export const RotationActions = {
  changeRotationInterval,
  startRotation,
  autoRotation,
  stopRotation,
  updateRotationSettings,
  setRotationPause,
  setManualRotationPause,
  nextRotationSettings,
  previousRotationSettings,
  requestRotationPause,
};

export type RotationActionsType = typeof RotationActions;

export default Type;
