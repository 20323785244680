import ColumnData from '@/enums/ColumnData';
import ViewName from '@/enums/ViewName';
import SortDirection from '@/enums/SortDirection';

const ENGAGEMENT_DURATION_DEFAULT_SORT = {
  sortBy: [ColumnData.ENGAGEMENT_DURATION],
  sortDirection: {
    [ColumnData.ENGAGEMENT_DURATION]: SortDirection.DESC,
  },
};

export const DEFAULT_SORT_OPTIONS = {
  [ViewName.ENGAGEMENT_CHATS]: ENGAGEMENT_DURATION_DEFAULT_SORT,
  [ViewName.BOOKMARK_CHATS]: ENGAGEMENT_DURATION_DEFAULT_SORT,
  [ViewName.ENGAGEMENT_ESCALATED]: ENGAGEMENT_DURATION_DEFAULT_SORT,
  [ViewName.QUEUE_CHATS]: {
    sortBy: [ColumnData.SLA, ColumnData.POSITION],
    sortDirection: {
      [ColumnData.SLA]: SortDirection.DESC,
      [ColumnData.POSITION]: SortDirection.ASC,
    },
  },
};

export default DEFAULT_SORT_OPTIONS;
