import { ELEMENT_ID } from '@/constants/viewConstants';
import Dimension from '@/enums/Dimension';
import ColumnData from '@/enums/ColumnData';
import { Attributes } from '@/constants/attributes';
import { AttributeTypes } from '@/enums/Attributes';

class CompatibilitySupV2 {
  formatField = (field, name, dimension = '') => {
    const simpleExtractor = (attribute: string | number) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const value = names[field][attribute];
      return value && typeof value === 'string' ? value : attribute;
    };

    const names = {
      category: {
        chat: ELEMENT_ID.CATEGORY.ID.ENGAGEMENT,
        extractName: simpleExtractor,
      },
      dimension: {
        agent_serviceability: Dimension.AGENTS,
        business_rules: Dimension.BUSINESS_RULES,
        chat_requests: Dimension.ENGAGEMENTS,
        chats: Dimension.ENGAGEMENTS,
        brAttr: Attributes[AttributeTypes.RULE][0].data,
        'owner.attr': Attributes[AttributeTypes.AGENT][0].data,
        visitorAttr: Attributes[AttributeTypes.VISITOR][0].data,
        extractName(attr) {
          const splitted = attr.split('.');
          if (splitted.length > 2) {
            splitted.splice(0, 1);
            const value = splitted.splice(-1, 1);
            const replacedName = this[splitted.join('.')];
            return replacedName ? `${replacedName}.${value}` : attr;
          }
          return simpleExtractor.call(this, attr);
        },
      },
      column: {
        value: dimension,
        activeString: ColumnData.ENGAGEMENT_ACTIVE,
        attribute_brAttr: Attributes[AttributeTypes.RULE][0].data,
        'attribute_owner.skills': Attributes[AttributeTypes.AGENT][0].data,
        attribute_visitorAttr: Attributes[AttributeTypes.VISITOR][0].data,
        attribute_requestedAttributes: Attributes[AttributeTypes.REQUESTED][0].data,
        attribute_skills: Attributes[AttributeTypes.AGENT][0].data,
        agentGroupID: ColumnData.AGENT_GROUP_NAME,
        escalatedAgents: ColumnData.AGENTS,
        escalatedAgentFullNames: ColumnData.AGENT_FULL_NAMES,
        escalatedCurrentPageMarker: ColumnData.CURRENT_PAGE_MARKER,
        avgWaitTime: ColumnData.AVG_MESSAGE_INTERVAL,
        transferedCount: ColumnData.TOTAL_TRANSFERRED_CHAT_COUNT,
        conferenceCount: ColumnData.TOTAL_CONFERENCE_CHAT_COUNT,
        persistentCount: ColumnData.TOTAL_PERSISTENT_CHAT_COUNT,
        requiresAttentionCount: ColumnData.TOTAL_REQUIRES_ATTENTION_CHAT_COUNT,
        escalationComment: ColumnData.ESCALATION_REASONS,
        extractName(attr) {
          const splitted = attr.split('.');
          if (splitted.length > 1) {
            const value = splitted.splice(-1, 1);
            const replacedName = this[splitted.join('.')];
            return replacedName ? `${replacedName}.${value}` : attr;
          }
          return simpleExtractor.call(this, attr);
        },
      },
    };
    if (names[field]) {
      return names[field].extractName(name.split(',')[0]);
    }
    return name;
  };

  checkFilterForCustomView = (filterableColumnData, columnField, view) => view.isCustom()
    && filterableColumnData === ColumnData.VALUE && columnField === view.getDimension();
}

export default new CompatibilitySupV2();
