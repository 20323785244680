const locale = {
  de: 'german',
  en: 'english',
  es: 'spanish',
  fr: 'french',
  ja: 'japanese',
  pt: 'portuguese',
  ru: 'russian',
  it: 'italian',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loadMessagesJson = (uiLocale: string): Promise<any> => new Promise((res, _rej) => {
  import(`@/locales/${locale[uiLocale]}.json`).then((data) => {
    res(data?.default);
  });
});

export default loadMessagesJson;
