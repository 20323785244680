import { gethhmmssFromDuration } from './timestamp';

function renderValueOrDefault(
  renderFunc,
  defaultValue,
): Function {
  return (value: number | null): string => (
    (value === null || value === undefined)
      ? defaultValue
      : renderFunc(value)
  );
}

export function formatNumberToString(value: number | null): string {
  return renderValueOrDefault((item) => item.toString(), '\u2013')(value);
}

export function formatNumberToPercent(value: number | null): string {
  return renderValueOrDefault((item) => `${item.toString()}%`, '\u2013')(value);
}

export function formatNumberToDuration(value: number | null): string {
  return renderValueOrDefault((item) => gethhmmssFromDuration(item), '\u2013')(value);
}
