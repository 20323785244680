import { handleActions, Action } from 'redux-actions';
import {
  ChangeChatStatusPayload,
  ChatManagerPayloads,
  ExpandChatPayload,
  SendMessagePayload,
  Type,
  UpdateMessagePayload,
  SetInputMessagePayload,
} from '@/actions/sync/chatActions';
import { ChatManager, Chat, Message } from '@/interfaces/Chat';
import { toChatData, toInternalTranscript, updateInternalTranscriptReadStatus } from '@/services/converters/chatConverter';
import ChatStatus from '@/enums/ChatStatus';

export const chatManagerInitialState: ChatManager = {
  chats: [],
  transcripts: {},
  internalTranscripts: {},
  fetchInternalTranscriptInProgress: false,
};

const processExpandChatAction = (
  state: ChatManager,
  { payload }: Action<ExpandChatPayload>,
): ChatManager => {
  const { chats, transcripts } = state;
  const { rowData, expandedChatId } = payload;
  const { [expandedChatId]: transcriptToRemove, ...restTranscripts } = transcripts;
  const newChatData = toChatData(rowData);
  const previousChat = chats.find((chat) => chat.engagementID === expandedChatId);
  const newChat = chats.find((chat) => chat.engagementID === rowData.engagementID);
  const updatedChats = chats
    .filter((chat) => chat.engagementID !== expandedChatId || chat.isInternal)
    .map((chat) => {
      if (chat.engagementID === expandedChatId) {
        return {
          ...chat,
          status: ChatStatus.HIDDEN,
          inputMessage: '',
        };
      }
      if (chat.engagementID === rowData.engagementID) {
        return {
          ...chat,
          ...newChatData,
        };
      }
      return chat;
    });
  return {
    ...state,
    chats: newChat
      ? updatedChats
      : [...updatedChats, newChatData],
    transcripts: previousChat && previousChat.isInternal
      ? transcripts
      : restTranscripts,
  };
};

const processRemoveNotInternalChatAction = (
  state: ChatManager,
  { payload }: Action<Chat>,
): ChatManager => {
  const { isInternal, engagementID } = payload;
  const { chats, transcripts } = state;
  const { [engagementID]: transcriptToRemove, ...updatedTranscripts } = transcripts;
  return {
    ...state,
    chats: chats.filter((chat) => chat.engagementID !== engagementID),
    transcripts: isInternal
      ? transcripts
      : updatedTranscripts,
  };
};

const processChangeChatStatusAction = (
  state: ChatManager,
  { payload }: Action<ChangeChatStatusPayload>,
): ChatManager => {
  const { engagementID, status } = payload;
  const { chats } = state;
  const updatedChats = chats.map((chat) => {
    if (chat.engagementID === engagementID) {
      return {
        ...chat,
        status,
      };
    }
    return chat;
  });

  return {
    ...state,
    chats: updatedChats,
  };
};

const processChangeChatPositionAction = (
  state: ChatManager,
  { payload }: Action<string>,
): ChatManager => {
  const { chats } = state;
  const updatedChats = [...chats];
  const chatIndex: number = chats.findIndex((chat) => (chat.engagementID === payload));
  const [chatToChange] = updatedChats.splice(chatIndex, 1);
  updatedChats.push(chatToChange);

  return {
    ...state,
    chats: updatedChats,
  };
};

const processUpdateChatsDataAction = (
  state: ChatManager,
  { payload }: Action<Chat[]>,
): ChatManager => {
  const { chats } = state;
  const updatedChats = chats.map((chat) => {
    const engagement = payload.find((engagementResponse) => (
      engagementResponse.engagementID === chat.engagementID
    ));
    return engagement
      ? {
        ...chat,
        ...engagement,
      }
      : chat;
  });
  return {
    ...state,
    chats: updatedChats,
  };
};

const processUpdateAction = (fieldName) => (state: ChatManager, payload) => ({
  ...state,
  [fieldName]: payload,
});

const processUpdateInternalTranscriptAction = (
  state,
  { payload }: Action<Message[]>,
): ChatManager => processUpdateAction('internalTranscripts')(state, payload);

const processUpdateTranscriptAction = (
  state,
  { payload }: Action<Message[]>,
): ChatManager => processUpdateAction('transcripts')(state, payload);

const processAddInternalChatAction = (
  state: ChatManager,
  { payload }: Action<string>,
): ChatManager => {
  const { chats, internalTranscripts, transcripts } = state;
  return {
    ...state,
    chats: chats.map((chat) => {
      if (chat.engagementID === payload) {
        return { ...chat, isInternal: true, timestamp: Date.now() };
      }
      return chat;
    }),
    internalTranscripts: {
      ...internalTranscripts,
      [payload]: toInternalTranscript(transcripts[payload]),
    },
  };
};

const processReadInternalChatAction = (
  state: ChatManager,
  { payload }: Action<string>,
): ChatManager => {
  const { internalTranscripts } = state;
  return {
    ...state,
    internalTranscripts: {
      ...internalTranscripts,
      [payload]: updateInternalTranscriptReadStatus(internalTranscripts[payload]),
    },
  };
};

const processAddMessageAction = (
  state: ChatManager,
  { payload }: Action<SendMessagePayload>,
): ChatManager => {
  const { internalTranscripts } = state;
  const { engagementID, message } = payload;
  return {
    ...state,
    internalTranscripts: {
      ...internalTranscripts,
      [engagementID]: [...internalTranscripts[engagementID], message],
    },
  };
};

const processRemoveInternalChatAction = (
  state: ChatManager,
  { payload }: Action<string>,
): ChatManager => {
  const { chats } = state;
  const updatedChats = chats
    .filter((chat) => chat.engagementID !== payload || chat.status === ChatStatus.EXPANDED)
    .map((chat) => {
      if (chat.engagementID === payload) {
        return { ...chat, isInternal: false };
      }
      return chat;
    });

  return {
    ...state,
    chats: updatedChats,
  };
};

const processUpdateMessageAction = (
  state: ChatManager,
  { payload }: Action<UpdateMessagePayload>,
): ChatManager => {
  const { internalTranscripts } = state;
  const { engagementID, updatedMessage } = payload;
  return {
    ...state,
    internalTranscripts: {
      ...internalTranscripts,
      [engagementID]: internalTranscripts[engagementID].map((savedMessage) => (
        savedMessage.dispatchTime === updatedMessage.dispatchTime ? updatedMessage : savedMessage
      )),
    },
  };
};

const processSetMessagesAsReadAction = (
  state: ChatManager,
  { payload }: Action<string>,
): ChatManager => {
  const { internalTranscripts } = state;
  const updatedInternalTranscripts = internalTranscripts[payload]
    ? {
      ...internalTranscripts,
      [payload]: internalTranscripts[payload].map((savedMessage) => (
        { ...savedMessage, read: true }
      )),
    }
    : internalTranscripts;
  return {
    ...state,
    internalTranscripts: updatedInternalTranscripts,
  };
};

const processSetInputMessageAction = (
  state: ChatManager,
  { payload }: Action<SetInputMessagePayload>,
): ChatManager => {
  const { chats } = state;
  const { engagementID, inputMessage } = payload;
  const updatedChats = chats
    .map((chat) => {
      if (chat.engagementID === engagementID) {
        return { ...chat, inputMessage };
      }
      return chat;
    });

  return {
    ...state,
    chats: updatedChats,
  };
};

const processSetfetchInternalTranscriptInProgress = (
  state: ChatManager,
  { payload }: Action<boolean>,
): ChatManager => ({
  ...state,
  fetchInternalTranscriptInProgress: payload,
});

const chatManagerReducer = handleActions<ChatManager, ChatManagerPayloads>(
  {
    [Type.ADD_INTERNAL_CHAT]: processAddInternalChatAction,
    [Type.UPDATE_INTERNAL_CHAT_READ]: processReadInternalChatAction,
    [Type.REMOVE_INTERNAL_CHAT]: processRemoveInternalChatAction,
    [Type.REMOVE_NOT_INTERNAL_CHAT]: processRemoveNotInternalChatAction,
    [Type.CHANGE_CHAT_STATUS]: processChangeChatStatusAction,
    [Type.CHANGE_CHAT_POSITION]: processChangeChatPositionAction,
    [Type.EXPAND_CHAT]: processExpandChatAction,
    [Type.SEND_MESSAGE]: processAddMessageAction,
    [Type.UPDATE_CHATS_DATA]: processUpdateChatsDataAction,
    [Type.UPDATE_INTERNAL_TRANSCRIPTS]: processUpdateInternalTranscriptAction,
    [Type.UPDATE_MESSAGE]: processUpdateMessageAction,
    [Type.UPDATE_TRANSCRIPT]: processUpdateTranscriptAction,
    [Type.SET_MESSAGES_AS_READ]: processSetMessagesAsReadAction,
    [Type.SET_INPUT_MESSAGE]: processSetInputMessageAction,
    [Type.SET_FETCH_INTERNAL_TRANSCRIPT_IN_PROGRESS]: processSetfetchInternalTranscriptInProgress,
  },
  chatManagerInitialState,
);

export default chatManagerReducer;
