import { createAction } from 'redux-actions';
import {
  FetchFollowUpConversationPayload,
  FetchConversationPayload,
  RequestInProgressPayload,
  RequestCompletedPayload,
  UpdateConversationTranscriptPayload,
} from '@/interfaces/Conversation';

export enum Type {
  UPDATE_CONVERSATION_TRANSCRIPT = 'UPDATE_CONVERSATION_TRANSCRIPT',
  FETCH_CONVERSATION_TRANSCRIPT = 'FETCH_CONVERSATION_TRANSCRIPT',
  CONVERSATION_TRANSCRIPT_REQUEST_IN_PROGRESS = 'CONVERSATION_TRANSCRIPT_REQUEST_IN_PROGRESS',
  ACTIVATE_CONVERSATION_TRANSCRIPT = 'ACTIVATE_CONVERSATION_TRANSCRIPT',
  FETCH_ACTIVE_CONVERSATION_TRANSCRIPT = 'FETCH_ACTIVE_CONVERSATION_TRANSCRIPT',
  FETCH_PREVIOUS_CHAT_CONVERSATION = 'FETCH_PREVIOUS_CHAT_CONVERSATION',
  CONVERSATION_TRANSCRIPT_REQUEST_COMPLETED = 'CONVERSATION_TRANSCRIPT_REQUEST_COMPLETED',
  CLEAR_CONVERSATION_TRANSCRIPT = 'CLEAR_CONVERSATION_TRANSCRIPT',
}

const updateConversationTranscript = createAction<UpdateConversationTranscriptPayload>(
  Type.UPDATE_CONVERSATION_TRANSCRIPT,
);
const fetchConversationTranscript = createAction<FetchFollowUpConversationPayload>(
  Type.FETCH_CONVERSATION_TRANSCRIPT,
);
const fetchPreviousChatConversation = createAction<FetchConversationPayload>(
  Type.FETCH_PREVIOUS_CHAT_CONVERSATION,
);
const requestInProgress = createAction<RequestInProgressPayload>(
  Type.CONVERSATION_TRANSCRIPT_REQUEST_IN_PROGRESS,
);
const requestCompleted = createAction<RequestCompletedPayload>(
  Type.CONVERSATION_TRANSCRIPT_REQUEST_COMPLETED,
);
const activateConversationTranscript = createAction(Type.ACTIVATE_CONVERSATION_TRANSCRIPT);
const fetchActiveConversationTranscript = createAction<void>(
  Type.FETCH_ACTIVE_CONVERSATION_TRANSCRIPT,
);
const clearConversationTranscript = createAction(Type.CLEAR_CONVERSATION_TRANSCRIPT);

export const ConversationActions = {
  updateConversationTranscript,
  fetchConversationTranscript,
  requestInProgress,
  requestCompleted,
  activateConversationTranscript,
  fetchPreviousChatConversation,
  fetchActiveConversationTranscript,
  clearConversationTranscript,
};
