import { handleActions } from 'redux-actions';
import { SupervisorViewActionsType, ViewActionType } from '@/actions/sync/supervisorViewActions';
import { CustomViewsData } from '@/interfaces/SupervisorView';

export const supervisorViewInitialState: CustomViewsData = {};

const fetchingViews = (state, { payload }) => ({
  ...state,
  [payload]: {
    ...state[payload],
    isFetching: true,
  },
});

const applyViewsFromWebStorage = (state, { payload }) => payload;

const applyViews = (state, { payload }) => ({
  ...state,
  [payload.settingId]: {
    isFetching: false,
    isEdited: false,
    siteId: payload.siteId,
    siteName: payload.siteName,
    name: payload.name,
    unit: payload.unit,
    settingViews: {
      ...payload.settingViews,
    },
  },
});

const addNewView = (state, { payload }) => {
  const { settingId, view } = payload;
  const { settingViews } = state[settingId];
  const { category } = view;
  let allowAddNewView = true;
  let categoryViews = settingViews[category] || [];

  categoryViews = categoryViews.map((currentView) => {
    let customView = currentView;
    if (currentView.name === view.name) {
      allowAddNewView = false;
      customView = { ...currentView, isEdited: true, isDeleted: false };
    }
    return customView;
  });

  return ({
    ...state,
    [settingId]: {
      ...state[settingId],
      isFetching: false,
      isEdited: true,
      settingViews: {
        ...settingViews,
        [category]: (allowAddNewView
          ? [...categoryViews, { ...view, isEdited: true, isDeleted: false }]
          : categoryViews),
      },
    },
  });
};

const deleteCustomView = (state, {
  payload: {
    category,
    customViewId,
    settingId,
    name,
  },
}) => {
  const { [settingId]: { settingViews } } = state;
  return {
    ...state,
    [settingId]: {
      ...state[settingId],
      isEdited: true,
      settingViews: {
        ...settingViews,
        [category]: settingViews[category].map((view) => {
          if (view.id === undefined || customViewId === undefined) {
            return view.name === name ? { ...view, isDeleted: true, isEdited: false } : view;
          }
          return view.id === customViewId ? { ...view, isDeleted: true, isEdited: false } : view;
        }),
      },
    },
  };
};

const updateRequestViewStatus = (isFetching) => (state, {
  payload: settingId,
}) => ({
  ...state,
  [settingId]: {
    ...state[settingId],
    isFetching,
  },
});

const updateCustomView = (state, {
  payload: {
    settingId,
    view,
  },
  meta: oldViewName,
}) => {
  const { category, name } = view;
  const { [settingId]: { settingViews } } = state;
  const viewName = oldViewName || name;

  return {
    ...state,
    [settingId]: {
      ...state[settingId],
      isEdited: true,
      settingViews: {
        ...settingViews,
        [category]: settingViews[category].map((currentView) => (
          currentView.name === viewName
            ? { ...view, isEdited: true, isDeleted: false }
            : currentView
        )),
      },
    },
  };
};

export const supervisorViewReducer = handleActions<SupervisorViewActionsType>(
  {
    [ViewActionType.FETCHING_VIEWS]: fetchingViews,
    [ViewActionType.APPLY_VIEWS]: applyViews,
    [ViewActionType.ADD_CUSTOM_VIEW]: addNewView,
    // @ts-ignore
    [ViewActionType.EDIT_CUSTOM_VIEW]: updateCustomView,
    // @ts-ignore
    [ViewActionType.DELETE_CUSTOM_VIEW]: deleteCustomView,
    [ViewActionType.SUPERVISOR_VIEW_REQUEST_IN_PROGRESS]: updateRequestViewStatus(true),
    [ViewActionType.SUPERVISOR_VIEW_REQUEST_COMPLETED]: updateRequestViewStatus(false),
    [ViewActionType.APPLY_VIEWS_FROM_WEB_STORAGE]: applyViewsFromWebStorage,
  },
  supervisorViewInitialState,
);

export default supervisorViewReducer;
