import { handleActions } from 'redux-actions';
import { User } from '@/interfaces/User';
import { Type } from '@/actions/sync/userActions';

const logoutAfterMills = window.NUANCE_ENV?.SESSION_LOGOUT_TIME;

export const userInitialState: User = {
  globalSettings: {
    requestInterval: 2,
    liCanChatWithAgents: false,
  },
  userRoles: [],
  sites: [],
  logoutAfterMills: (logoutAfterMills && +logoutAfterMills) || 900000,
};

const processAction = (state, action): User => ({ ...state, ...action.payload });

const processLogoutNotification = (state, { payload }): User => ({
  ...state,
  showLogoutNotification: payload,
});

export const userReducer = handleActions<User>(
  {
    [Type.UPDATE_USER]: processAction,
    [Type.SHOW_LOGOUT_NOTIFICATION]: processLogoutNotification,
  },
  userInitialState,
);
