import ColumnData from '@/enums/ColumnData';
import RowId from '@/enums/RowId';
import { CustomColumn } from '@/components/organisms/CustomTable/customTable.types';

const createRequiredFieldsString = (
  columns: CustomColumn[],
  returnFields: Array<RowId | ColumnData>,
): string => columns
  .filter(({ generated }) => !generated)
  .map(({ dataKey, apiKey }): string | void => apiKey || dataKey)
  .concat(returnFields)
  .join(',');

export default createRequiredFieldsString;
