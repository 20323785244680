import {
  takeEvery, put, call, select, delay, throttle, takeLatest,
} from 'redux-saga/effects';
import { toUserMessage } from '@/services/converters/chatConverter';
import { parseAndLogError } from '@/services/loggerService';
import { Type, ChatActions } from '@/actions/sync/chatActions';
import { Type as OnlineStatusType } from '@/actions/sync/onlineStatusActions';
import {
  getConnectedChatsIds,
  areChatsExist,
  getInternalTranscripts,
  getTranscripts,
  getChats,
  getUnreadMessageCount,
  selectFetchInternalTranscriptInProgress,
} from '@/selectors/chatManagerSelector';
import { loadChatsTranscript, loadChatsData } from '@/api/realtimeClientAPI';
import {
  loadMessages, joinChat, postMessage, exitChat,
} from '@/api/agentEngagementAPI';
import { CONNECTED_CHATS_IDS } from '@/constants/WebStorageKey';
import { WebStorageActions } from '@/actions/sync/webStorageActions';
import selectOnlineStatus from '@/selectors/onlineStatusSelector';

export function* fetchChatData() {
  try {
    const chatDataResponse = yield call(loadChatsData);
    const { engagements } = chatDataResponse;
    yield put(ChatActions.updateChatsData(engagements));
  } catch (error) {
    parseAndLogError(error);
  }
}

export function* fetchTranscript() {
  try {
    const transcriptResponse = yield call(loadChatsTranscript);
    const { engagements } = transcriptResponse;
    const transcripts = yield select(getTranscripts);
    const chats = yield select(getChats);
    const updatedTranscripts = { ...transcripts };
    engagements.forEach((engagementTranscript) => {
      const { engagementID, transcript } = engagementTranscript;
      const chatToUpdate = chats.find((chat) => chat.engagementID === engagementID);
      if (chatToUpdate) {
        updatedTranscripts[engagementID] = transcript;
      }
    });
    yield put(ChatActions.updateTranscripts(updatedTranscripts));
  } catch (error) {
    parseAndLogError(error);
  }
}

export function* fetchInternalMessages() {
  try {
    yield put(ChatActions.setfetchInternalTranscriptInProgress(true));
    const internalTranscriptResponse = yield call(loadMessages);
    const { engagements } = internalTranscriptResponse;
    if (engagements && engagements.length > 0) {
      const internalTranscripts = yield select(getInternalTranscripts);
      const updatedInternalTranscripts = { ...internalTranscripts };
      Object.keys(internalTranscripts).forEach((key) => {
        const engagement = engagements.find((engagementResponse) => engagementResponse.id === key);
        if (engagement) {
          updatedInternalTranscripts[engagement.id] = [
            ...internalTranscripts[engagement.id],
            ...engagement.messages
              .filter((message) => message.chatLineReceiverType === 'internal')
              .map((message) => toUserMessage(
                message,
                false,
              )),
          ];
        }
      });
      yield put(ChatActions.updateInternalTranscripts(updatedInternalTranscripts));
    }
    yield put(ChatActions.setfetchInternalTranscriptInProgress(false));
    if (yield select(getConnectedChatsIds)) {
      yield call(fetchInternalMessages);
    }
  } catch (error: any) {
    parseAndLogError(error);
    yield put(ChatActions.setfetchInternalTranscriptInProgress(false));
    if ((yield select(getConnectedChatsIds))
      && (yield select(selectOnlineStatus))
      && error.status && error.status !== 401) {
      yield call(fetchInternalMessages);
    }
  }
}

// Once application is back online resume messages call
function* fetchInternalChatMessages() {
  if ((yield select(selectOnlineStatus)) && (yield select(getConnectedChatsIds))
    && !(yield select(selectFetchInternalTranscriptInProgress))) {
    yield call(fetchInternalMessages);
  }
}

export function* startChatRequests() {
  if ((yield select(areChatsExist))) {
    yield call(fetchChatData);
    yield call(fetchTranscript);
  }
}

export function* startInternalTranscriptRequests(engagementID) {
  try {
    yield put(ChatActions.addInternalChat(engagementID));
    yield put(WebStorageActions.updateWebStorage({
      key: CONNECTED_CHATS_IDS,
      value: yield select(getConnectedChatsIds),
    }));
    // To avoid redundant messages netwrok call when 2 or more internal chats are open
    if (yield select(getConnectedChatsIds)
      && !(yield select(selectFetchInternalTranscriptInProgress))) {
      yield call(fetchInternalMessages);
    }
  } catch (error) {
    parseAndLogError(error);
  }
}

export function* startChat({
  payload,
}: ReturnType<typeof ChatActions.startChat>) {
  try {
    yield call(joinChat, payload);
    yield call(startInternalTranscriptRequests, payload);
  } catch (error: any) {
    if (error.status === 422) {
      yield call(startInternalTranscriptRequests, payload);
    }
    parseAndLogError(error);
  }
}

export function* sendMessage({
  // @ts-ignore
  payload: { message, engagementID },
}: ReturnType<typeof ChatActions.sendMessage>) {
  let updatedMessage;
  try {
    yield call(postMessage, engagementID, message);
    updatedMessage = {
      ...message,
      isSending: false,
      timestamp: Date.now(),
    };
  } catch (error: any) {
    updatedMessage = {
      ...message,
      isSending: false,
      isUndelivered: true,
      timestamp: Date.now(),
    };
    if (error.status !== 422) {
      parseAndLogError(error);
    }
  } finally {
    yield put(ChatActions.updateMessage({ engagementID, updatedMessage }));
  }
}

export function* endChat({
  payload,
}: ReturnType<typeof ChatActions.endChat>) {
  try {
    yield call(exitChat, payload);
    yield put(ChatActions.removeInternalChat(payload));
  } catch (error: any) {
    if (error.status === 422) {
      // generally happens if the engagement has already ended
      yield put(ChatActions.removeInternalChat(payload));
    } else {
      // TODO: implement server side logging after SUPV3-339 is resolved.
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }
}

export function* updateReadNewInternalMessage({
  payload,
}: ReturnType<typeof ChatActions.readNewInternalChat>) {
  const unreadMessageCount = yield select(getUnreadMessageCount(payload));
  const delayPeriod = unreadMessageCount >= 2 ? 2000 : 15;
  if (unreadMessageCount) {
    yield delay(delayPeriod);
    yield put(ChatActions.updateInternalChatRead(payload));
  }
}

export function* watchChat() {
  yield takeEvery(Type.FETCH_TRANSCRIPT, startChatRequests);
  yield takeLatest(Type.FETCH_INTERNAL_TRANSCRIPT, fetchInternalMessages);
  yield takeEvery(Type.START_CHAT, startChat);
  yield takeEvery(Type.END_CHAT, endChat);
  yield takeEvery(Type.SEND_MESSAGE, sendMessage);
  yield takeEvery(OnlineStatusType.UPDATE_ONLINE_STATUS, fetchInternalChatMessages);
  yield throttle(2500, Type.READ_NEW_INTERNAL_CHAT, updateReadNewInternalMessage);
}
