import { handleActions, Action } from 'redux-actions';
import { TokenPayload } from '@/interfaces/TokenPayload';
import { Oauth2State } from '@/interfaces/Oauth2State';
import Type, { Oauth2Payloads } from '@/actions/sync/oauth2Actions';

export const tokenPayloadInitialState: TokenPayload = {
  accessToken: null,
  tokenType: null,
  refreshToken: null,
  expiresIn: null,
  scope: null,
  sites: [],
  jti: null,
  tokenUpdateTime: 0,
};

export const oauth2InitialState: Oauth2State = {
  idpPage: null,
  pendingTokenRequest: null,
  tokenPayload: tokenPayloadInitialState,
  authenticationInProgress: false,
};

const processSetIdpPage = (
  state: Oauth2State,
  { payload }: Action<string>,
): Oauth2State => ({
  ...state,
  idpPage: payload,
});

const processTokenAction = (fieldName: string) => ((
  state: Oauth2State,
  { payload }: Action<string>,
): Oauth2State => ({
  ...state,
  [fieldName]: payload,
}));

const processSetPendingTokenRequest = (
  state: Oauth2State,
  { payload }: Action<Promise<TokenPayload>>,
): Oauth2State => ({
  ...state,
  pendingTokenRequest: payload,
});

const processSetAuthenticationInProgressAction = (
  state: Oauth2State,
  { payload }: Action<boolean>,
): Oauth2State => ({
  ...state,
  authenticationInProgress: payload,
});

export const oauth2Reducer = handleActions<Oauth2State, Oauth2Payloads>(
  {
    [Type.UPDATE_TOKEN]: processTokenAction('tokenPayload'),
    [Type.SET_PENDING_TOKEN_REQUEST]: processSetPendingTokenRequest,
    [Type.SET_IDP_PAGE]: processSetIdpPage,
    [Type.SET_AUTHENTICATION_IN_PROGRESS]: processSetAuthenticationInProgressAction,
  },
  oauth2InitialState,
);
