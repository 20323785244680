export enum ServerLocale {
  US = 'en_US',
  DE = 'de_DE',
  FR = 'fr_FR',
  JP = 'ja_JP',
  ES = 'es_ES',
  PT = 'pt',
  RU = 'ru_RU',
  IT = 'it_IT',
}

export enum Language {
  ENGLISH = 'english',
  GERMAN = 'german',
  FRENCH = 'french',
  JAPANESE = 'japanese',
  SPANISH = 'spanish',
  PORTUGUESE = 'portuguese',
  RUSSIAN = 'russian',
  ITALIAN = 'Italian',
}

export const LocaleLanguage = {
  en_US: Language.ENGLISH,
  de_DE: Language.GERMAN,
  fr_FR: Language.GERMAN,
  ja_JP: Language.JAPANESE,
  es_ES: Language.SPANISH,
  pt: Language.PORTUGUESE,
  ru: Language.RUSSIAN,
  it: Language.ITALIAN,
};

export enum UiLocale {
  US = 'en',
  DE = 'de',
  FR = 'fr',
  JP = 'ja',
  ES = 'es',
  PT = 'pt',
  RU = 'ru',
  IT = 'it',
}
