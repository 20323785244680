import Category from '@/enums/Category';
import localeKeys from '@/constants/localeKeys';
import getStringByLocaleKey from '@/utils/getStringByLocaleKey';
import ColumnData from '@/enums/ColumnData';
import { ViewColumn } from '@/interfaces/SupervisorView';

export function defineQueueCustomViewColumns(): ViewColumn[] {
  return [
    {
      data: ColumnData.ENGAGEMENTS_IN_QUEUE,
      titleText: getStringByLocaleKey(localeKeys.TOTAL_IN_QUEUE_HEADER),
      visible: true,
    },
    {
      data: ColumnData.PERCENT_QUEUE_SLA,
      titleText: getStringByLocaleKey(localeKeys.PERCENT_WITHIN_SLA_HEADER),
      visible: true,
    },
    {
      data: ColumnData.AVG_TIME_IN_QUEUE,
      titleText: getStringByLocaleKey(localeKeys.AVG_TIME_IN_QUEUE_HEADER),
      visible: true,
    },
    {
      data: ColumnData.MAX_TIME_IN_QUEUE,
      titleText: getStringByLocaleKey(localeKeys.MAX_TIME_IN_QUEUE_HEADER),
      visible: true,
    },
    {
      data: ColumnData.PERCENT_ENGAGEMENTS_QUEUED,
      titleText: getStringByLocaleKey(localeKeys.PERCENT_OF_CHATS_QUEUED_HEADER),
      visible: true,
    },
  ];
}

export function defineEngagementCustomViewColumns(): ViewColumn[] {
  return [
    {
      data: ColumnData.ACTIVE_ENGAGEMENTS,
      titleText: getStringByLocaleKey(localeKeys.ACTIVE_CHATS_HEADER),
      visible: true,
    },
    {
      data: ColumnData.AVG_ENGAGEMENT_DURATION,
      titleText: getStringByLocaleKey(localeKeys.AVG_DURATION_HEADER),
      visible: true,
    },
    {
      data: ColumnData.AVG_AGENT_RESPONSE_TIME,
      titleText: getStringByLocaleKey(localeKeys.AVG_RESPONSE_TIME_HEADER),
      visible: true,
    },
    {
      data: ColumnData.AVG_INITIAL_AGENT_RESPONSE_TIME,
      titleText: getStringByLocaleKey(localeKeys.AVG_INITIAL_RESPONSE_HEADER),
      visible: false,
    },
    {
      data: ColumnData.AGENTS,
      titleText: getStringByLocaleKey(localeKeys.AGENTS_HEADER),
      visible: true,
    },
    {
      data: ColumnData.MAX_AGENT_RESPONSE_TIME,
      titleText: getStringByLocaleKey(localeKeys.MAX_RESPONSE_HEADER),
      visible: false,
    },
    {
      data: ColumnData.AVG_MESSAGE_INTERVAL,
      titleText: getStringByLocaleKey(localeKeys.AVG_WAIT_HEADER),
      visible: false,
    },
    {
      data: ColumnData.TOTAL_TRANSFERRED_CHAT_COUNT,
      titleText: getStringByLocaleKey(localeKeys.TRANSFERRED_COUNT_HEADER),
      visible: false,
    },
    {
      data: ColumnData.TOTAL_CONFERENCE_CHAT_COUNT,
      titleText: getStringByLocaleKey(localeKeys.CONFERENCE_COUNT_HEADER),
      visible: false,
    },
    {
      data: ColumnData.TOTAL_PERSISTENT_CHAT_COUNT,
      titleText: getStringByLocaleKey(localeKeys.PERSISTENT_COUNT_HEADER),
      visible: false,
    },
    {
      data: ColumnData.TOTAL_REQUIRES_ATTENTION_CHAT_COUNT,
      titleText: getStringByLocaleKey(localeKeys.REQUIRES_ATTENTION_COUNT_HEADER),
      visible: false,
    },
  ];
}

export const defineCustomViewColumns = (
  attributeColumn: ViewColumn,
  category: Category,
): ViewColumn[] => {
  const defaultColumns = category === Category.QUEUE
    ? defineQueueCustomViewColumns()
    : defineEngagementCustomViewColumns();
  return [attributeColumn, ...defaultColumns];
};

export function getDefaultViewsLocaleKeys() {
  return [
    localeKeys.CHAT_REQUESTS,
    localeKeys.AGENT_SERVICEABILITY,
    localeKeys.CHATS,
    localeKeys.BUSINESS_RULES,
    localeKeys.PAGES,
    localeKeys.ESCALATED_CHATS,
    localeKeys.STATUSES,
    localeKeys.ENGAGEMENT_METRICS,
    localeKeys.CONVERSATIONS,
  ];
}
