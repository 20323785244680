import { createSelector } from 'reselect';
import { StoreState } from '@/stores/store';
import { User } from '@/interfaces/User';
import { Site } from '@/interfaces/Site';

export const selectUser = (state: StoreState): User => (state.user);

export const selectUserSites = createSelector(
  selectUser,
  (user): Site[] => user.sites,
);

export const selectUserRoles = createSelector(
  selectUser,
  (user): string[] => user.userRoles,
);

export const selectLogoutData = createSelector(
  selectUser,
  ({ logoutAfterMills, showLogoutNotification }) => ({ logoutAfterMills, showLogoutNotification }),
);
