import { useEffect, useCallback, useMemo } from 'react';
import { logConsole } from '@/services/loggerService';

export function useEnvironmentVerifier(
) {
  const URL_KEYS: string[] = useMemo(() => (
    ['AUTH_DOMAIN', 'AEAPI_DOMAIN', 'API_DOMAIN']
  ), []);

  const ALL_KEYS: string[] = useMemo(() => (
    [...URL_KEYS, 'AUTH_DOMAIN', 'AEAPI_DOMAIN', 'API_DOMAIN']
  ), [URL_KEYS]);

  const verifyValues = useCallback(() => {
    const missingKeys: string[] = [];
    ALL_KEYS.forEach((key: string) => {
      if (!window.NUANCE_ENV[key]) {
        missingKeys.push(key);
      }
    });

    if (missingKeys.length > 0) {
      const plural = missingKeys.length === 0 ? '' : 's';
      const keys = missingKeys.join(',');
      logConsole(`NUANCE_ENV is missing the key${plural}: ${keys} `);
    }
  }, [ALL_KEYS]);

  const verifyURLs = useCallback(() => {
    const regex = /^https/i;
    URL_KEYS.forEach((key) => {
      const value = window.NUANCE_ENV[key];
      if (value && !value.match(regex)) {
        logConsole(`Incorrect value '${value}' provided for '${key}' in NUANCE_ENV.`);
      }
    });
  }, [URL_KEYS]);

  useEffect(() => {
    if (!window.NUANCE_ENV) {
      logConsole('window.NUANCE_ENV is undefined. environment.js might not be loaded.');
    } else {
      verifyValues();
      verifyURLs();
    }
  }, [verifyValues, verifyURLs]);
}

export default useEnvironmentVerifier;
