import {
  takeEvery, put, select,
} from 'redux-saga/effects';
import { isEqual } from 'lodash';
import Type, { CurrentViewActions } from '@/actions/sync/currentViewActions';
import { selectCurrentView } from '@/selectors/currentViewSelector';

function* updateCurrentView({
  payload: updatedCurrentView,
}:ReturnType<typeof CurrentViewActions.requestUpdateCurrentView>) {
  const currentView = yield select(selectCurrentView);
  if (!isEqual(currentView, updatedCurrentView)) {
    yield put(CurrentViewActions.updateCurrentView(updatedCurrentView));
  }
}

export default function* currentViewSaga() {
  yield takeEvery(Type.REQUEST_UPDATE_CURRENT_VIEW, updateCurrentView);
}
