import { createSelector } from 'reselect';
import { ConversationManager, ConversationTranscript, ConversationTranscripts } from '@/interfaces/Conversation';
import { StoreState } from '@/stores/store';

export const selectConversationManager = (state: StoreState): ConversationManager => (
  state.conversationManager
);

export const selectActiveConversationId = (
  state: StoreState,
): string => (
  state.conversationManager.activeConversation.conversationID
);

export const selectActiveConversation = (
  state: StoreState,
): object => (
  state.conversationManager.activeConversation
);

export const selectConversations = (state: StoreState) => (
  state.conversationManager.conversations
);

export const selectConversationTranscripts = (conversationID: string) => createSelector(
  [selectConversations],
  (conversationTranscripts: ConversationTranscripts): ConversationTranscript => (
    Object.keys(conversationTranscripts).length > 0 && conversationTranscripts[conversationID]
      ? conversationTranscripts[conversationID]
      : {
        engagements: {},
        isLoaded: false,
        isLoading: false,
      }
  ),
);
