/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { ErrorInfo } from 'react';
import { logConsole, parseAndLogError } from './services/loggerService';

class ErrorBoundary extends React.Component {
  componentDidCatch(error: Error, info: ErrorInfo) {
    parseAndLogError(error, info);
    logConsole(error);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
