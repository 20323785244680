import { put, select, takeEvery } from 'redux-saga/effects';
import { ViewActionType } from '@/actions/sync/supervisorViewActions';
import { VIEW_DATA } from '@/constants/WebStorageKey';
import { WebStorageActions } from '@/actions/sync/webStorageActions';
import { isAdministrator } from '@/selectors/applicationSettingsSelector';

export function* saveViewsToWebStorage() {
  const viewsData = yield select((store) => store.supervisorViews);
  const isAdmin = yield select(isAdministrator);

  if (isAdmin) {
    yield put(WebStorageActions.updateWebStorage({
      key: VIEW_DATA,
      value: viewsData,
      isSession: true,
    }));
  }
}

export default function* selectedUnitsSaga() {
  yield takeEvery([
    ViewActionType.ADD_CUSTOM_VIEW,
    ViewActionType.EDIT_CUSTOM_VIEW,
    ViewActionType.DELETE_CUSTOM_VIEW,
  ], saveViewsToWebStorage);
}
