import { getCurrentSiteId, getCurrentSettingId } from '@/selectors/applicationSettingsSelector';
import { store } from '@/stores/store';
import { CustomScript } from '@/interfaces/CustomScript';
import URL from '@/api/url';
import {
  authorizedGet, getUserName, authorizedPost, authorizedDelete, authorizedPut,
} from '@/api/oAuth2ClientAPI';
import { SupervisorViewResponse, DeleteViewResponse } from '@/interfaces/SupervisorView';
import selectRegisteredId from '@/selectors/registeredIdSelector';
import { AgentLogoutResponse } from '@/interfaces/responses/AgentLogourResponse';
import { LoggedMessage } from '@/interfaces/Logger';
import LoginStateType from '@/enums/loginStateType';
import { authorizedAeapiPost } from './agentEngagementAPI';
import { CustomSettingRequest } from '@/interfaces/ApplicationSettings';

export function loadCustomScript(): Promise<CustomScript | void> {
  const params = `?username=${getUserName()}`;
  const url = URL.API.ENGAGEMENT_API_V2_AGENT.SUPERVISOR.CUSTOM_FILTER + params;
  return authorizedGet(url, null);
}

export function postCustomScript(updatedFilters?): Promise<CustomScript | void> {
  const url = URL.API.ENGAGEMENT_API_V2_AGENT.SUPERVISOR.CUSTOM_FILTER;
  const {
    language, locale, lastUpdateDate, filter,
  }: CustomScript = store.getState().customScript;
  const script = {
    filter: updatedFilters || filter,
    username: getUserName(),
    locale,
    lastUpdateDate,
    language,
  };
  return authorizedPost(url, JSON.stringify(script));
}

export function loadSupervisorView(max, offset): Promise<SupervisorViewResponse | void> {
  const url = `${URL.API.ENGAGEMENT_API_V2_AGENT.SUPERVISOR.SUPERVISOR_VIEW}?`
    + `settingId=${getCurrentSettingId(store.getState())}`
    + `&siteId=${getCurrentSiteId(store.getState())}`
    + `&max=${max}`
    + `&offset=${offset}`
    + `&agentId=${getUserName()}`;

  return authorizedGet<SupervisorViewResponse>(url, null);
}

export function postSupervisorView(requestBody): Promise<{ id: string } | void> {
  const url = `${URL.API.ENGAGEMENT_API_V2_AGENT.SUPERVISOR.SUPERVISOR_VIEW}?`
    + `&agentId=${getUserName()}`;
  return authorizedPost(url, requestBody);
}

export function loginSupervisor(reLogin?: boolean): Promise<void> {
  const url: string = `${URL.API.ENGAGEMENT_API_V2_AGENT.SUPERVISOR.LOGIN}?`
    + `agentId=${getUserName()}`
    + '&output=json';
  const data = {
    state: reLogin ? LoginStateType.RESTORED : LoginStateType.NEW,
    username: getUserName(),
  };
  return authorizedPost(url, JSON.stringify(data));
}

export function logoutAgent(agentIdToLogout): Promise<AgentLogoutResponse | void> {
  const state = store.getState();
  const agentId = getUserName();
  const registeredId = selectRegisteredId(state);
  const url: string = `${URL.API.ENGAGEMENT_API_V2_AGENT.SUPERVISOR.LOGOUT_AGENT}?`
    + `agentId=${agentId}`
    + `&registeredId=${registeredId}`;
  const data = {
    agentId,
    agentIdToLogout,
  };
  return authorizedAeapiPost(url, JSON.stringify(data));
}

export function deleteSupervisorView(
  customViewId,
  settingId,
  siteId,
): Promise<DeleteViewResponse | void> {
  const url: string = `${URL.API.ENGAGEMENT_API_V2_AGENT.SUPERVISOR.SUPERVISOR_VIEW}?`
    + `agentId=${getUserName()}`
    + `&customViewId=${customViewId}`
    + `&settingId=${settingId}`
    + `&siteId=${siteId}`;
  return authorizedDelete(url, null);
}

export function putSupervisorView(requestBody): Promise<void> {
  const url = `${URL.API.ENGAGEMENT_API_V2_AGENT.SUPERVISOR.SUPERVISOR_VIEW}?`
    + `&agentId=${getUserName()}`;
  return authorizedPut(url, requestBody);
}

export function logMessage(title, level, detail = {}): Promise<void> | undefined {
  const userName = getUserName();
  if (!userName) return undefined;
  const url: string = URL.API.ENGAGEMENT_API_V2_AGENT.SUPERVISOR.LOGGING;
  const message: LoggedMessage = {
    title: `Supervisor V3 user = [${userName}] - ${title}`,
    detail,
    timestamp: Date.now(),
    level,
  };
  return authorizedPost(url, JSON.stringify(message));
}

export function loadCustomSettings(): Promise<void> {
  const url = URL.API.ENGAGEMENT_API_V2_AGENT.SUPERVISOR.CUSTOM_SETTINGS;
  return authorizedGet(url, null);
}

export function postCustomSettings(): Promise<void> {
  const url = `${URL.API.ENGAGEMENT_API_V2_AGENT.SUPERVISOR.CUSTOM_SETTINGS}`;

  const { rotation, applicationSettings, customScript } = store.getState();
  const requestBody:CustomSettingRequest = {
    rotation,
    customScript,
    shouldApplyCustomSettings: applicationSettings.shouldApplyLastSettings,
    selectedBusinessUnits: applicationSettings.selectedBusinessUnitIds,
    selectedAgentGroups: applicationSettings.selectedAgentGroupIds,
  };
  return authorizedPost(url, JSON.stringify(requestBody));
}
