import { put, takeEvery } from 'redux-saga/effects';
import { getFromWebStorage } from '@/services/webStorageService';
import formatStringAsJson from '@/utils/formatStringAsJson';
import { ApplicationSettings } from '@/interfaces/ApplicationSettings';
import Type from '@/actions/sync/applicationSettingsActions';
import { WebStorageActions } from '@/actions/sync/webStorageActions';
import { getUserName } from '@/api/oAuth2ClientAPI';

export function* saveSelectedUnitToWebStorage({ type, payload, meta }) {
  const userName: string | null = getUserName();
  const applicationSettings = formatStringAsJson(
    getFromWebStorage(userName),
  ) as ApplicationSettings;
  const siteId = meta || applicationSettings.selectedSite.id;
  switch (type) {
    case Type.SELECTED_SITE_CHANGED:
      applicationSettings.selectedSite = payload;
      break;
    case Type.SELECTED_UNIT_CHANGED:
      applicationSettings.selectedUnit = payload;
      break;
    case Type.SELECTED_AGENT_GROUPS_CHANGED:
      applicationSettings.selectedAgentGroupIds[siteId] = payload;
      break;
    case Type.SELECTED_BUSINESS_UNITS_CHANGED:
      applicationSettings.selectedBusinessUnitIds[siteId] = payload;
      break;
    case Type.SHOW_TRANSCRIPT_DETAILS:
      applicationSettings.showTranscriptDetails = payload;
      break;
    case Type.SHOW_COLUMN_SEARCH:
      applicationSettings.isColumnSearchVisible = payload;
      break;
    default: break;
  }
  yield put(WebStorageActions.updateWebStorage({
    key: userName,
    value: applicationSettings,
  }));
}

export default function* selectedUnitsSaga() {
  yield takeEvery([
    Type.SELECTED_SITE_CHANGED,
    Type.SELECTED_UNIT_CHANGED,
    Type.SELECTED_AGENT_GROUPS_CHANGED,
    Type.SELECTED_BUSINESS_UNITS_CHANGED,
    Type.SHOW_TRANSCRIPT_DETAILS,
    Type.SHOW_COLUMN_SEARCH,
  ], saveSelectedUnitToWebStorage);
}
