import {
  CONNECTED_CHATS_IDS, REGISTERED_ID, TOKEN_INFO, VIEW_DATA,
} from '@/constants/WebStorageKey';
import { TokenPayload } from '@/interfaces/TokenPayload';

function getWebStore(isSession?: boolean): Storage {
  return isSession ? sessionStorage : localStorage;
}

export function saveToWebStorage(
  key: string | null,
  value: string | TokenPayload | object,
  isSession?: boolean,
): void {
  if (!key) return;
  let newValue: string = value as string;
  if (typeof value === 'object') {
    newValue = JSON.stringify(value);
  }
  getWebStore(isSession).setItem(key, newValue);
}

export function getFromWebStorage(key: string | null, isSession?: boolean): string | null {
  return key && getWebStore(isSession).getItem(key);
}

export function removeFromWebStorage(key: string | null, isSession?: boolean): void {
  if (key) {
    getWebStore(isSession).removeItem(key);
  }
}

export function clearWebStorageBeforeLogout(): void {
  removeFromWebStorage(CONNECTED_CHATS_IDS);
  removeFromWebStorage(REGISTERED_ID);
  removeFromWebStorage(TOKEN_INFO, true);
  removeFromWebStorage(VIEW_DATA, true);
}
