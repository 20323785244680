import { handleActions } from 'redux-actions';
import { BookmarkedActionsType, Type } from '@/actions/sync/userActions';
import { Bookmarked } from '@/interfaces/Bookmarked';

const initialState: Bookmarked = {
  chatIds: [],
  conversationIds: [],
};

const processPinChat = (state, action): Bookmarked => {
  const chatId = action.payload;
  const { chatIds } = state;
  if (chatIds.includes(chatId)) {
    return state;
  }
  return { ...state, chatIds: [...chatIds, chatId] };
};

const processUnpinChat = (state, action): Bookmarked => {
  const chatId = action.payload;
  const { chatIds } = state;
  if (!chatIds.includes(chatId)) {
    return state;
  }
  return { ...state, chatIds: chatIds.filter((addedChatId) => addedChatId !== chatId) };
};

const processPinConversation = (state, action): Bookmarked => {
  const conversationId = action.payload;
  const { conversationIds } = state;
  if (conversationIds.includes(conversationId)) {
    return state;
  }
  return { ...state, conversationIds: [...conversationIds, conversationId] };
};

const processUnpinConversation = (state, action): Bookmarked => {
  const conversationId = action.payload;
  const { conversationIds } = state;
  if (!conversationIds.includes(conversationId)) {
    return state;
  }
  return {
    ...state,
    conversationIds: conversationIds
      .filter((addedConversationId) => addedConversationId !== conversationId),
  };
};

const bookmarkedReducer = handleActions<BookmarkedActionsType>(
  {
    // @ts-ignore
    [Type.PIN_CHAT]: processPinChat,
    // @ts-ignore
    [Type.UNPIN_CHAT]: processUnpinChat,
    // @ts-ignore
    [Type.PIN_CONVERSATION]: processPinConversation,
    // @ts-ignore
    [Type.UNPIN_CONVERSATION]: processUnpinConversation,
  },
  initialState,
);

export default bookmarkedReducer;
