enum PageType {
  SUMMARY = 'Summary',
  ACTIVE = 'Active',
  QUEUE = 'Queue',
  AGENTS = 'Agents',
  FOLLOW_UP = 'Follow Up',
  BOOKMARKS = 'Bookmarks',
}

export default PageType;
