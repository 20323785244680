import { createSelector } from 'reselect';
import { StoreState } from '@/stores/store';
import { CustomScript, Filter } from '@/interfaces/CustomScript';
import { CurrentView } from '@/interfaces/currentView';
import { UiLocale } from '@/interfaces/Language';
import { toUiLocale } from '@/services/converters/localeConverter';
import { CURRENT_LOCALE } from '@/constants/WebStorageKey';
import { selectCurrentView } from './currentViewSelector';

export const selectCustomScript = (state: StoreState): CustomScript => (state.customScript);

export const selectAllFilters = createSelector(
  selectCustomScript,
  (customScript: CustomScript): Filter[] => customScript.filter || [],
);

export const selectCurrentViewFilters = createSelector(
  [selectAllFilters, selectCurrentView],
  (filters: Filter[], currentView: CurrentView): Filter[] => (
    filters.length
      ? filters.filter((item: Filter) => (item.tableId === currentView.tableId))
      : []
  ),
);

export const hasFiltersForCurrentView = createSelector(
  selectCurrentViewFilters,
  (filters: Filter[]): boolean => !!filters.length,
);

export const getUiLocale = createSelector(
  (state: StoreState): CustomScript => state.customScript,
  (customScript) => (customScript && customScript.lastUpdateDate
    ? toUiLocale(customScript.locale)
    : toUiLocale(localStorage.getItem(CURRENT_LOCALE)) || UiLocale.US),
);

export const isUiLocaleEN = createSelector(
  getUiLocale,
  (uiLocale): boolean => uiLocale === UiLocale.US,
);
