import { handleActions } from 'redux-actions';
import { Type } from '@/actions/sync/openActionItemActions';

const openActionItemName = '';

const updateOpenActionItemName = (state, action) => action.payload;

const openActionItemReducer = handleActions<string>(
  {
    [Type.UPDATE_OPEN_ACTION_ITEM]: updateOpenActionItemName,
  },
  openActionItemName,
);

export default openActionItemReducer;
