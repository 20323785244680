import { handleActions } from 'redux-actions';
import { Type } from '@/actions/sync/onlineStatusActions';

const onlineStatus = true;

const updateOnlineStatus = (state, action) => action.payload;

const onlineStatusReducer = handleActions<boolean>(
  {
    [Type.UPDATE_ONLINE_STATUS]: updateOnlineStatus,
  },
  onlineStatus,
);

export default onlineStatusReducer;
