enum ChatStatus {
  EXPANDED = 'expanded',
  EXPANDED_COLLAPSED = 'expandedCollapsed',
  MINIMIZED = 'minimized',
  HIDDEN = 'hidden',
  DOCKED = 'docked',
  STACKED = 'stacked',
}

export default ChatStatus;
