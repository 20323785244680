import React from 'react';
import { NuanceLogoProps } from './nuanceLogo.types';

const NuanceLogo: React.FC<NuanceLogoProps> = (({
  className,
  color = '#00C3C6',
}: NuanceLogoProps) => (
  <svg className={className} width="119px" height="69px" viewBox="0 0 119 69">
    <g transform="translate(0 69) scale(.1 -.1)" fill={color}>
      <path d="m10 505v-175h170 170v91c0 81 2 90 18 86 9-3 35-8 57-12s78-22 124-41c111-45 124-44 76 7-65 67-176 144-245 172-66 26-207 47-315 47h-55v-175z" />
      <path d="m856 339c-3-17-6-58-6-90v-59h-35c-42 0-125 27-215 69-35 17-66 28-69 25-4-3 30-41 75-83 137-130 251-177 457-188l117-6v181 182h-159-158l-7-31z" />
    </g>
  </svg>
));

export default React.memo(NuanceLogo);
