import { Base64 } from 'js-base64';
import { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import ContentType from '@/constants/contentType';
import { UserInfo } from '@/interfaces/User';
import formatStringAsJson from '@/utils/formatStringAsJson';
import { getTokenPayload, getPendingTokenRequest } from '@/selectors/oauth2Selector';
import createLogoutAction from '@/actions/sync/rootActions';
import toTokenPayload from '@/services/converters/tokenPayloadConverter';
import { HttpRequest } from '@/interfaces/HttpRequest';
import { store } from '@/stores/store';
import RequestMethod from '@/enums/RequestMethod';
import TokenGrandType from '@/enums/TokenGrandType';
import { TokenPayload, TokenPayloadResponse } from '@/interfaces/TokenPayload';
import URL from '@/api/url';
import { Oauth2Actions } from '@/actions/sync/oauth2Actions';
import ERROR from '@/enums/Error';
import { post, send } from '@/api/httpClientAPI';
import { MessageActions } from '@/actions/sync/messageActions';

export function parseJwt(
  token,
): string {
  if (!token) {
    return '';
  }
  const base64 = token
    .split('.')[1]
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  const jsonPayload = Base64.decode(base64);
  const user = formatStringAsJson<UserInfo>(jsonPayload);
  return user
    ? user.user_name
    : '';
}

export function getUserName(): string | null {
  const { accessToken } = getTokenPayload();
  return accessToken && parseJwt(accessToken);
}

export async function authenticateCredentials(
  username: string,
  password: string,
  csrfToken?,
): Promise<AxiosResponse<TokenPayloadResponse>> {
  const ENV = window.NUANCE_ENV;

  const data: string = [
    `client_id=${ENV?.OAUTH_CLIENT_ID}`,
    `grant_type=${TokenGrandType.PASSWORD}`,
    `password=${encodeURIComponent(password)}`,
    `username=${username}`,
  ].join('&');

  const headers: AxiosRequestHeaders = {
    'X-CSRF-TOKEN': csrfToken,
    Authorization: `Basic ${btoa(`${ENV?.OAUTH_CLIENT_ID}:${ENV?.OAUTH_SECRET}`)}`,
  };

  return post<TokenPayloadResponse>(URL.OAUTH.TOKEN, data, headers);
}

export async function authenticateAuthToken(authToken: string): Promise<TokenPayloadResponse> {
  const ENV = window.NUANCE_ENV;

  const data = [
    `auth_token=${authToken}`,
    `grant_type=${TokenGrandType.TRANSIENT_TOKEN}`,
  ].join('&');

  const headers = {
    Authorization: `Basic ${btoa(`${ENV?.OAUTH_CLIENT_ID}:${ENV?.OAUTH_SECRET}`)}`,
    'x-referrer': window.location.href,
  };
  store.dispatch(MessageActions.clearMessageDetails());
  return post<TokenPayloadResponse>(URL.OAUTH.TOKEN, data, headers)
    .then((response) => response.data);
}

export function logout(): Promise<AxiosResponse> {
  const data: string = `access_token=${getTokenPayload().accessToken}`;
  return post<void>(URL.OAUTH.LOGOUT, data);
}

export function updateTokens(
  storageToken?,
): Promise<TokenPayload | void> {
  const ENV = window.NUANCE_ENV;

  const data: string = [
    `grant_type=${TokenGrandType.REFRESH_TOKEN}`,
    `refresh_token=${storageToken ? storageToken.refreshToken : getTokenPayload().refreshToken}`,
  ].join('&');

  const headers: AxiosRequestHeaders = {
    Authorization: `Basic ${btoa(`${ENV?.OAUTH_CLIENT_ID}:${ENV?.OAUTH_SECRET}`)}`,
    'x-referrer': window.location.href,
  };

  const pendingRequest = post<TokenPayloadResponse>(URL.OAUTH.TOKEN, data, headers)
    .then((response) => {
      store.dispatch(Oauth2Actions.setPendingTokenRequest(null));
      const tokenPayload = toTokenPayload(response.data);
      store.dispatch(Oauth2Actions.updateToken(tokenPayload as TokenPayload));
    })
    .catch((error) => {
      const responseStatus = error.response?.status;
      if ([400, 401, 403].includes(responseStatus)) {
        store.dispatch(createLogoutAction());
      }
      if (!responseStatus) {
        store.dispatch(Oauth2Actions.setPendingTokenRequest(null));
      }
      return Promise.reject(error.response);
    });
  store.dispatch(Oauth2Actions.setPendingTokenRequest(pendingRequest));
  return pendingRequest;
}

function isTokenExpired(): boolean {
  const now = Date.now() + 2000;
  const { tokenUpdateTime, expiresIn } = getTokenPayload();
  return !!tokenUpdateTime && !!expiresIn && (now - +tokenUpdateTime > +expiresIn * 1000);
}

export async function authorizedFullResponseSend<T>(
  httpRequest: HttpRequest,
  timeout?: number,
): Promise<AxiosResponse<T> | void> {
  const pendingTokenRequest = getPendingTokenRequest();
  try {
    if (pendingTokenRequest) {
      await pendingTokenRequest;
    } else if (isTokenExpired()) {
      await updateTokens();
    }
    return await send<T>(
      httpRequest.method,
      httpRequest.url,
      httpRequest.data,
      httpRequest.headers,
      true,
      timeout,
    ).catch(async (error: AxiosError): Promise<AxiosResponse<T> | void> => {
      const username = getUserName();
      if (!username) {
        return undefined;
      }
      const { response } = error;
      const errorType = response && response.data
        ? response.data.error || response.data.title
        : null;
      if (response && response.status === 401 && errorType === ERROR.INVALID_TOKEN) {
        try {
          const newResponse: AxiosResponse<T> | void = await authorizedFullResponseSend(
            httpRequest,
          );
          return await Promise.resolve(newResponse);
        } catch (e) {
          return Promise.reject(e);
        }
      }
      return Promise.reject(response);
    });
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function authorizedSend<T>(
  httpRequest: HttpRequest,
  timeout?: number,
): Promise<T | void> {
  return authorizedFullResponseSend<T>(httpRequest, timeout)
    .then((response) => response && response.data);
}

export function authorizedGet<T>(
  url: string,
  data: string | null,
  headers?: AxiosRequestHeaders,
  timeout?: number,
): Promise<T | void> {
  return authorizedSend({
    method: RequestMethod.GET,
    url,
    data,
    headers,
  }, timeout);
}

export function authorizedPost<T>(
  url: string,
  data: string | null,
  headers?: AxiosRequestHeaders,
): Promise<T | void> {
  const postHeaders: AxiosRequestHeaders = {
    'Content-Type': ContentType.JSON,
  };
  return authorizedSend({
    method: RequestMethod.POST,
    url,
    data,
    headers: { ...headers, ...postHeaders },
  } as HttpRequest);
}

export function authorizedPut<T>(
  url: string,
  data: string | null,
  headers?: AxiosRequestHeaders,
): Promise<T | void> {
  return authorizedSend({
    method: RequestMethod.PUT,
    url,
    data,
    headers,
  });
}

export function authorizedDelete<T>(
  url: string,
  data: string | null,
  headers?: AxiosRequestHeaders,
): Promise<T | void> {
  return authorizedSend({
    method: RequestMethod.DELETE,
    url,
    data,
    headers,
  });
}
