import {
  takeEvery, call, put, select, delay, cancel, fork,
} from 'redux-saga/effects';
import loadSupervisorSettings from '@/api/loadSupervisorSettings';
import { User } from '@/interfaces/User';
import Type, { Oauth2Actions } from '@/actions/sync/oauth2Actions';
import { Type as UserTypes, UserActions } from '@/actions/sync/userActions';
import { MessageActions } from '@/actions/sync/messageActions';
import localeKeys from '@/constants/localeKeys';
import application from '@/constants/application';
import Severity from '@/enums/Severity';
import history from '@/services/history';
import {
  LOGIN, LOADING, SUMMARY, INDEX,
} from '@/constants/localUrl';
import getStringByLocaleKey from '@/utils/getStringByLocaleKey';
import { selectUserSites, selectLogoutData } from '@/selectors/userDataSelector';

let logoutTask = null;

function* startLogoutTimer() {
  const { logoutAfterMills } = yield select(selectLogoutData);
  yield delay(logoutAfterMills - application.SHOW_LOGOUT_POPUP_TIME * 1000);
  yield put(UserActions.showLogoutNotification(true));
  yield delay(application.SHOW_LOGOUT_POPUP_TIME * 1000);
  yield put(Oauth2Actions.logout());
  yield put(MessageActions.addMessageDetails({
    message: getStringByLocaleKey(localeKeys.SESSION_AUTO_EXPIRED),
    severity: Severity.ERROR,
  }));
}

function* notifyUserActivity() {
  const sites = yield select(selectUserSites);
  if (logoutTask) {
    yield cancel(logoutTask);
    logoutTask = null;
  }
  if (sites && sites.length) {
    logoutTask = yield fork(startLogoutTimer);
  }
}

function* fetchUser() {
  try {
    let returnPath = [LOGIN, INDEX, LOADING].includes(history.location.pathname)
      ? SUMMARY : history.location.pathname;
    if (returnPath.endsWith(INDEX)) {
      returnPath = returnPath.slice(0, -1);
    }
    returnPath += history.location.search;

    yield put(Oauth2Actions.restoreToken());
    const user: User = yield call(loadSupervisorSettings);
    yield put(UserActions.updateUser(user));
    history.push(returnPath || INDEX);
    yield call(notifyUserActivity);
  } catch (error) {
    yield put(Oauth2Actions.logout());
    history.push(LOGIN);
    yield put(MessageActions.addMessageDetails({
      message: getStringByLocaleKey(localeKeys.IMPROPER_ACCOUNT_CONFIG),
      severity: Severity.ERROR,
    }));
  } finally {
    yield put(Oauth2Actions.setAuthenticationInProgress(false));
  }
}

export default function* userSaga() {
  yield takeEvery(Type.AUTHENTICATED, fetchUser);
  yield takeEvery(UserTypes.NOTIFY_USER_ACTIVITY, notifyUserActivity);
}
