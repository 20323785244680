import { AttributeKeys, AttributeLabels, AttributeTypes } from '@/enums/Attributes';

export interface AttributeType {
  data: AttributeKeys;
  type: AttributeTypes;
  label: AttributeLabels;
  shortName: AttributeLabels;
  hideOn: Array<string> | null;
  showOn: Array<string>;
}

export const Attributes: Record<string, Array<AttributeType>> = {
  [AttributeTypes.AGENT]: [{
    data: AttributeKeys.AGENT,
    hideOn: null,
    label: AttributeLabels.AGENT,
    shortName: AttributeLabels.AGENT_SHORT,
    showOn: ['agents_status', 'agent_serviceability'],
    type: AttributeTypes.AGENT,
  },
  {
    data: AttributeKeys.OWNER,
    hideOn: null,
    label: AttributeLabels.OWNER,
    shortName: AttributeLabels.OWNER_SHORT,
    showOn: ['pages', 'rules', 'chats', 'agents'],
    type: AttributeTypes.AGENT,
  }],
  [AttributeTypes.RULE]: [{
    data: AttributeKeys.RULE,
    hideOn: ['agents_status', 'follow_up'],
    label: AttributeLabels.RULE,
    shortName: AttributeLabels.RULE_SHORT,
    showOn: [],
    type: AttributeTypes.RULE,
  }],
  [AttributeTypes.REQUESTED]: [{
    data: AttributeKeys.REQUESTED,
    hideOn: ['agents_status', 'follow_up'],
    label: AttributeLabels.REQUESTED,
    shortName: AttributeLabels.REQUESTED_SHORT,
    showOn: [],
    type: AttributeTypes.REQUESTED,
  }],
  [AttributeTypes.VISITOR]: [{
    data: AttributeKeys.VISITOR,
    hideOn: ['agents_status', 'follow_up'],
    label: AttributeLabels.VISITOR,
    shortName: AttributeLabels.VISITOR_SHORT,
    showOn: [],
    type: AttributeTypes.VISITOR,
  }],
};
