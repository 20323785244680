import { createAction } from 'redux-actions';
import ChatStatus from '@/enums/ChatStatus';
import { Chat, Message, Transcripts } from '@/interfaces/Chat';

export enum Type {
  ADD_INTERNAL_CHAT = 'ADD_INTERNAL_CHAT',
  READ_NEW_INTERNAL_CHAT = 'READ_NEW_INTERNAL_CHAT',
  UPDATE_INTERNAL_CHAT_READ = 'UPDATE_INTERNAL_CHAT_READ',
  REMOVE_INTERNAL_CHAT = 'REMOVE_INTERNAL_CHAT',
  REMOVE_NOT_INTERNAL_CHAT = 'REMOVE_NOT_INTERNAL_CHAT',
  CHANGE_CHAT_POSITION = 'CHANGE_CHAT_POSITION',
  CHANGE_CHAT_STATUS = 'CHANGE_CHAT_STATUS',
  EXPAND_CHAT = 'EXPAND_CHAT',
  FETCH_TRANSCRIPT = 'FETCH_TRANSCRIPT',
  FETCH_INTERNAL_TRANSCRIPT = 'FETCH_INTERNAL_TRANSCRIPT',
  UPDATE_MESSAGE = 'UPDATE_MESSAGE',
  SEND_MESSAGE = 'SEND_MESSAGE',
  START_CHAT = 'START_CHAT',
  END_CHAT = 'END_CHAT',
  UPDATE_CHATS_DATA = 'UPDATE_CHATS_DATA',
  UPDATE_INTERNAL_TRANSCRIPTS = 'INTERNAL_TRANSCRIPTS_UPDATE',
  UPDATE_TRANSCRIPT = 'TRANSCRIPT_UPDATE',
  SET_MESSAGES_AS_READ = 'SET_MESSAGES_AS_READ',
  SET_INPUT_MESSAGE = 'SET_INPUT_MESSAGE',
  SET_FETCH_INTERNAL_TRANSCRIPT_IN_PROGRESS = 'SET_FETCH_INTERNAL_TRANSCRIPT_IN_PROGRESS',
}

const addInternalChat = createAction<string>(Type.ADD_INTERNAL_CHAT);
const readNewInternalChat = createAction<string>(Type.READ_NEW_INTERNAL_CHAT);
const updateInternalChatRead = createAction<string>(Type.UPDATE_INTERNAL_CHAT_READ);
const removeInternalChat = createAction<string>(Type.REMOVE_INTERNAL_CHAT);
const removeNotInternalChat = createAction<Chat>(Type.REMOVE_NOT_INTERNAL_CHAT);
const changeChatPosition = createAction<string>(Type.CHANGE_CHAT_POSITION);
const changeChatStatus = createAction<ChangeChatStatusPayload>(Type.CHANGE_CHAT_STATUS);
const expandChat = createAction<ExpandChatPayload>(Type.EXPAND_CHAT);
const sendMessage = createAction<SendMessagePayload>(Type.SEND_MESSAGE);
const startChat = createAction<string>(Type.START_CHAT);
const fetchTranscript = createAction<void>(Type.FETCH_TRANSCRIPT);
const fetchInternalTranscript = createAction<void>(Type.FETCH_INTERNAL_TRANSCRIPT);
const endChat = createAction<string>(Type.END_CHAT);
const updateChatsData = createAction<Chat[]>(Type.UPDATE_CHATS_DATA);
const updateMessage = createAction<UpdateMessagePayload>(Type.UPDATE_MESSAGE);
const updateInternalTranscripts = createAction<Message[]>(Type.UPDATE_INTERNAL_TRANSCRIPTS);
const updateTranscripts = createAction<Transcripts>(Type.UPDATE_TRANSCRIPT);
const setMessagesAsRead = createAction<string>(Type.SET_MESSAGES_AS_READ);
const setInputMessage = createAction<SetInputMessagePayload>(Type.SET_INPUT_MESSAGE);
const setfetchInternalTranscriptInProgress = createAction<boolean>(
  Type.SET_FETCH_INTERNAL_TRANSCRIPT_IN_PROGRESS,
);

export const ChatActions = {
  addInternalChat,
  readNewInternalChat,
  updateInternalChatRead,
  removeInternalChat,
  removeNotInternalChat,
  changeChatPosition,
  changeChatStatus,
  startChat,
  fetchTranscript,
  fetchInternalTranscript,
  endChat,
  expandChat,
  sendMessage,
  updateChatsData,
  updateMessage,
  updateTranscripts,
  updateInternalTranscripts,
  setMessagesAsRead,
  setInputMessage,
  setfetchInternalTranscriptInProgress,
};
export type ChatActionsType = typeof ChatActions;

export type ChangeChatStatusPayload = {
  engagementID: string,
  status: ChatStatus,
};

export type ExpandChatPayload = {
  rowData: any,
  expandedChatId: string,
};

export type SendMessagePayload = {
  engagementID: string,
  message: Message,
};

export type UpdateMessagePayload = {
  engagementID: string,
  updatedMessage: Message,
};

export type SetInputMessagePayload = {
  engagementID: string,
  inputMessage: string,
};

export type ChatManagerPayloads =
  Chat &
  Chat[] &
  ChangeChatStatusPayload &
  ExpandChatPayload &
  SendMessagePayload &
  UpdateMessagePayload &
  Message[] &
  string &
  SetInputMessagePayload &
  boolean;
