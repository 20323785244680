import { handleActions } from 'redux-actions';
import { SnackBarMessage } from '@/interfaces/SnackbarMessage';
import { MessageActionsType, Type } from '@/actions/sync/messageActions';

const messageInitialState = {};

const addMessageDetails = (state, action): SnackBarMessage => action.payload;
const clearMessageDetails = () => messageInitialState;

const messageReducer = handleActions<MessageActionsType>(
  {
    // @ts-ignore
    [Type.ADD_MESSAGE_DETAILS]: addMessageDetails,
    // @ts-ignore
    [Type.CLEAR_MESSAGE_DETAILS]: clearMessageDetails,
  },
  messageInitialState,
);

export default messageReducer;
