import { handleActions } from 'redux-actions';
import Type, { RotationActionsType } from '@/actions/sync/rotationActions';

export const initialState = {
  isRotationInProgress: false,
  isRotationPause: false,
  isManualRotationPause: false,
  rotationInterval: 0,
  rotationSettings: [],
};

const processAction = (fieldName: string) => ((state, action) => ({
  ...state,
  [fieldName]: action.payload,
}));

const setRotationInProgress = (value: boolean) => ((state) => ({
  ...state,
  isRotationInProgress: value,
}));

const setRotationPause = (state, { payload }) => ({
  ...state,
  isRotationPause: payload,
});

const setManualRotationPause = (state, { payload }) => ({
  ...state,
  isManualRotationPause: payload,
});

const rotationReducer = handleActions<RotationActionsType>(
  {
    [Type.ROTATION_INTERVAL_CHANGED]: processAction('rotationInterval'),
    [Type.UPDATE_ROTATION_SETTINGS]: processAction('rotationSettings'),
    [Type.START_ROTATION]: setRotationInProgress(true),
    [Type.STOP_ROTATION]: setRotationInProgress(false),
    [Type.SET_ROTATION_PAUSE]: setRotationPause,
    [Type.SET__MANUAL_ROTATION_PAUSE]: setManualRotationPause,
  },
  // @ts-ignore
  initialState,
);

export default rotationReducer;
