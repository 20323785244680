import { useEffect, useCallback } from 'react';
import { parseAndLogError } from '@/services/loggerService';

export function useGlobalErrorHandler(
) {
  const handleRejection = useCallback(
    (event) => {
      event.promise.catch((error) => {
        parseAndLogError(error);
        event.stopPropagation();
        event.preventDefault();
      });
    },
    [],
  );

  const handleGlobalError = useCallback(
    (event) => {
      if (event.error) {
        parseAndLogError(event.error);
      }
      event.stopPropagation();
      event.preventDefault();
    },
    [],
  );

  useEffect(() => {
    window.addEventListener('unhandledrejection', handleRejection);
    window.addEventListener('error', handleGlobalError);
    return () => {
      window.removeEventListener('unhandledrejection', handleRejection);
      window.addEventListener('error', handleGlobalError);
    };
  }, [handleRejection, handleGlobalError]);
}

export default useGlobalErrorHandler;
