import Category from '@/enums/Category';
import { ViewColumn, DimensionType, ViewNameType } from '@/interfaces/SupervisorView';
import { CustomColumn } from '@/components/organisms/CustomTable/customTable.types';
import {
  getCurrentSettingId,
  getCurrentSiteId,
} from '@/selectors/applicationSettingsSelector';
import { store } from '@/stores/store';
import { ViewAttributesData } from '@/interfaces/AttributesData';
import { CustomView } from '../../interfaces/SupervisorView';
import { defineCustomViewColumns } from '../viewService';

const buildViewRequestBody = (view) => ({
  settingId: getCurrentSettingId(store.getState()),
  siteId: getCurrentSiteId(store.getState()),
  view,
});

export default buildViewRequestBody;

export const buildViewColumns = (
  columns: CustomColumn[],
): ViewColumn[] => (columns
  .filter((column) => (
    !column.generated
  ))
  .map(({ dataKey, label, visible }) => ({
    data: dataKey,
    titleText: label,
    visible,
  }))
);

export const buildView = (
  category: Category,
  dimension: DimensionType,
  viewName: ViewNameType,
  columns: ViewColumn[],
  attributesHeader?: string,
  id?: string,
): CustomView => {
  const view = {
    category,
    dimension,
    name: viewName,
    columns,
    attributesHeader: attributesHeader || '',
  };
  return id ? { ...view, id } : view;
};

export const buildCustomView = (
  category: Category,
  viewName: string,
  attributeData: ViewAttributesData,
  attributeName: string,
  id?: string,
): CustomView => {
  const dimension: string = `${attributeData.key}.${attributeName}`;
  const attributesHeader: string = `${attributeName}${attributeData.shortLabel}`;
  const attributeColumn: ViewColumn = {
    data: dimension,
    titleText: attributesHeader,
    visible: true,
    descending: false,
  };

  return buildView(
    category,
    dimension,
    viewName.trim(),
    defineCustomViewColumns(attributeColumn, category),
    attributesHeader,
    id,
  );
};
