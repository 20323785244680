import { createAction } from 'redux-actions';
import { ChangePasswordPayload } from '@/interfaces/User';

export enum Type {
  LOGOUT_AGENT = 'LOGOUT_AGENT',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
}

const logoutAgent = createAction<{
  agentID: string;
  agentFullName: string;
}>(Type.LOGOUT_AGENT);

const changePassword = createAction<ChangePasswordPayload>(Type.CHANGE_PASSWORD);

export const AgentActions = {
  logoutAgent,
  changePassword,
};

export type AgentActionsType = typeof AgentActions;
