import { createAction } from 'redux-actions';

export enum Type {
  UPDATE_OPEN_ACTION_ITEM = 'UPDATE_OPEN_ACTION_ITEM',
}

const updateActionItem = createAction<string>(Type.UPDATE_OPEN_ACTION_ITEM);

export const UpdateOpenItemActions = {
  updateActionItem,
};

export type UpdateActionItemType = typeof updateActionItem;
