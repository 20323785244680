export const caseInsensitiveComparatorASC = (val1: string = '', val2: string = ''): number => (
  val1.localeCompare(val2));

/**
 * Sort function by name field.
 * Letter case is ignored.
 */
export default function comparatorByName(obj1, obj2) {
  const name1 = obj1.name.toLowerCase();
  const name2 = obj2.name.toLowerCase();
  if (name1 < name2) {
    return -1;
  }
  if (name1 > name2) {
    return 1;
  }
  return 0;
}
