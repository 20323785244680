export function putToCookie(name, value): void {
  document.cookie = `${name}=${value}; Path=/;`;
}

export function removeFromCookie(name): void {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export function getFromCookie(name): string | null | undefined {
  const value: string = `; ${document.cookie}`;
  const parts: string[] = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()!.split(';').shift();
  }
  return null;
}

export const CSRF_TOKEN = 'csrf_token';
export const SSO_USER = 'sso_user';
