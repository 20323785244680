import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import NuanceLogo from '@/components/atoms/NuanceLogo';
import useClasses from './loaderPage.styles';
import localeKeys from '@/constants/localeKeys';

const LoaderPage: React.FC = () => {
  const {
    wrapper,
    spinner,
    logoWrapper,
    spinnerStart,
  } = useClasses();
  const intl = useIntl();

  const [loadingText, setLoadingText] = useState<string>('');

  useEffect(() => {
    setTimeout(() => setLoadingText(intl.formatMessage({ id: localeKeys.LOADING_PLEASE_WAIT })),
      0);
  }, [intl]);
  return (
    <>
      <span className="sr-only" aria-live="assertive">{loadingText}</span>
      <Box
        className={wrapper}
        id="loader-wrapper"
      >
        <Box
          className={spinner}
          id="loader-spinner"
        >
          <Box
            className={spinnerStart}
            id="circle"
          />
        </Box>
        <Box
          className={logoWrapper}
          id="logo-wrapper"
        >
          <NuanceLogo />
        </Box>
      </Box>
    </>
  );
};

export default React.memo(LoaderPage);
