import { SortDirectionMap } from 'react-virtualized/dist/es/Table';
import { handleActions, Action } from 'redux-actions';
import { DEFAULT_SORT_OPTIONS } from '@/constants/tableDefaultSortOptions';
import { TableConfig, SortOptions } from '@/interfaces/TableConfig';
import {
  Type,
  SortOptionsPayload,
  ResetSortOptionsPayload,
  TableConfigPayloads,
} from '@/actions/sync/tableConfigActions';
import SortDirection from '@/enums/SortDirection';

export const initialState: TableConfig = {};

const getSortDirection = (
  dataKey: string,
  { [dataKey]: sortDirectionType }: SortDirectionMap,
): SortDirectionMap | null => {
  switch (sortDirectionType) {
    case SortDirection.ASC:
      return { [dataKey]: SortDirection.DESC };
    case SortDirection.DESC:
      return null;
    default:
      return { [dataKey]: SortDirection.ASC };
  }
};

const processSetSortOptionsAction = (
  state: TableConfig,
  { payload }: Action<SortOptionsPayload>,
): TableConfig => {
  const { dataKey, settingId, viewName } = payload;
  const settingIdData = { ...DEFAULT_SORT_OPTIONS, ...state[settingId] };
  let sortOptions: SortOptions | null = {
    sortBy: [dataKey],
    sortDirection: { [dataKey]: SortDirection.ASC },
  };

  if (settingIdData
    && settingIdData[viewName]
    && Object.keys(settingIdData[viewName]).length) {
    const newSortDirection: SortDirectionMap | null = getSortDirection(
      dataKey,
      settingIdData[viewName].sortDirection,
    );
    sortOptions = newSortDirection && { sortBy: [dataKey], sortDirection: newSortDirection };
  }

  return {
    ...state,
    [settingId]: {
      ...settingIdData,
      [viewName]: sortOptions || {},
    },
  };
};

const processUpdateSortOptionsActions = (
  state: TableConfig,
  { payload }: Action<SortOptionsPayload>,
): TableConfig => {
  const { dataKey, settingId, viewName } = payload;
  const settingIdData = { ...state[settingId] };
  let sortOptions: SortOptions | Object = {
    sortBy: [dataKey],
    sortDirection: { [dataKey]: SortDirection.ASC },
  };

  const savedSortOptions = (settingIdData && settingIdData[viewName])
    || DEFAULT_SORT_OPTIONS[viewName];

  if (savedSortOptions && Object.keys(savedSortOptions).length) {
    const { sortBy, sortDirection } = savedSortOptions;
    if (sortBy.includes(dataKey)) {
      const newSortDirection: SortDirectionMap | null = getSortDirection(dataKey, sortDirection);
      const newSortBy = [...sortBy];

      if (newSortDirection) {
        sortOptions = {
          sortBy: [...newSortBy],
          sortDirection: { ...sortDirection, ...newSortDirection },
        };
      } else {
        const { [dataKey]: removedSortDirection, ...restSortDirection } = sortDirection;
        newSortBy.splice(sortBy.indexOf(dataKey), 1);
        sortOptions = {
          sortBy: newSortBy,
          sortDirection: restSortDirection,
        };
      }
    } else {
      sortOptions = {
        sortBy: [...sortBy, dataKey],
        sortDirection: {
          ...sortDirection,
          [dataKey]: SortDirection.ASC,
        },
      };
    }
  }

  return {
    ...state,
    [settingId]: {
      ...settingIdData,
      [viewName]: sortOptions,
    },
  };
};

const processRemoveSortOptionsAction = (
  state: TableConfig,
  { payload }: Action<SortOptionsPayload>,
): TableConfig => {
  const { dataKey, settingId, viewName } = payload;
  const settingIdData = { ...state[settingId] };

  if (settingIdData
    && settingIdData[viewName]
    && Object.keys(settingIdData[viewName]).length === 0) {
    return state;
  }

  const savedSortOptions = (settingIdData && settingIdData[viewName])
    || DEFAULT_SORT_OPTIONS[viewName];

  const { sortBy, sortDirection } = savedSortOptions;
  const newSortBy = sortBy.filter((value) => value !== dataKey);
  const newSortDirection = { ...sortDirection };
  delete newSortDirection[dataKey];

  const newSortOptions = newSortBy.length ? {
    sortBy: newSortBy,
    sortDirection: newSortDirection,
  } : {};

  return {
    ...state,
    [settingId]: {
      ...settingIdData,
      [viewName]: newSortOptions,
    },
  };
};

const resetSortOptionsAction = (
  state: TableConfig,
  { payload }: Action<ResetSortOptionsPayload>,
): TableConfig => {
  const { settingId, viewName } = payload;
  const settingIdData = { ...state[settingId] };
  delete settingIdData[viewName];
  return {
    ...state,
    [settingId]: settingIdData,
  };
};

const tableConfigReducer = handleActions<TableConfig, TableConfigPayloads>(
  {
    [Type.SET_SORT_OPTIONS]: processSetSortOptionsAction,
    [Type.UPDATE_SORT_OPTIONS]: processUpdateSortOptionsActions,
    [Type.REMOVE_SORT_OPTIONS]: processRemoveSortOptionsAction,
    [Type.RESET_SORT_OPTIONS]: resetSortOptionsAction,
  },
  initialState,
);

export default tableConfigReducer;
