import {
  all, call, put, select, takeEvery, delay,
} from 'redux-saga/effects';
import SubTabIndex from '@/enums/SubTabIndex';
import { selectCurrentView } from '@/selectors/currentViewSelector';
import { parseAndLogError } from '@/services/loggerService';
import history from '@/services/history';
import { selectUser } from '@/selectors/userDataSelector';
import {
  isSingleSettingMode,
  getCurrentSettingId,
  getCurrentSetting,
  selectUnit,
  selectSelectedSite,
  isAdministrator,
} from '@/selectors/applicationSettingsSelector';
import CompatibilitySupV2 from '@/services/CompatibilitySupV2';
import {
  SupervisorViewResponse,
  CustomView,
  SettingCustomViews,
  ViewColumn,
  ConfiguredSupervisorViewData,
  CustomViewsData,
} from '@/interfaces/SupervisorView';
import {
  loadSupervisorView, postSupervisorView, deleteSupervisorView, putSupervisorView,
} from '@/api/supervisorEngagementClientAPI';
import Type, { ApplicationSettingsActions } from '@/actions/sync/applicationSettingsActions';
import {
  ViewActionType, SupervisorViewActions, AddViewPayload, EditViewPayload,
} from '@/actions/sync/supervisorViewActions';
import {
  isSupervisorViewReceived, selectAllUnsavedChanges, selectSupervisorViewData, selectViewByViewName,
} from '@/selectors/supervisorViewSelector';
import Severity from '@/enums/Severity';
import { MessageActions } from '@/actions/sync/messageActions';
import { TableActions } from '@/actions/sync/tableConfigActions';
import { CurrentView } from '@/interfaces/currentView';
import buildViewRequestBody, { buildView, buildViewColumns } from '@/services/builders/buildViewRequestBody';
import ColumnData from '@/enums/ColumnData';
import formatStringAsJson from '@/utils/formatStringAsJson';
import { VIEW_DATA } from '@/constants/WebStorageKey';
import LocaleKey from '@/constants/localeKeys';
import { LOADING } from '@/constants/localUrl';
import { getFromWebStorage } from '@/services/webStorageService';
import { WebStorageActions } from '@/actions/sync/webStorageActions';
import { Oauth2Actions } from '@/actions/sync/oauth2Actions';

function* loadMoreSupervisorViews(
  maxViewsFetch, offset, allViews: CustomView[] = [],
) {
  try {
    const response = yield call(loadSupervisorView, maxViewsFetch, offset);
    const supervisorViewResponse = response as SupervisorViewResponse;
    const total = +supervisorViewResponse.total;
    const nextOffset = +supervisorViewResponse.offset + maxViewsFetch;
    allViews.push(...response.views);
    if (nextOffset < total) {
      yield call(loadMoreSupervisorViews, maxViewsFetch, nextOffset, allViews);
    }
    return allViews;
  } catch (error) {
    // in case of 204 No content status code
    return allViews;
  }
}

const getUnsupportedColmunData = (): ColumnData[] => [ColumnData.ATTRIBUTES];

const getPreparedViews = (views: CustomView[]): SettingCustomViews => {
  const customViews = {};
  views.forEach((viewData) => {
    const customView = viewData;
    const categoryId = CompatibilitySupV2.formatField('category', customView.category);
    const dimension = CompatibilitySupV2.formatField('dimension', customView.dimension);
    const columns = customView.columns
      .filter((column) => getUnsupportedColmunData().indexOf(column.data as ColumnData) === -1)
      .map((column) => ({ ...column, data: CompatibilitySupV2.formatField('column', column.data, dimension) }));
    if (!(categoryId in customViews)) {
      customViews[categoryId] = [];
    }
    customView.category = categoryId;
    customView.dimension = dimension;
    customView.columns = columns;
    customViews[categoryId].push(viewData);
  });
  return customViews;
};

function* fetchSupervisorView() {
  try {
    const { maxViewsFetch } = yield select(selectUser);
    const selectedUnit = yield select(selectUnit);
    const [currentSetting] = yield select(getCurrentSetting);
    const selectedSite = yield select(selectSelectedSite);

    const settingId = yield select(getCurrentSettingId);
    yield put(SupervisorViewActions.fetchingViews(settingId));
    const views: CustomView[] = yield call(
      loadMoreSupervisorViews, maxViewsFetch, 0, [],
    );
    const settingViews = getPreparedViews(views);
    yield put(SupervisorViewActions.applyViews({
      settingId,
      settingViews,
      unit: selectedUnit,
      name: currentSetting.name,
      siteName: selectedSite.name,
      siteId: currentSetting.siteId,
    }));
  } catch (error) {
    parseAndLogError(error);
  }
}

function* selectedSettingsChanged() {
  if (!(yield select(isSingleSettingMode))
    || (yield select(isSupervisorViewReceived))) return;
  yield call(fetchSupervisorView);
}

function* setSubTabIndex(category, settingId, tabIndex) {
  const currentSettingId = yield select(getCurrentSettingId);
  const currentCategory: CurrentView = yield select(selectCurrentView);
  if (category === currentCategory.category
    && settingId === currentSettingId) {
    yield put(ApplicationSettingsActions.setSubTabIndex(tabIndex));
  }
}

function* addNewCustomView({ payload: { requestBody, tabIndex } }: AddViewPayload) {
  const settingId = yield select(getCurrentSettingId);

  try {
    if (settingId.split(',').length === 1) {
      yield put(SupervisorViewActions.addCustomView(requestBody));
      if (tabIndex) {
        yield setSubTabIndex(requestBody.view.category, settingId, tabIndex);
      }
    }
  } catch (error: any) {
    yield put(MessageActions.addMessageDetails({
      message: error.toString(),
      severity: Severity.ERROR,
    }));
  }
}

function* requestEditCustomView({ payload, meta: oldViewName }: EditViewPayload) {
  try {
    yield put(SupervisorViewActions.editCustomView(payload, oldViewName));
  } catch (error: any) {
    yield put(MessageActions.addMessageDetails({
      message: error.toString(),
      severity: Severity.ERROR,
    }));
  }
}

function* deleteCustomViewById({
  payload: {
    customViewId,
    category,
    settingId,
    name,
  },
}: ReturnType<typeof SupervisorViewActions.requestDeleteCustomView>) {
  try {
    yield setSubTabIndex(category, settingId, SubTabIndex.ZERO);
    yield put(SupervisorViewActions.deleteCustomView({
      customViewId, category, settingId, name,
    }));
  } catch (error: any) {
    yield put(MessageActions.addMessageDetails({
      message: error.toString(),
      severity: Severity.ERROR,
    }));
  }
}

function* resetDefaultView({
  payload: {
    customViewId,
    settingId,
    category,
  },
}: ReturnType<typeof SupervisorViewActions.resetDefaultView>) {
  try {
    const { name: viewName }: CurrentView = yield select(selectCurrentView);
    yield put(TableActions.resetSortOptions({ viewName, settingId }));
    if (customViewId) {
      yield put(SupervisorViewActions.deleteCustomView({ customViewId, category, settingId }));
    }
  } catch (error: any) {
    yield put(MessageActions.addMessageDetails({
      message: error.toString(),
      severity: Severity.ERROR,
    }));
  }
}

function* resetCustomView({
  payload,
}: ReturnType<typeof SupervisorViewActions.resetCustomView>) {
  const { settingId } = payload;
  const { name }: CurrentView = yield select(selectCurrentView);
  try {
    yield put(TableActions.resetSortOptions({ viewName: name, settingId }));
    yield put(SupervisorViewActions.editCustomView(payload, name));
  } catch (error: any) {
    yield put(MessageActions.addMessageDetails({
      message: error.toString(),
      severity: Severity.ERROR,
    }));
  }
}

function* updateColumns({ payload }: ReturnType<typeof SupervisorViewActions.updateViewColumns>) {
  const {
    category, dimension, name,
  }: CurrentView = yield select(selectCurrentView);
  const currentView: CustomView | undefined = yield select(selectViewByViewName(category, name));

  const viewColumns: ViewColumn[] = buildViewColumns(payload);
  const view: CustomView = buildView(category, dimension, name, viewColumns);
  try {
    if (currentView) {
      yield requestEditCustomView({
        payload: buildViewRequestBody({ ...currentView, columns: viewColumns }),
        type: ViewActionType.REQUEST_EDIT_CUSTOM_VIEW,
        meta: currentView.name,
      });
    } else {
      yield addNewCustomView({
        payload: {
          requestBody: buildViewRequestBody(view),
        },
        type: ViewActionType.REQUEST_ADD_CUSTOM_VIEW,
      });
    }
  } catch (error) {
    parseAndLogError(error);
  }
}

function prepareSupervisorViewData(supervisorViewData): ConfiguredSupervisorViewData {
  const preparedViews = Object.entries(supervisorViewData.settingViews as SettingCustomViews)
    .reduce((acc, { 1: views }) => [...acc, ...views], [] as CustomView[])
    .filter((view) => view.isEdited || view.isDeleted)
    .reduce((acc, view) => {
      if (view.isDeleted) {
        if (view.id) acc.delete.push(view);
        return acc;
      }
      if (view.id) {
        acc.put.push(view);
      } else {
        acc.post.push(view);
      }
      return acc;
    }, { put: [], post: [], delete: [] } as ConfiguredSupervisorViewData);

  return preparedViews;
}

function* sendSupervisorRequests(viewsRequestData, siteId, settingId) {
  const requestBody = {
    siteId,
    settingId,
    view: {},
  };

  return yield all([
    ...viewsRequestData.post.map((view) => (
      call(postSupervisorView, { ...requestBody, view })
    )),
    ...viewsRequestData.put.map((view) => (
      call(putSupervisorView, { ...requestBody, view })
    )),
    ...viewsRequestData.delete.map((view) => (
      call(deleteSupervisorView, view.id, settingId, siteId)
    )),
  ]);
}

function* updateStorageViewData(settingId: string) {
  const { [settingId]: view, ...restViews }: CustomViewsData = formatStringAsJson(
    getFromWebStorage(VIEW_DATA, true),
  ) as CustomViewsData;

  yield put(WebStorageActions.updateWebStorage({
    key: VIEW_DATA,
    value: restViews,
    isSession: true,
  }));
}

function* commitViewChanges({
  payload,
}: ReturnType<typeof SupervisorViewActions.commitViewChanges>) {
  try {
    const isAdmin = select(isAdministrator);
    if (isAdmin) {
      let supervisorData = {};
      yield put(MessageActions.addMessageDetails({
        message: {
          translate: true,
          progressBarEnabled: true,
          message: LocaleKey.COMMIT_VIEWS_INPROCESS,
          description: LocaleKey.COMMIT_VIEWS_INPROCESS_MESSAGE,
        },
      }));

      if (payload.commitForAllSites) {
        supervisorData = yield select(selectAllUnsavedChanges);
      } else {
        const settingId = yield select(getCurrentSettingId);
        supervisorData = {
          [settingId]: yield select(selectSupervisorViewData),
        };
      }

      yield all(Object.entries(supervisorData).map(([settingId, supervisorViewData]) => {
        const viewsRequestData = prepareSupervisorViewData(supervisorViewData);
        const makeViewRequest = function* (viewRequestData, supervisorViewsData, tempSettingId) {
          yield call(sendSupervisorRequests,
            viewRequestData, supervisorViewsData.siteId, tempSettingId);
          yield call(updateStorageViewData, settingId);
        };
        return call(makeViewRequest, viewsRequestData, supervisorViewData, settingId);
      }));

      yield delay(1000);
      yield put(MessageActions.addMessageDetails({
        message: {
          translate: true,
          progressBarEnabled: true,
          message: LocaleKey.COMMIT_VIEWS_SUCCESS,
          description: LocaleKey.COMMIT_VIEWS_SUCCESS_MESSAGE,
        },
      }));

      if (payload?.logout) {
        history.push(LOADING);
        yield put(Oauth2Actions.logout());
      } else {
        yield call(fetchSupervisorView);
      }
    }
  } catch (error) {
    yield put(MessageActions.addMessageDetails({
      severity: Severity.ERROR,
      message: {
        translate: true,
        progressBarEnabled: true,
        message: LocaleKey.COMMIT_VIEWS_ERROR,
        description: LocaleKey.COMMIT_VIEWS_ERROR_MESSAGE,
      },
    }));
    parseAndLogError(error);
  }
}

function* discardViewChanges() {
  try {
    const settingId = yield select(getCurrentSettingId);
    yield call(fetchSupervisorView);
    yield call(updateStorageViewData, settingId);
  } catch (error) {
    parseAndLogError(error);
  }
}

export default function* supervisorViewSaga() {
  yield takeEvery([
    Type.UPDATE_APPLICATION_SETTINGS,
    Type.SELECTED_BUSINESS_UNITS_CHANGED,
    Type.SELECTED_AGENT_GROUPS_CHANGED,
    Type.SELECTED_SITE_CHANGED,
    Type.SELECTED_UNIT_CHANGED,
  ], selectedSettingsChanged);
  yield takeEvery(ViewActionType.COMMIT_VIEW_CHANGES, commitViewChanges);
  yield takeEvery(ViewActionType.DISCARD_VIEW_CHANGES, discardViewChanges);
  yield takeEvery(ViewActionType.REQUEST_ADD_CUSTOM_VIEW, addNewCustomView);
  yield takeEvery(ViewActionType.REQUEST_EDIT_CUSTOM_VIEW, requestEditCustomView);
  yield takeEvery(ViewActionType.REQUEST_DELETE_CUSTOM_VIEW, deleteCustomViewById);
  yield takeEvery(ViewActionType.RESET_DEFAULT_VIEW, resetDefaultView);
  yield takeEvery(ViewActionType.RESET_CUSTOM_VIEW, resetCustomView);
  yield takeEvery([
    ViewActionType.UPDATE_VIEW_COLUMNS,
    ViewActionType.UPDATE_VIEW_COLUMNS_REORDERED,
  ], updateColumns);
}
