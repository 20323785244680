import { handleActions } from 'redux-actions';
import {
  AgentSummary, EngagementSummary, QueueSummary, Summary,
} from '@/interfaces/Summary';
import { SummaryActionsType, Type } from '@/actions/sync/summaryActions';

const agentSummary: AgentSummary = {
  activeAgents: null,
  agentUtilization: null,
  agentsLoggedIn: null,
  agentsOnBusy: null,
  availableEngagementSlots: null,
  engagementUtilization: null,
};

const engagementSummary: EngagementSummary = {
  activeEngagements: null,
  avgAgentResponseTime: null,
  avgEngagementDuration: null,
  avgInitialAgentResponseTime: null,
  engagementsExceedingHandleTimeGoal: null,
  engagementsInLastHour: null,
  engagementsWithinHandleTimeGoal: null,
  escalatedChats: null,
  maxEngagementDuration: null,
  nonDispositionEngagements: null,
  percentSaleQualified: null,
  saleQualifiedEngagements: null,
};

const queueSummary: QueueSummary = {
  engagementsInQueue: null,
  percentWithinQueueSLA: null,
  queuedEngagementsExceedingSLA: null,
  queuedEngagementsWithinSLA: null,
  trendingPercentOfAbandons: null,
  trendingPercentWithinQueueSLA: null,
  maxTimeInQueue: null,
  avgTimeInQueue: null,
  trendingAvgTimeInQueue: null,
};

export const summaryInitialState: Summary = {
  agentSummary,
  engagementSummary,
  queueSummary,
  isKpiOpen: false,
  isSummaryOpen: false,
};

const setProcessRequests = (field: string, value: boolean) => ((state) => ({
  ...state,
  [field]: value,
}));

const processAction = (fieldName: string) => ((state, action) => ({
  ...state,
  [fieldName]: action.payload,
}));

const summaryReducer = handleActions<SummaryActionsType>(
  {
    [Type.KPI_OPEN]: setProcessRequests('isKpiOpen', true),
    [Type.KPI_CLOSE]: setProcessRequests('isKpiOpen', false),
    [Type.SUMMARY_OPEN]: setProcessRequests('isSummaryOpen', true),
    [Type.SUMMARY_CLOSE]: setProcessRequests('isSummaryOpen', false),
    [Type.UPDATE_AGENT]: processAction('agentSummary'),
    [Type.UPDATE_ENGAGEMENT]: processAction('engagementSummary'),
    [Type.UPDATE_QUEUE]: processAction('queueSummary'),
  },
  // @ts-ignore
  summaryInitialState,
);

export default summaryReducer;
