import { handleActions, Action } from 'redux-actions';
import { Type } from '@/actions/sync/conversationActions';
import {
  ConversationManager,
  ConversationManagerPayload,
} from '@/interfaces/Conversation';
import toConversationTranscript from '@/services/converters/conversationTranscriptConverter';

export const conversationManagerInitialState: ConversationManager = {
  conversations: {},
  activeConversation: {
    conversationID: '',
    owningAgentGroup: '',
    owningBusinessUnit: '',
  },
};

const processUpdateAction = (fieldName) => (state: ConversationManager, payload) => ({
  ...state,
  [fieldName]: payload,
});

const processRequestTranscriptAction = (
  state,
  { payload }: Action<ConversationManagerPayload>,
) => {
  const { isLoading, conversationID } = payload;
  const conversationTranscript = {
    [conversationID]: {
      ...state.conversations[conversationID],
      isLoading,
    },
  };
  return {
    ...state,
    conversations: {
      ...state.conversations,
      ...conversationTranscript,
    },
  };
};

const processRequestCompletedAction = (
  state,
  { payload }: Action<ConversationManagerPayload>,
) => {
  const { isLoaded, conversationID } = payload;
  const conversationTranscript = {
    [conversationID]: {
      ...state.conversations[conversationID],
      isLoaded,
    },
  };
  return {
    ...state,
    conversations: {
      ...state.conversations,
      ...conversationTranscript,
    },
  };
};
const processClearTranscriptAction = (
  state,
  { payload }: Action<ConversationManagerPayload>,
) => {
  const { conversationID } = payload;
  const emptyConversationTranscript = {
    engagements: {},
    isLoaded: false,
    isLoading: false,
  };
  const conversationTranscript = {
    [conversationID]: {
      ...state.conversations[conversationID],
      ...emptyConversationTranscript,
    },
  };
  return {
    ...state,
    conversations: {
      ...state.conversations,
      ...conversationTranscript,
    },
  };
};

const processUpdateTranscriptAction = (
  state,
  { payload }: Action<ConversationManagerPayload>,
) => {
  const { engagements, conversationID } = payload;
  const updatedConversationTranscripts = { ...state.conversations };
  engagements.forEach((engagementTranscript) => {
    const { transcript } = engagementTranscript;
    const transcriptsEngagements = {
      engagements: toConversationTranscript(transcript),
    };

    updatedConversationTranscripts[conversationID] = {
      ...state.conversations[conversationID],
      ...transcriptsEngagements,
    };
  });
  return {
    ...state,
    conversations: {
      ...state.conversations,
      ...updatedConversationTranscripts,
    },
  };
};

const processActivateTranscriptAction = (
  state,
  { payload }: Action<ConversationManagerPayload>,
) => processUpdateAction('activeConversation')(state, payload);

const conversationManagerReducer = handleActions<
ConversationManager, ConversationManagerPayload
>(
  {
    [Type.CONVERSATION_TRANSCRIPT_REQUEST_IN_PROGRESS]: processRequestTranscriptAction,
    [Type.CONVERSATION_TRANSCRIPT_REQUEST_COMPLETED]: processRequestCompletedAction,
    [Type.UPDATE_CONVERSATION_TRANSCRIPT]: processUpdateTranscriptAction,
    [Type.ACTIVATE_CONVERSATION_TRANSCRIPT]: processActivateTranscriptAction,
    [Type.CLEAR_CONVERSATION_TRANSCRIPT]: processClearTranscriptAction,
  },
  conversationManagerInitialState,
);

export default conversationManagerReducer;
