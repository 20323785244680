import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions } from '@/actions/sync/userActions';
import { selectUserSites } from '@/selectors/userDataSelector';

const useNotifyUserInactivity = () => {
  const dispatch = useDispatch();
  const sites = useSelector(selectUserSites);

  const throttle = useCallback((callback, wait) => {
    let timeoutId: null | NodeJS.Timeout;
    return () => {
      if (!timeoutId) {
        timeoutId = setTimeout(() => {
          clearTimeout(timeoutId as unknown as NodeJS.Timeout);
          timeoutId = null;
        }, wait);
        callback();
      }
    };
  }, []);

  useEffect(() => {
    ['click', 'scroll', 'keydown', 'mousemove'].forEach((evt) => document.addEventListener(evt, throttle(() => {
      if (sites && sites.length) {
        dispatch(UserActions.notifyUserActivity());
      }
    }, 5000)));
  }, [sites, throttle, dispatch]);
};

export default useNotifyUserInactivity;
